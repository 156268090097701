import boxFinder from '../components/BoxFinder/translations/de'
import checkoutProcess from '../components/CheckoutProcess/translations/de'

export default {
    boxFinder,
    checkoutProcess,
    bookingPage: {
        header: {
            step1: 'Schritt 1: Buchen',
            step2: 'Schritt 2: Deine Details',
            step3: 'Schritt 3: Bezahlung',
        },
        step1: {
            insuranceModal: {
                title: 'Bist du sicher, dass du keine Versicherung benötigst?',
                description:
                    'Eine Versicherung gegen Diebstahl, Feuer und Wasserschäden ist obligatorisch. Du kannst deine Hausratversicherung kontaktieren oder du versicherst bei uns. Die Kosten sind monatlich CHF 6 pro CHF 10’000 Warenwert',
                yes: 'Ja',
                no: 'Nein',
            },
            startDate: 'Startdatum wählen',
            requiredFieldError: 'Dieses Feld ist Pflicht',
            minimumTerm: 'Mindestdauer',
            insurance: 'Versicherung',
            insuranceDescription:
                'Die Kosten sind monatlich CHF 6 pro CHF 10’000 Warenwert',
            confirmInsurance: 'Ja, ich möchte eine Versicherung (empfohlen)',
            boxValue: 'Wert deiner Waren (CHF)',
            disableInsurance:
                'Nein, ich habe eine Versicherung und werde im Schadensfall keine Ansprüche gegenüber placeB erheben',
            nextStep: 'Nächster Schritt',
            ok: 'OK',
            cancel: 'Abbrechen',
            durations: {
                day: 'Tag',
                week: 'Woche',
                month: 'Monat',
                year: 'Jahr',
            },
        },
        step2: {
            loginModal: {
                login: 'Anmelden',
                cancel: 'Abbrechen',
                email: 'E-Mail',
                password: 'Passwort',
                emailError: 'Falsche E-mail',
                passwordError: 'Das Passwort ist zu kurz',
            },
            passwordModal: {
                login: 'Anmelden',
                cancel: 'Abbrechen',
                choosePassword: 'Passwort festlegen',
                password: 'Passwort',
                email: 'E-Mail',
                passwordError: 'Das Passwort ist zu kurz',
                newPasswordPropose:
                    'Um die placeB App nutzen zu können, benötigst du ein persönliches Passwort. Erstelle hier ein Passwort und merke es dir gut.',
            },
            requiredFieldError: 'Dieses Feld ist Pflicht',
            loginButton: 'Hast du bereits ein Konto?',
            name: 'Name',
            firstName: 'Vorname',
            lastName: 'Nachname',
            company: 'Firma (optional)',
            country: 'Land',
            email: 'E-Mail',
            address: 'Adresse',
            address2: 'Adresszusatz (optional)',
            zip: 'Plz',
            city: 'Stadt',
            phone: 'Mobiltelefon',
            nextStep: 'Nächster Schritt',
            confirm: 'Nächster Schritt',
        },
        step3: {
            edit: 'Bearbeiten',
            invoice: 'Rechnung (+ CHF 5 / Rechnung)',
            accept: 'Ja, ich stimme den AGB zu',
            confirm: 'Jetzt buchen',
            paymentOptions: 'Zahlungsoptionen',
            couponCode: 'Gutscheincode',
            doYouHaveCode: 'Hast du einen Gutscheincode?',
            incorrectValue: 'Ungültige Wert',
            acceptTerms: 'Ja, ich stimme den AGBs zu',
        },
        bookingSuccess: {
            successLabel:
                'Danke! Du hast deine Box erfolgreich gebucht. Hier sind die nächsten Schritte:',
            verifyEmail: 'E-Mail verifizieren',
            installMobileApp: 'Installiere die placeB App auf deinem Telefon',
            openYourBox: 'Öffne die Box. Aber erst wenn du davor stehst!',
            bookedBox: 'Gebuchte Boxen',
            installPropose:
                'Installiere die placeB App damit du von allen Vorteilen profitieren kannst',
            personalInformation: 'Informationen',
            verified: 'Bestätigt',
            editInfo: 'Informationen bearbeiten',
            openBox: 'Box öffnen',
            openBuilding: 'Gebäude öffnen',
            viewDetails: 'Details anzeigen',
            mobileApp: 'Mobile App',
            youHaventInstall:
                'Du hast die App noch nicht auf deinem Telefon installiert!',
            verifyPhone: 'Telefonnummer verifizieren',
            feedbackForm:
                'https://docs.google.com/forms/d/e/1FAIpQLSeS2LdV6HkLtI1tmr8FSPEKCkcbJCdTa-6w-X-LVbULR9Z5Yg',
        },
        durationSelect: {
            perMonth: 'pro Monat',
            perWeek: 'pro Woche',
            perDay: 'pro Tag',
            ourBestseller: 'Unser Bestseller',
            durations: {
                day: 'Tag',
                days: 'Tage',
                week: 'Woche',
                weeks: 'Wochen',
                month: 'Monat',
                months: 'Monate',
                year: 'Jahr',
                years: 'Jahre',
            },
        },
        summaryRibbon: {
            insurance: 'Versicherung',
            invoice: 'Rechnung (+ CHF 5 / Rechnung)',
            total: 'Total',
            price: '%{price} CHF / %{unit}',
            priceMonth: '%{price} CHF / Monat',
            month: 'Monat',
            year: 'Jahr',
            week: 'Woche',
            day: 'Tag',
            bill: 'Rechnung',
        },
    },
    filter: {
        volume: 'Volumen',
        price: '%{price} CHF / %{unit}',
        sizeUnit: 'Grösse in m³',
        categoryItem: {
            priceString: 'Ab %{minPrice} CHF / Monat',
        },
        searchPage: {
            hintText: 'Gib deine PLZ oder dein Ort ein',
        },
    },
    boxItem: {
        volumeString: 'Volumen: %{volume} m³',
        areaString: 'Fläche: %{square} m²',
    },
    categoryDetails: {
        priceString: 'Ab %{minPrice} CHF / Monat',
        squareString: 'Fläche: %{square} m²',
        volumeString: 'Volumen: %{volume} m³',
        sizeString:
            'Grösse: %{depth} %{sizeUnits} × %{width}%{sizeUnits} × %{height} %{sizeUnits} (L×B×H)',
        aboutTheLocation: 'Über den Standort',
        bookNow: 'Jetzt buchen',
        wantCharged: 'Es fallen noch keine Kosten an',
        benefits: {
            a: 'Zugriff 24/7',
            b: 'Schlüssellos',
            c: 'Videoüberwacht',
        },
        price: '%{price} CHF / %{unit}',
        volume: 'Volumen',
    },
    userProfile: {
        personalInformation: 'Informationen',
        name: 'Name',
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma (optional)',
        country: 'Land',
        email: 'E-Mail',
        address: 'Adresse',
        address2: 'Adresszusatz (optional)',
        zip: 'Plz',
        city: 'Stadt',
        phone: 'Telefon',
        sendPostcard: 'Adressverifizierung versenden',
        verified: 'Bestätigt',
        saveChanges: 'Änderungen speichern',
        requiredFieldError: 'Dieses Feld ist Pflicht',
        resendSms: 'Verifikations SMS erneut versenden',
    },
    autoComplete: {
        popularLocations: 'Beliebte Standorte',
    },
    actions: {
        wrongCreds: 'Falsches E-Mail oder Passwort',
        registrationFailed: 'Registration fehlgeschlagen',
        reservationFailed: "Die Buchung konnte nicht abgeschlossen werden. Das könnte folgende Gründe haben:\n" +
            "• Deine Telefonnummer oder Emailadresse ist bereits bei uns im System hinterlegt (allenfalls hattest du früher bereits eine Buchung oder hast dich vorgängig bei uns registriert). In diesem Fall bitten wir dich bei der Personalienangabe auf \"mit bestehendem Konto anmelden\" zu klicken.\n" +
            "• Deine Zahlungsmethode ist nicht gedeckt. Bitte lade deine Kreditkarte auf oder wähle eine andere Zahlungsmethode.",
        error: 'Fehler',
        cantSave: 'Speichern nicht möglich',
        cantOpen: 'Öffnen nicht möglich',
        profileUpdated: 'Änderungen übernommen',
        noDoors: 'Keine Türen zum öffnen',
    },
    registration: {
        confirm: 'Registrieren',
    },
    auth: {
        error: 'Anmelden fehlgeschlagen',
    },
}
