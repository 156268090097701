import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as yup from 'yup'
import { createTranslator } from '../../../../services'

yup.addMethod(yup.string, 'validateNumber', function () {
    return this.test('phoneValidation', 'incorrectPhone', value => {
        const test = parsePhoneNumberFromString(value)
        return test && test.isValid()
    })
})

const t = createTranslator('checkoutProcess.userDetails')

export default yup.object().shape({
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    email: yup.string().email('incorrectEmail').required('required'),
    phone: yup.string().required('required').validateNumber(),
    company: yup.string().notRequired().nullable(),
    street: yup.string().required('required'),
    additionalStreet: yup.string().notRequired().nullable(),
    zip: yup
        .string()
        //.matches(/^(\d{4,5})$/i, 'incorrectZip')
        .required('required'),
    city: yup.string().required('required'),
    country: yup.string().required('required'),
})
