import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getShowCalculatorFlag,
    showCalculatorSelector,
} from './sizeTypeCalculatorDuck'
import SizeTypeCalculatorPortal from './SizeTypeCalculatorPortal'

const mapStateToProps = state => ({
    visible: getShowCalculatorFlag(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators({ showSelector: showCalculatorSelector }, dispatch)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SizeTypeCalculatorPortal)
