import geolib from 'geolib'
import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { getLocations } from '../../../redux/modules/locationsDuck'
import {
    getSelectedCategory,
    getSelectedCategoryObject,
    getSelectedDuration,
} from '../../../redux/modules/productDuck'
import { actionCreator } from '../../../services'
import { getSelectedPosition } from '../LocationSelector'

export const moduleName = 'cheaperModal'
const defaultValue = {
    isCheaperBoxVisible: false,
}
const createAction = actionCreator(moduleName)

export const setCheaperBoxVisible = createAction('SET_CHEAPER_BOX_VISIBLE')
export const showCheaperBoxDetails = createAction('SHOW_CHEAPER_BOX_DETAILS')
export const recalculateCheaperBox = createAction('RECALCULATE_CHEAPER_BOX')

export default handleActions(
    {
        [setCheaperBoxVisible]: (state, action) => ({
            ...state,
            isCheaperBoxVisible: action.payload,
        }),
        [showCheaperBoxDetails]: state => ({
            ...state,
            isCheaperBoxVisible: true,
        }),
    },
    defaultValue
)

export const getCheaperBoxVisibleFlag = state =>
    state[moduleName].isCheaperBoxVisible
export const getCheapestBox = createSelector(
    [
        getLocations,
        getSelectedDuration,
        getSelectedCategory,
        getSelectedCategoryObject,
        getSelectedPosition,
    ],
    (
        locations,
        selectedDuration,
        selectedCategory,
        currentCategory,
        selectedPosition
    ) => {
        if (!currentCategory) {
            return null
        }
        const duration = currentCategory.durations[selectedDuration]
        if (!duration) {
            return null
        }
        const minPriceCategory = duration.price
        if (!selectedPosition) {
            return null
        }
        const cheaperLocations = locations
            .filter(location =>
                location.categories.find(
                    category =>
                        category.title === selectedCategory &&
                        category.durations[selectedDuration] &&
                        category.durations[selectedDuration].price <
                            minPriceCategory
                )
            )
            .sort((prev, next) => {
                return (
                    geolib.getDistanceSimple(selectedPosition, prev) -
                    geolib.getDistanceSimple(selectedPosition, next)
                )
            })
        const cheapestLocation = cheaperLocations[0]
        if (!cheapestLocation) {
            return null
        }
        const cheapestCategory = cheapestLocation.categories.find(
            category => category.title === selectedCategory
        )
        return {
            category: cheapestCategory,
            cheapestLocation: cheapestLocation,
            selectedDuration: selectedDuration,
        }
    }
)
