import { createAction as createReduxAction } from 'redux-actions'
import { routes } from '../../../constants'
import history from '../../../core/history'
import moduleName from './moduleName'

const createAction = (action, ...args) =>
    createReduxAction(`${moduleName}/${action}`, ...args)

export const changeDuration = createAction('CHANGE_DURATION', duration => ({
    duration,
}))
export const changeCategory = createAction('CHANGE_CATEGORY')
export const changeSizeType = createAction('CHANGE_SIZE_TYPE')
export const changeLocation = createAction('CHANGE_LOCATION')
export const boxFinderReady = createAction('BOX_FINDER_READY')
export const userChangedSize = createAction('USER_CHANGED_SIZE')
export const userChangedSizeType = createAction('USER_CHANGED_SIZE_TYPE')
export const userSelectedLocationFromSlider = createAction(
    'USER_SELECTED_LOCATION_FROM_SLIDER'
)
export const valueChanged = createAction('CHECK_IS_VALUE_CHANGED')
export const presetValues = createAction('PRESET_VALUES')

export const openBoxFinder = () => history.push(`/${routes.finder}`)
