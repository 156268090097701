// import type { Stream } from '../../../../types';
import queryString from 'query-string'
import * as R from 'ramda'
import { ofType } from 'redux-observable'
import { mergeMap, take } from 'rxjs/operators'
import history from '../../../../core/history'
import {
    getLocations,
    locationsLoaded,
} from '../../../../redux/modules/locationsDuck'
// import type { StateObservable } from 'redux-observable';
import {
    boxFinderReady,
    changeLocation,
    presetValues,
} from '../../../../redux/modules/productDuck'
import { findAvailableCategory } from '../../../../redux/modules/productDuck/helpers'
import { showLocationSelector } from '../../LocationSelector/locationSelectorDuck'
import { showRadiusSelector } from '../../RadiusSelector'
import { showCalculatorSelector } from '../../SizeTypeCalculator'

export default (action$ /*: Stream*/, state$ /*: StateObservable*/) =>
    action$.pipe(
        ofType(locationsLoaded.toString()),
        take(1),
        mergeMap(() => {
            const locations = getLocations(state$.value)
            const location =
                locations.find(findAvailableCategory) || locations[0]
            const queryObject = queryString.parse(history.location.search)
            if (!queryObject || R.isEmpty(queryObject)) {
                return [
                    changeLocation({
                        location,
                        deep: true,
                        setPosition: true,
                        changedBySystem: true,
                        init: true,
                    }),
                    presetValues({}),
                ]
            }
            const { l, c, d, st, ls, rs, calc } = queryObject
            const stream = [
                presetValues({
                    selectedLocation: +l,
                    selectedCategoryType: st,
                    selectedCategory: c,
                    selectedDuration: +d,
                }),
                boxFinderReady(),
            ]
            if (+ls) {
                stream.push(showLocationSelector())
            }
            if (+rs) {
                stream.push(showRadiusSelector())
            }
            if (+calc) {
                stream.push(showCalculatorSelector())
            }
            return stream
        })
    )
