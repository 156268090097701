import { actionTypes, clearSubmitErrors } from 'redux-form'
import { combineEpics, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { filter, ignoreElements, mapTo, skip, take, tap } from 'rxjs/operators'
import history from '../../../../core/history'
import { getIsValueChanged } from '../../../../redux/modules/productDuck'
import { confirmButtonClicked, getCheapestBox } from '../../../BoxFinder'
import * as Payment from '../../Payment'
import { openBooking } from '../actions'
import createReservationEpic from './createReservationEpic'
import paymentConfirmedEpic from './paymentConfirmedEpic'
import registerUserEpic from './registerUserEpic'
import reservationFailedEpic from './reservationFailedEpic'
import startReservationEpic from './startReservationEpic'
import updateUserEpic from './updateUserEpic'

const confirmButtonClickedEpic1 = (action$, state$) => {
    const confirmButtonClicked$ = action$.pipe(
        ofType(confirmButtonClicked.toString())
    )
    return Rx.merge(
        confirmButtonClicked$.pipe(
            take(1),
            filter(
                () =>
                    getIsValueChanged(state$.value) ||
                    !getCheapestBox(state$.value)
            )
        ),
        confirmButtonClicked$.pipe(skip(1))
    ).pipe(mapTo(openBooking()))
}
const openBookingEpic = action$ =>
    action$.pipe(
        ofType(openBooking.toString()),
        tap(() => history.push('/booking')),
        ignoreElements()
    )
const resetCreditCardErrorsEpic = action$ =>
    action$.pipe(
        ofType(actionTypes.CHANGE),
        filter(
            ({ payload, meta: { field, form } }) =>
                field === 'invoice' && form === Payment.moduleName && payload
        ),
        mapTo(clearSubmitErrors(Payment.moduleName, 'creditCard'))
    )

export default combineEpics(
    confirmButtonClickedEpic1,
    openBookingEpic,
    reservationFailedEpic,
    startReservationEpic,
    registerUserEpic,
    createReservationEpic,
    updateUserEpic,
    resetCreditCardErrorsEpic,
    paymentConfirmedEpic
)
