import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
    display: flex;
    flex: 1;
`
const RightPadding = styled(Root)`
    padding-left: 10px;
`
const LeftPadding = styled(Root)`
    padding-right: 10px;
`

const RowForTwo = ({ children }) => (
    <Root>
        <LeftPadding>{children[0]}</LeftPadding>
        <RightPadding>{children[1]}</RightPadding>
    </Root>
)

RowForTwo.propTypes = {}
export default RowForTwo
