import getProductState from './getProductState'
import readableDuration from './readableDuration'
import removeLocationNameWhiteSpace from './removeLocationNameWhiteSpace'
import with2Digits from './with2Digits'

export default state => {
    const {
        location,
        category,
        duration,
        quantity,
        price,
        priceTotal,
        sizeType,
    } = getProductState(state)
    return {
        name: category,
        category: removeLocationNameWhiteSpace(location.name),
        quantity,
        priceTotal,
        price,
        variant: duration ? readableDuration(duration) : '',
        dimension13: sizeType.toUpperCase(),
        dimension15: quantity && with2Digits(quantity / 7),
        dimension16: quantity && with2Digits(quantity / 30),
    }
}
