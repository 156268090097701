import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as R from 'ramda'
import * as React from 'react'
import { connect } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import Input from '../../components/Input'
import { getProfileFormValues } from '../../UserDetails/ProfileForm'
import duck from '../duck'

const tPrefix = 'bookingPage.step2.passwordModal'

class PasswordModal extends React.Component {
    state = { password: '', errorFlag: false }

    onChange = value => {
        this.setState({ password: value })
    }

    onConfirm = () => {
        const { password } = this.state
        if (password.length < 2) {
            return this.setError()
        }
        this.props.onConfirm(password)
    }

    setError = () => {
        this.setState(() => ({ errorFlag: true }))
    }

    resetError = () => {
        this.setState(() => ({ errorFlag: false }))
    }

    render() {
        const passwordErrorText = I18n.t(`${tPrefix}.passwordError`)
        const { errorFlag, password } = this.state
        const { isVisible, onCancelButtonPress } = this.props
        return (
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                onClose={onCancelButtonPress}
                open={isVisible}
                //classes={{ paper: classes.root }}
            >
                <DialogTitle id="alert-dialog-title">
                    {I18n.t(`${tPrefix}.choosePassword`)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Translate value={`${tPrefix}.newPasswordPropose`} />
                        <p>
                            {I18n.t(`${tPrefix}.email`) + ': '}
                            {this.props.email}
                        </p>
                        <input type="email" hidden />
                        <InputWrapper
                            error={errorFlag}
                            errorText={passwordErrorText}
                            onChange={this.onChange}
                            onClick={this.resetError}
                            title={I18n.t(`${tPrefix}.password`)}
                            type="password"
                            value={password}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancelButtonPress}>
                        {I18n.t(`${tPrefix}.cancel`)}
                    </Button>
                    <Button color="primary" onClick={this.onConfirm}>
                        {'Submit'} {/*TODO*/}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const InputWrapper = styled(Input)`
    && {
        margin-top: 5px;
    }
    @supports (-ms-ime-align: auto) {
        width: 100% !important;
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        width: 100% !important;
    }
    @-moz-document url-prefix() {
        width: 100% !important;
    }
`

const mapStateToProps = state => ({
    isVisible: duck.selectors.getIsPasswordModalVisible(state),
    email: R.compose(R.prop('email'), getProfileFormValues)(state),
})
const mapDispatchToProps = {
    onCancelButtonPress: duck.actions.hidePasswordModal,
    onConfirm: duck.actions.passwordModalConfirmed,
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(PasswordModal)
