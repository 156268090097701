export default {
    header: 'Étape 3 : Paiement',
    paymentOptions: 'Options de paiement',
    creditCard: 'Carte de crédit',
    invoice: 'Facture (+ CHF 5 / facture)',
    couponCode: 'Code promo',
    enterCode: 'Saisir le code',
    confirm: 'Réserver maintenant',
    required: 'Ce champ est obligatoire',
    incorrectValue: 'Valeur invalide',
    termsRequired: `Merci d'accepter nos conditions générales.`,
    acceptTerms: `Oui, j'accepte les <a href="https://www.placeb.ch/fr/terms" target="_blank">Conditions générales de vente</a> et les <a href="https://www.placeb.ch/fr/privacy-policy" target="_blank">Protection des données</a>`,
    hint: `Remarque importante : il est possible d'annuler sa réservation sans frais dans les 7 jours qui suivent.`,
    invoiceDepot:
        'Facture avec paiement anticipé & dépôt (+ CHF 5 / facture, dépôt : 3 mois de loyer)',
    invoiceDepotHelpText: `L'accès sera activé dès que nous aurons reçu le premier mois de loyer ainsi que le dépôt de garantie.`,
    invoice3rdParty:
        'Paiement par une autorité sociale (demande de remboursement nécessaire)',
    invoice3rdPartyHelpText: `L'accès sera activé dès que nous aurons reçu la déclaration de prise en charge des frais de l'autorité sociale.`,
    ratingInformation: `<p>Merci pour ta réservation. Afin de pouvoir offrir les meilleurs prix possibles avec le mode de paiement "facture", nous devons nous protéger contre d'éventuels défauts de paiement. C'est la raison pour laquelle nous effectuons un contrôle de solvabilité pour tous nos clients. L'examen de ta demande a révélé que tu ne figurais pas dans la base de données de notre prestataire de services externe dans le domaine des informations de crédit, Intrum, avec une notation requise.</p>

    <p>Bien entendu, tu peux quand même effectuer la réservation souhaitée. Cependant, nous te prions de comprendre que nous devons exiger un paiement anticipé, y compris un dépôt, une déclaration de frais par une autorité sociale ou un paiement par carte de crédit. Nous te remercions de nous informer sur le mode de paiement que tu souhaites utiliser.</p>
    
    <p>Afin que nous puissions nous conformer au mode de paiement que tu souhaites lors de tes futures réservations, tu as la possibilité d'envoyer une confirmation de domicile à la <a href="https://www.intrum.ch/de/konsumenten/uber-intrum/ihre-privatsphare-ist-unsere-prioritat/kontaktformular-erlauterung-bonitatsprufung/" target="_blank">société Intrum.</a></p>
    `,
    moreInfo: `Plus d'informations`,
    close: 'Fermer',
    applyCouponCode: 'Vérifier',
}
