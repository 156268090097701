import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { submit } from 'redux-form'
import { loginUser, getLoggedUserProfile } from '../../../redux/modules/auth'
import {
    getPreopenedFlag,
    getSelectedCategoryObject,
    getSelectedDurationObject,
} from '../../../redux/modules/productDuck'
import { openBooking, openPayment } from '../checkoutDuck/actions'
import { totalPricePerMonth } from '../checkoutDuck/selectors'
import UserDetails from './UserDetails'

const mapStateToProps = state => ({
    price: totalPricePerMonth(state),
    category: getSelectedCategoryObject(state),
    preOpened: getPreopenedFlag(state),
    duration: getSelectedDurationObject(state),
    user: getLoggedUserProfile(state),
    openPayment,
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            openBooking,
            login: loginUser,
            onSubmit: R.always(submit('profileForm')),
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
