import * as R from 'ramda'
import {
    actionTypes as reduxFormTypes,
    change as changeFormValue,
    formValueSelector,
    getFormValues as _getFormValues,
} from 'redux-form'
import { ofType } from 'redux-observable'
import { filter, mapTo } from 'rxjs/operators'
import { actionCreator, globalizeSelectors } from '../../../services'
import checkoutDuckName from '../checkoutDuck/moduleName'
import moduleName from './moduleName'

const globalize = globalizeSelectors(R.prop(checkoutDuckName))
const createAction = actionCreator(moduleName)
const showPasswordModal = createAction('SHOW_PASSWORD_MODAL', R.F)
const hidePasswordModal = createAction('HIDE_PASSWORD_MODAL', R.F)
const showRatingModal = createAction('SHOW_RATING_MODAL', R.F)
const hideRatingModal = createAction('HIDE_RATING_MODAL', R.F)
const passwordModalConfirmed = createAction('PASSWORD_MODAL_CONFIRMED')
const formConfirmed = createAction('FORM_CONFIRMED')
const actions = {
    showPasswordModal,
    hidePasswordModal,
    passwordModalConfirmed,
    formConfirmed,
    showRatingModal,
    hideRatingModal,
}

const getFormValues = _getFormValues(moduleName)
const getIsPasswordModalVisible = globalize(R.prop('isPasswordModalVisible'))
const getIsRatingModalVisible = globalize(R.prop('isRatingModalVisible'))
const selectors = {
    getFormValues,
    getIsPasswordModalVisible,
    getIsRatingModalVisible,
}

const epic = (action$, state$) =>
    action$.pipe(
        ofType(reduxFormTypes.FOCUS),
        filter(() => formValueSelector(moduleName)(state$.value, 'invoice')),
        filter(() =>
            formValueSelector(moduleName)(state$.value, 'invoice3rdParty')
        ),
        filter(({ meta: { field } }) => field.includes('creditCard')),
        mapTo(changeFormValue(moduleName, 'invoice', false))
    )

export default { actions, selectors, epic }
