import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import type { Dispatch } from 'redux';
// import type { Props } from './CheaperModal';
import {
    changeLocation,
    getPreopenedFlag,
    getSelectedCategoryTypeObject,
} from '../../../redux/modules/productDuck'
import CheaperModal from './CheaperModal'
import {
    getCheaperBoxVisibleFlag,
    getCheapestBox,
    setCheaperBoxVisible,
} from './recalculateCheaperBox'
// import type { Action } from '../../../types';
const mapStateToProps = state => ({
    isLocationPreopened: getPreopenedFlag(state),
    cheapestBox: getCheapestBox(state),
    selectedCategoryType: getSelectedCategoryTypeObject(state),
    isVisible: getCheaperBoxVisibleFlag(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            onClose: setCheaperBoxVisible,
            changeLocation: location =>
                changeLocation({ location, setPosition: true }),
        },
        dispatch
    )

const CheaperModalContainer = (props /*: Props & { isVisible: false }*/) =>
    props.isVisible ? <CheaperModal {...props} /> : null

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheaperModalContainer)
