export default {
    header: 'Schritt 3: Bezahlung',
    paymentOptions: 'Zahlungsoptionen',
    creditCard: 'Kreditkarte',
    invoice: 'Rechnung (+ CHF 5 / Rechnung)',
    couponCode: 'Gutscheincode',
    enterCode: 'Code eingeben',
    confirm: 'Jetzt buchen',
    required: 'Dieses Feld ist Pflicht',
    incorrectValue: 'Ungültige Wert',
    termsRequired: 'Bitte unsere Geschäftsbedingungen akzeptieren.',
    acceptTerms:
        'Ja, ich akzeptiere die <a href="https://www.placeb.ch/terms" target="_blank">AGBs</a> und die <a href="https://www.placeb.ch/privacy-policy" target="_blank">Datenschutzerklärung</a>',
    hint: 'Wichtiger Hinweis: Du kannst deine Buchung in den nächsten 7 Tagen kostenlos stornieren',
    invoiceDepot:
        'Rechnung mit Vorauszahlung & Depot (+ CHF 5 / Rechnung, Depot: 3 Monatsmieten)',
    invoiceDepotHelpText:
        'Der Zugang wird freigeschaltet, sobald die erste Monatsmiete sowie das Depot bei uns eingegangen ist.',
    invoice3rdParty:
        'Bezahlung durch eine Sozialbehörde (Kostenfürsprache notwendig)',
    invoice3rdPartyHelpText:
        'Der Zugang wird freigeschaltet, sobald die Kostenfürsprache der Sozialbehörde bei uns eingegangen ist.',
    ratingInformation: `<p>Vielen Dank für deine Buchung. Um die auch bei der Zahlungsart “Rechnung” die bestmöglichen Preise anbieten zu können, müssen wir uns von allfälligen Zahlungsausfällen schützen. Aus diesem Grund führen wir bei allen Kunden eine Bonitätsprüfung durch. Die Prüfung deiner Anfrage hat ergeben, dass du bei unserem externen Dienstleister im Bereich Credit Information, der Intrum, nicht mit einem erforderlichen Rating in der Datenbank verzeichnet bist.</p>

    <p>Selbstverständlich kannst du trotzdem die gewünschte Buchung vornehmen. Jedoch bitten wir dich um Verständnis, dass wir auf eine Vorauszahlung inkl. Depot, einer Kostenfürsprache durch eine Sozialbehörde oder auf Bezahlung mit Kreditkarte bestehen müssen. Wir danken danken dir für eine entsprechende Information hinsichtlich der von dir gewünschten Zahlungsmethode.</p>
    
    <p>Damit wir bei zukünftigen Buchungen der von dir gewünschten Zahlungsweise entsprechen können, hast du die Möglichkeit, bei der <a href="https://www.intrum.ch/de/konsumenten/uber-intrum/ihre-privatsphare-ist-unsere-prioritat/kontaktformular-erlauterung-bonitatsprufung/" target="_blank">Firma Intrum eine Wohnsitzbestätigung einzureichen.</a></p>
    `,
    moreInfo: 'Mehr infos',
    close: 'Schliessen',
    applyCouponCode: 'Überprüfen',
}
