const flipStructure = obj => {
    const key = Object.keys(obj)[0]
    const languages = Object.keys(obj[key])
    return languages.reduce(
        (accum, next) => ({ ...accum, [next]: { [key]: obj[key][next] } }),
        {}
    )
}
const mergeObjects = (a, b) => {
    return Object.keys(b).reduce(
        (accum, next) => ({ ...accum, [next]: { ...a[next], ...b[next] } }),
        {}
    )
}
const reducer = (accum, next) => {
    const flipped = flipStructure(next)
    return mergeObjects(accum, flipped)
}

export default translations => translations.reduce(reducer, {})
