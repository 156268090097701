import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import rest from '../../../../services/rest'
import {
    registerUser,
    registrationFailed,
    registrationSuccess,
} from '../actions'

export default action$ =>
    action$.pipe(
        ofType(registerUser),
        mergeMap(async ({ payload: { user } }) => {
            try {
                await rest.registerUser(user)
                return registrationSuccess()
            } catch (error) {
                return registrationFailed({ error })
            }
        })
    )
