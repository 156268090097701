export default {
    header: 'Schritt 2: Deine Details',
    fillDetails: 'Details ausfüllen',
    loginButton: 'Anmelden bei bestehendem Konto',
    firstName: 'Vorname',
    lastName: 'Nachname',
    company: 'Firma (optional)',
    country: 'Land',
    email: 'E-Mail',
    address: 'Adresse',
    address2: 'Adresszusatz (optional)',
    zip: 'Plz',
    city: 'Stadt',
    phone: 'Mobiltelefon',
    phoneHelperText: 'Bitte eine Mobiltelefonnummer angeben',
    nextStep: 'Nächster Schritt',
    confirm: 'Nächster Schritt',
    required: 'Dieses Feld ist Pflicht',
    invalidCountry: 'Kein gültiges Land',
    incorrectEmail: 'Falsche E-mail',
    passwordError: 'Das Passwort ist zu kurz',
    incorrectPhone: 'Ungültige Mobiltelefonnummber',
    incorrectZip: 'ungültige PLZ',
    loginModal: {
        logIn: 'Anmelden',
        email: 'E-Mail',
        cancel: 'Abbrechen',
        password: 'Passwort',
        emailError: 'Falsche E-mail',
        passwordError: 'Das Passwort ist zu kurz',
        requiredField: 'Dieses Feld ist Pflicht',
    },
}
