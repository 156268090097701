import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import Header from '../../components/Header/Header'

const tPrefix = 'checkoutProcess.payment'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const PaymentHeader = ({ onButtonClick }) => (
    <Header onButtonClick={onButtonClick} step={3} title={t('header')} />
)

PaymentHeader.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
}
export default PaymentHeader
