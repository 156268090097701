import { routes } from '../../../../constants'
import { PageType } from '../constants'
import getProductState from './getProductState'
import readableDuration from './readableDuration'
import removeLocationNameWhiteSpace from './removeLocationNameWhiteSpace'

export default state => {
    const { location, category, duration, sizeType } = getProductState(state)
    return {
        page: `/${routes.finder}`,
        pageType: PageType.boxFinder,
        ecommerce: {
            detail: {
                products: [
                    {
                        category: removeLocationNameWhiteSpace(location.name),
                        name: category,
                        variant: duration ? readableDuration(duration) : '',
                        dimension13: sizeType.toUpperCase(),
                    },
                ],
            },
        },
    }
}
