import React from 'react'
import styled from 'styled-components'
import { addressItemTitle } from '../components/commonComponents'
import LocationSelector from './components/LocationSelector'
import LocationSelectorFace from './components/LocationSelectorFace'
// import type { PlacebLocation, Position, Suggestion } from '../../../types';

const Root = styled.div`
    position: relative;
    min-width: 0;
`
// type Props = {|
//   locations: Array<PlacebLocation>,
//   onChange: Position => any,
//   value: Position,
//   onInputChange: string => any,
//   suggestions: Array<Suggestion>,
//   userPosition: Position,
//   userGeolocation: Position,
//   className: string,
//   showSelector: void => any,
//   setShowSelector: boolean => any,
//   hideLocationSelector: void => any,
//   showLocationSelector: void => any,
// |};
const LocationSelectorPortal = (
    {
        locations,
        value,
        className,
        suggestions,
        userGeolocation,
        onInputChange,
        onChange,
        showSelector,
        showLocationSelector,
        hideLocationSelector,
    } /*: Props*/
) => (
    <Root className={className}>
        <LocationSelectorFace
            onClick={showLocationSelector}
            value={value ? addressItemTitle`${value}` : ''}
        />
        {showSelector && (
            <LocationSelector
                locations={locations}
                onChange={onChange}
                onClose={hideLocationSelector}
                onInputChange={onInputChange}
                suggestions={suggestions}
                userGeolocation={userGeolocation}
            />
        )}
    </Root>
)

export default LocationSelectorPortal
