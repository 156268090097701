import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import withStyles from '@material-ui/core/styles/withStyles'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import * as React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n-lite'
import { Fields } from 'redux-form'
import styled from 'styled-components'
import { withLocale } from '../../../../core/ApplicationContext'
import FlexContainer from '../../../components/FlexContainer'
import duck from '../duck'
import BaseCreditCardForm from '../../components/creditCardForm'
import RatingModal from './RatingModal'
import moduleName from '../moduleName'

const tPrefix = 'checkoutProcess.payment'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const styles = {
    iconStyle: {
        height: '20px',
        width: '20px',
    },
    radio: {
        borderColor: '#DDDDDD',
        paddingTop: '12px',
        paddingLeft: 19,
        paddingRight: 19,
        cursor: 'pointer',
    },
    radioIcon: {
        width: 23,
        'margin-right': 9,
    },
    label: {
        fontSize: 16,
    },
    radioInvoice: {
        marginTop: '32px',
    },
}
const Root = styled(FlexContainer)`
    align-self: stretch;
    align-items: stretch;
    margin-left: -17px;
`

const CreditCardForm = styled(BaseCreditCardForm)`
    padding-left: 62px;
`

const StrongStyle = styled.span`
    font-weight: bold;
`

const HelpText = styled.div`
    font-size: 14px;
`

const MoreInfo = styled.a`
    margin: 20px 0 0 20px;
    font-size: 14px;
    text-decoration: none;
    color: #80c700;
    font-weight: bold;
    font-family: Helvetica, sans-serif;
    cursor: pointer;
`

const StyledFormControlLabel = styled(FormControlLabel)`
    padding-top: 0px !important;
    margin-top: 14px;
    span {
        padding-top: 0;
        padding-bottom: 0;
    }
    &:first-child {
        margin-top: 0;
    }
`

const StyledCreditCardFormContainer = styled.div`
    margin-top: 10px;
`

class PaymentSelector extends React.Component /*<Props>*/ {
    enableFields = null

    renderEnableCard = (invoice, invoice3rdParty, onChange) => {
        return (
            <StyledCreditCardFormContainer
                key={'payment-credit-card-container'}
            >
                <StyledFormControlLabel
                    checked={
                        !invoice.input.value && !invoice3rdParty.input.value
                    }
                    classes={{
                        root: this.props.classes.radio,
                        label: this.props.classes.label,
                    }}
                    control={
                        <Radio
                            classes={{ root: this.props.classes.radioIcon }}
                            color={'primary'}
                            name="payment"
                        />
                    }
                    label={<StrongStyle>{t('creditCard')}</StrongStyle>}
                    onChange={onChange}
                />
            </StyledCreditCardFormContainer>
        )
    }

    renderCreditCardForm = (creditCard, onChange) => {
        return (
            <ElementsConsumer key={'payment-elements'}>
                {({ elements, stripe }) => (
                    <CreditCardForm
                        elements={elements}
                        field={creditCard}
                        formName={moduleName}
                        locale={this.props.locale}
                        onClick={onChange}
                        stripe={stripe}
                    />
                )}
            </ElementsConsumer>
        )
    }

    renderEnableInvoice = ({ input: { value } }, label, onChange) => (
        <StyledFormControlLabel
            key={'payment-invoice'}
            checked={!!value}
            classes={{
                root: this.props.classes.radio,
                label: this.props.classes.label,
            }}
            control={
                <Radio
                    classes={{ root: this.props.classes.radioIcon }}
                    color={'primary'}
                    name="payment"
                    tabIndex={value ? 0 : -1}
                    disableRipple
                />
            }
            label={label}
            onChange={onChange}
        />
    )

    renderEnableInvoice3rdParty = ({ input: { value } }, onChange) => (
        <StyledFormControlLabel
            key={'payment-invoice-3rdParty'}
            checked={!!value}
            classes={{
                root: this.props.classes.radio,
                label: this.props.classes.label,
            }}
            control={
                <Radio
                    classes={{ root: this.props.classes.radioIcon }}
                    color={'primary'}
                    name="payment"
                    tabIndex={value ? 0 : -1}
                    disableRipple
                />
            }
            label={
                <>
                    <StrongStyle>{t('invoice3rdParty')}</StrongStyle>
                    <HelpText>{t('invoice3rdPartyHelpText')}</HelpText>
                </>
            }
            onChange={onChange}
        />
    )

    renderFields = fields => {
        if (!this.enableFields) {
            this.enableFields = (invoiceValue, invoice3rdPartyValue) => {
                fields.invoice.input.onChange(invoiceValue)
                fields.invoice3rdParty.input.onChange(invoice3rdPartyValue)
            }
        }

        const fieldsArray = [
            this.renderEnableCard(fields.invoice, fields.invoice3rdParty, () =>
                this.enableFields(false, false)
            ),
            this.renderCreditCardForm(fields.creditCard, () =>
                this.enableFields(false, false)
            ),
        ]

        if (this.props.rating?.green || this.props.rating?.rating === 'X') {
            fieldsArray.push(
                this.renderEnableInvoice(
                    fields.invoice,
                    <StrongStyle>{t('invoice')}</StrongStyle>,
                    () => this.enableFields(true, false)
                )
            )
        } else {
            fieldsArray.push(
                this.renderEnableInvoice(
                    fields.invoice,
                    <>
                        <StrongStyle>{t('invoiceDepot')}</StrongStyle>
                        <HelpText>{t('invoiceDepotHelpText')}</HelpText>
                    </>,
                    () => this.enableFields(true, false)
                ),
                this.renderEnableInvoice3rdParty(fields.invoice3rdParty, () =>
                    this.enableFields(false, true)
                )
            )
        }
        return fieldsArray
    }

    render() {
        const { className } = this.props
        return (
            <Root className={className}>
                <Fields
                    component={this.renderFields}
                    names={['invoice', 'creditCard', 'invoice3rdParty']}
                />
                {!this.props.rating?.green && (
                    <>
                        <MoreInfo
                            href="#"
                            onClick={ev => {
                                ev.preventDefault()
                                this.props.onShowRatingModal()
                            }}
                        >
                            {t('moreInfo')}
                        </MoreInfo>
                        <RatingModal isVisible />
                    </>
                )}
            </Root>
        )
    }
}

const mapStateToProps = state => ({
    // isVisible: duck.selectors.getIsRatingModalVisible(state),
})
const mapDispatchToProps = {
    onShowRatingModal: duck.actions.showRatingModal,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

export default withStyles(styles)(withLocale(connector(PaymentSelector)))
