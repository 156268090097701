import {
    dimensions,
    durations,
    durationsMultiple,
    sizeTypes,
} from '../../../../translations/frCommon'

export default {
    header: 'Étape 1 : Réservation',
    boxInformation: 'Informations sur la box',
    size: 'Taille',
    sizes: {
        extraSmall: 'Extra small',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        extraLarge: 'Extra large',
    },
    sizeTypes,
    dimensions,
    durations,
    durationsMultiple,
    location: 'Lieu',
    duration: 'Durée minimale de location',
    startDate: 'Sélectionnez Date de début',
    requiredFieldError: 'Ce champ est obligatoire',
    minimumTerm: 'Durée minimale',
    insurance: 'Assurance (obligatoire)',
    insuranceDescription: 'Les frais sont de 6 CHF par mois pour chaque tranche de 10’000 CHF de marchandises.',
    confirmInsurance: 'Oui, j\'ai besoin d\'une assurance placeB (recommandé)\n',
    boxValue: 'Valeur des marchandises (CHF)',
    disableInsuranceStrong: `Non, je n\'ai pas besoin d\'une assurance placeB et je sais que je dois annoncer à mon assurance que mes objets sont entreposés chez un tiers.`,
    disableInsurance: ' Je ne ferai valoir aucune prétention à l\'égard de placeB en cas de dommage.',
    nextStep: 'Prochaine étape',
    ok: 'OK',
    cancel: 'Annuler',
    insurancePartner: `Ton partenaire d'assurance`,
    per: 'par',
    noticePeriod: 'Délais de résiliation',
    noticePeriodDescription:
        'Sans résiliation, le contrat est automatiquement prolongé de %{period}. Mais tu peux aussi résilier de manière flexible avant l\'échéance.',
    insuranceInformation: 'Assurance',
    insuranceInformationDescription: `Une assurance contre le vol, l'incendie et les dégâts des eaux est obligatoire. Mais tu peux aussi résilier de manière flexible avant l'échéance.`,
    moreInformation: `Plus d'informations`,
    incorrectValue: 'Valeur invalide',
    modalTitle1: 'Délais de résiliation',
    modalText1: `Il n'y a pas de délai de résiliation. Sans résiliation, le contrat se prolonge simplement d'un mois à chaque fois (ou d'une semaine si tu as choisi un contrat hebdomadaire). Mais ce n'est pas un problème si tu n'as plus besoin du box de stockage avant. Dans ce cas, la réduction est corrigée rétroactivement si nécessaire et tu peux nous quitter à tout moment dans un délai d'une semaine. Pour cela, il suffit de nous envoyer un e-mail à info@placeb.ch.
    \n \t Attention : si tu résilies le dernier jour, assure-toi que ton espace est vide, car tu n'auras ensuite plus accès à la placeB Box.
    \n \t Tu peux résilier ton contrat très facilement dans l'app placeB :
    \r \t - connecte-toi à l'app
    \r \t - clique sur "Mes boxes".
    \r \t - Sélectionne la boîte
    \r \t - clique sur le bouton de menu (trois points dans le coin supérieur droit) et ensuite sur Détails du contrat. Tu y trouveras tout en bas un bouton vert avec l'inscription "Résilier".
    \n \t Terminé !`,
    modalTitle2: 'Mes affaires sont-elles assurées ?',
    modalText2: `Pas automatiquement. Mais une assurance contre le vol, l'incendie et les dégâts des eaux est obligatoire. Tu as deux possibilités :
    \n \t Soit tu contactes ton assurance habitation, soit tu assures les objets stockés chez nous.
    \n \t Il suffit de cocher la case correspondante dans le contrat. Les coûts, qui s'élèvent à 6 francs par mois pour 10’000 francs de biens, sont généralement moins élevés que ceux d'une assurance habitation personnelle.`,
}
