import ReduxQuerySync from 'redux-query-sync'
import {
    getShowCalculatorFlag,
    getShowLocationSelectorFlag,
    getShowRadiusSelectorFlag,
} from '../components/BoxFinder'
import history from '../core/history'
import {
    getSelectedCategory,
    getSelectedCategoryType,
    getSelectedDuration,
    getSelectedLocation,
} from './modules/productDuck'

const action = () => _ => _

export default function (store) {
    ReduxQuerySync({
        store,
        initialTruth: 'location',
        replaceState: true,
        history,
        params: {
            l: { selector: getSelectedLocation, action }, //location
            st: { selector: getSelectedCategoryType, action }, //sizeType
            c: { selector: getSelectedCategory, action: action }, //category
            d: { selector: getSelectedDuration, action: action }, //duration
            ls: {
                selector: s => (getShowLocationSelectorFlag(s) ? 1 : 0),
                action,
            }, //showLocationSelector
            rs: {
                selector: s => (getShowRadiusSelectorFlag(s) ? 1 : 0),
                action,
            }, //showRangeSelector
            calc: { selector: s => (getShowCalculatorFlag(s) ? 1 : 0), action }, //showCalculator
        },
    })
}
