import { createTransform, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/es/storage/session'
import { default as _reducer } from './reducer'

export * from './actions'

const checkoutTransform = createTransform(
    null,
    startDate => new Date(startDate),
    { whitelist: ['startDate'] }
)
const persistConfig = {
    key: 'checkout',
    storage: storageSession,
    blacklist: ['ordered', 'isReservationPending'],
    transforms: [checkoutTransform],
}
export const reducer = { checkout: persistReducer(persistConfig, _reducer) }
export { default as epic } from './epic'
export {
    getOrderedFlag,
    getStartDate,
    getDiscount,
    isPayByCard,
    getInsuranceValue,
    getLastReservation,
    getIsReservationPending,
    totalPricePerMonth,
} from './selectors'

export { default as actions } from './actions'
