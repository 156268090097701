import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { initApp, isTasksPending } from '../redux/modules/coreDuck'
import { getBoxFinderReadyFlag } from '../redux/modules/productDuck'
import '../styles/index.css'
import Head from './Head'
import Loader from './loader'
import RouterContainer from './RouterContainer'
import withWrappers from './withWrappers'
import { setLoggedUser, loginUser } from '../redux/modules/auth'
import { getCookie } from './utils'
// import authorizeUser from '../services/placebLogic/authorizeUser'
import { submit, initialize } from 'redux-form'

class App extends React.Component {
    static propTypes = {
        translations: PropTypes.object,
        pendingTasks: PropTypes.bool.isRequired,
        initApp: PropTypes.func.isRequired,
        boxFinderReady: PropTypes.bool.isRequired,
    }

    async componentDidMount() {
        this.props.initApp()
        const authToken = getCookie('authToken')
        let token

        if (authToken) {
            token = {
                access_token: authToken,
                refresh_token: getCookie('refreshToken'),
            }
            global.localStorage.setItem('token', JSON.stringify(token))
            await this.props.loginUser({ token })
            return
        }

        token = global.localStorage.getItem('token')
        if (token) {
            await this.props.loginUser({ token: JSON.parse(token) })
        }
    }

    render() {
        const { translations, pendingTasks, boxFinderReady } = this.props
        const ready = translations && boxFinderReady

        return (
            <React.Fragment>
                <Head />
                {ready && <RouterContainer />}
                {(!ready || pendingTasks) && (
                    <Loader showBackdrop={pendingTasks} />
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    translations: state.i18n.translations,
    pendingTasks: isTasksPending(state),
    boxFinderReady: getBoxFinderReadyFlag(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators({ initApp, loginUser, setUser: setLoggedUser }, dispatch)

export default compose(
    withWrappers,
    connect(mapStateToProps, mapDispatchToProps)
)(App)
