import React from 'react'

const defaultValue = {
    locale: 'en',
}
const Context = React.createContext(defaultValue)

export const withLocale = Component => props =>
    (
        <Context.Consumer>
            {({ locale }) => <Component {...props} locale={locale} />}
        </Context.Consumer>
    )

export default Context
