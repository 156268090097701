import React from 'react'
import styled from 'styled-components'
import {
    addressItemTitle,
    ListItem,
    SimpleLogo,
} from '../../components/commonComponents'
// import type { Position } from '../../../../types';

const Icon = styled(SimpleLogo)`
    margin: 0 12px 0 10px;
    height: 20px;
    width: 20px;
    visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`
// type Props = {
//   item: Position,
//   onClick: void => void,
// };
const AddressItem = ({ item, onClick } /*: Props*/) => (
    <ListItem onClick={onClick}>
        <Icon hide={item.isSuggestion} />
        {item.isSuggestion ? item.name : addressItemTitle`${item}`}
    </ListItem>
)
export default AddressItem
