import { ofType } from 'redux-observable'
import { delay, mergeMap } from 'rxjs/operators'
import {
    changeCategory,
    changeSizeType,
    getSelectedLocationObject,
    userChangedSizeType,
} from '../../../../redux/modules/productDuck'
import { chooseCategoryBySizeType } from '../../../../redux/modules/productDuck/helpers'
// import type { Stream } from '../../../../types';
// import type { StateObservable } from 'redux-observable';

export default (action$ /*: Stream*/, state$ /*: StateObservable*/) =>
    action$.pipe(
        ofType(userChangedSizeType.toString()),
        delay(0),
        mergeMap(({ payload: { sizeType } }) => {
            const selectedLocation = getSelectedLocationObject(state$.value)
            const category = chooseCategoryBySizeType(
                selectedLocation,
                sizeType
            )
            return [
                changeSizeType({ sizeType, deep: true }),
                changeCategory({ category }),
            ]
        })
    )
