import { getFormValues as _getFormValues } from 'redux-form'
import duck from './duck'
import moduleName from './moduleName'

export { default as moduleName } from './moduleName'
export { default } from './PaymentContainer'
export const getFormValues = _getFormValues(moduleName)
export const actions = duck.actions
export const selectors = duck.selectors
export const epic = duck.epic
