import * as R from 'ramda'
import { actionTypes } from 'redux-form'
import { combineEpics, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { map, mergeMap, mergeMapTo, switchMap, take } from 'rxjs/operators'
import {
    mapPinClicked,
    sizeChangedByCalculator,
    userChangedPosition,
} from '../../../../components/BoxFinder'
import {
    openBooking,
    paymentConfirmed,
} from '../../../../components/CheckoutProcess'
import * as checkoutDuck from '../../../../components/CheckoutProcess/checkoutDuck'
import * as Payment from '../../../../components/CheckoutProcess/Payment'
import { routes } from '../../../../constants'
import { routeChanged } from '../../coreDuck'
import {
    changeCategory,
    changeDuration,
    changeLocation,
    userChangedSize,
    userChangedSizeType,
    userSelectedLocationFromSlider,
} from '../../productDuck'
import actions from '../actions'

const routeToActionMap = {
    [routes.finder]: actions.boxFinderLoaded,
    [routes.booking]: actions.bookingPageLoaded,
    [routes.payment]: actions.paymentPageLoaded,
    [routes.userDetails]: actions.userDetailsPageLoaded,
    [routes.bookingSuccess]: actions.orderCompleted,
}
const createMapToEpic = (type, result) => action$ =>
    action$.pipe(ofType(type.toString()), mergeMapTo([result]))
const makePChangedEpic = (reason1, reason2, method) => action$ =>
    action$.pipe(
        ofType(reason1.toString()),
        switchMap(() =>
            action$.pipe(
                ofType(reason2.toString()),
                take(1),
                mergeMapTo([actions.productChanged({ method })])
            )
        )
    )

const routeChangedEpic = action$ =>
    action$.pipe(
        ofType(routeChanged.toString()),
        switchMap(({ payload: { route } }) => {
            const stream = [actions.pageLoaded({ route })]
            if (route === null) {
                return stream
            }
            const relatedAction = routeToActionMap[route]
            if (relatedAction) {
                stream.push(relatedAction())
            }
            return stream
        })
    )

const formFieldInputChangeEpic = action$ =>
    action$.pipe(
        ofType(actionTypes.CHANGE),
        map(({ payload, meta: { form, field } }) =>
            actions.formFieldInputChange({ form, field, value: payload })
        )
    )
const formFieldInputErrorEpic = action$ =>
    Rx.merge(
        action$.pipe(
            ofType(actionTypes.STOP_SUBMIT),
            map(({ payload, meta: { form } }) =>
                R.mapObjIndexed(
                    (value, field) =>
                        actions.formFieldInputError({ form, field, value }),
                    payload
                )
            ),
            mergeMap(R.values)
        ),
        action$.pipe(
            ofType(checkoutDuck.actions.creditCardError.toString()),
            map(({ payload }) =>
                actions.formFieldInputError({
                    form: Payment.moduleName,
                    field: 'creditCard',
                    value: payload,
                })
            )
        )
    )

export default combineEpics(
    routeChangedEpic,
    makePChangedEpic(
        userChangedPosition,
        changeLocation,
        'search_result_click'
    ),
    makePChangedEpic(mapPinClicked, changeLocation, 'map_pin'),
    makePChangedEpic(userChangedSize, changeCategory, 'size_select'),
    makePChangedEpic(userChangedSizeType, changeCategory, 'size_select'),
    makePChangedEpic(
        sizeChangedByCalculator,
        changeCategory,
        'size_consultant'
    ),
    makePChangedEpic(
        userSelectedLocationFromSlider,
        changeLocation,
        'location_slider'
    ),
    createMapToEpic(
        changeDuration,
        actions.productChanged({ method: 'duration' })
    ),
    createMapToEpic(paymentConfirmed, actions.confirmPayment()),
    createMapToEpic(openBooking, actions.boxSelected()),
    formFieldInputChangeEpic,
    formFieldInputErrorEpic
)
