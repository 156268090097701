import HelpIcon from '@material-ui/icons/Help'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'

const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const Hint = styled.div`
    display: flex;
    font-size: 16px;
    color: #70c005;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
    align-items: center;
`

// type Props = {
//   onClick: void => void,
// };
const HelpIconStyle = {
    color: '#70C005',
    height: 18,
    width: 16,
    marginRight: 3,
}

const CalculatorButton = ({ onClick } /*: Props*/) => (
    <Hint onClick={onClick}>
        <HelpIcon style={HelpIconStyle} />
        {t('sizeCalculator')}
    </Hint>
)

export default CalculatorButton
