import geolib from 'geolib'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import BoxSizeTypes from '../../../constants/BoxSizeTypes'
import { globalizeSelectors } from '../../../services'
import { getLocationById, getLocations } from '../locationsDuck'
import {
    findClosestLocation,
    sortArrayBox,
    sortSimpleArrayBox,
} from './helpers'
import moduleName from './moduleName'

const globalize = globalizeSelectors(R.prop(moduleName))
const _getBoxFinderReadyFlag = R.prop('boxFinderReady')
const _getIsValueChanged = R.prop('isValueChanged')
const _getIsLocationPreselected = R.prop('isLocationPreselected')
const _getSelectedCategory = R.prop('selectedCategory')
const _getSelectedCategoryType = R.prop('selectedCategoryType')
const _getSelectedDuration = R.prop('selectedDuration')
const _getSelectedLocation = R.prop('selectedLocation')
export const getSelectedCategoryType = globalize(_getSelectedCategoryType)
const _getSelectedCategoryTypeObject = createSelector(
    [_getSelectedCategoryType],
    type => BoxSizeTypes[type ? type.toLowerCase() : 0]
)
export const getSelectedLocation = globalize(_getSelectedLocation)
export const getSelectedCategoryTypeObject = globalize(
    _getSelectedCategoryTypeObject
)
export const getSelectedCategory = globalize(_getSelectedCategory)
export const getSelectedDuration = globalize(_getSelectedDuration)
export const getBoxFinderReadyFlag = globalize(_getBoxFinderReadyFlag)

export const getIsValueChanged = globalize(_getIsValueChanged)
export const getIsLocationPreselected = globalize(_getIsLocationPreselected)
export const getSelectedLocationObject = state => {
    const location = getSelectedLocation(state)
    return getLocationById(state, location)
}

export const getSelectedCategoryObject = createSelector(
    [getSelectedLocationObject, getSelectedCategory],
    (selectedLocation, selectedCategory) =>
        (selectedLocation &&
            selectedLocation.categories.find(
                item => item.title === selectedCategory
            )) ||
        null
)

export const getAvailableCategories = createSelector(
    [getSelectedLocationObject],
    selectedLocation =>
        selectedLocation && selectedLocation.categories.map(item => item.title)
)

export const getCategories = createSelector(
    [getSelectedLocationObject, getSelectedCategoryTypeObject],
    (selectedLocation, selectedCategoryType) =>
        selectedLocation &&
        selectedCategoryType &&
        selectedCategoryType.options.filter(item =>
            selectedLocation.usedCategories.includes(item)
        )
)

export const getDurations = createSelector(
    [getSelectedCategoryObject],
    categoryObject => categoryObject && categoryObject.durations
)

export const getSelectedDurationObject = createSelector(
    [getSelectedCategoryObject, getSelectedDuration],
    (categoryObject, selectedDuration) =>
        (categoryObject &&
            categoryObject.durations &&
            categoryObject.durations[selectedDuration]) ||
        null
)

export const getPreopenedFlag = state => {
    const selectedLocation = getSelectedLocationObject(state)
    return (selectedLocation && selectedLocation.preOpened) || false
}

export const getPrices = createSelector(
    [getLocations, getSelectedCategory, getSelectedDuration],
    (locations, selectedCategory, selectedDuration) => {
        const prices = locations
            .map(location => {
                const category = location.categories.find(
                    _category => _category.title === selectedCategory
                )
                if (!category) {
                    return null
                }
                const durationObject = category.durations[selectedDuration]
                if (!durationObject) {
                    return null
                }
                const { price, unit } = durationObject
                const displayPrice = unit === 'week' ? price / 4 : price
                return { id: location.id, price: displayPrice }
            })
            .filter(i => i)
        return prices.reduce(
            (accum, next) => ({ ...accum, [next.id]: next.price }),
            {}
        )
    }
)

export const getNearestLocation = createSelector(
    [getLocations, getSelectedLocationObject, getSelectedCategory],
    (locations, selectedLocation, selectedCategory) => {
        if (!locations.length) {
            return null
        }
        const availableLocations = locations.filter(item => {
            return item.categories.find(i => i.title === selectedCategory)
        })
        const location = findClosestLocation(
            selectedLocation,
            availableLocations
        )
        return location || null
    }
)

export const getCheapestLocation = createSelector(
    [
        getLocations,
        getSelectedLocationObject,
        getSelectedCategory,
        getSelectedDuration,
    ],
    (locations, selectedLocation, selectedCategory, selectedDuration) => {
        if (!locations.length) {
            return
        }
        const availableLocations = locations.filter(item => {
            return item.categories.find(i => i.title === selectedCategory)
        })
        const sortedAvailableLocations = availableLocations.sort(
            (prev, next) =>
                geolib.getDistanceSimple(selectedLocation, prev) -
                geolib.getDistanceSimple(selectedLocation, next)
        )
        const lowestPrices = sortedAvailableLocations.map(location => {
            return location.categories.filter(
                category => category.title === selectedCategory
            )
        })
        const prices = lowestPrices
            .map(i => {
                const duration = i[0].durations[selectedDuration]
                return duration ? duration.price : null
            })
            .filter(_ => _)
        const minPrice = Math.min.apply(null, prices)
        const location = sortedAvailableLocations.find(_location => {
            return _location.categories.find(
                category =>
                    category.durations[selectedDuration]?.price === minPrice
            )
        })
        return location || null
    }
)

export const getNearSizeAvailableCategory = createSelector(
    [getSelectedLocationObject, getSelectedCategory, (_, type) => type],
    (selectedLocation, selectedCategory, type) => {
        if (!selectedCategory) {
            return null
        }
        const sortedCategories = sortArrayBox(selectedLocation.categories)
        const unsortedCategoriesArray = sortedCategories.map(cat => cat.title)
        const categoriesArray = sortSimpleArrayBox(unsortedCategoriesArray)
        const sortedCategoriesArray = sortSimpleArrayBox(
            unsortedCategoriesArray
        )
        sortedCategoriesArray.push(selectedCategory)
        const categoriesArrayAddedItem = sortSimpleArrayBox(
            sortedCategoriesArray
        )
        const index = categoriesArrayAddedItem.indexOf(selectedCategory)

        if (type === 'min' && index !== 0) {
            return categoriesArray[index - 1]
        } else if (
            type === 'max' &&
            index !== categoriesArrayAddedItem.length - 1
        ) {
            return categoriesArray[index]
        } else {
            return null
        }
    }
)
