import React from 'react'
// import PropTypes from 'prop-types';
import styled from 'styled-components'
import Timer from '@material-ui/icons/QueryBuilder'
import InformationItem from './InformationItem'
import { I18n } from 'react-redux-i18n-lite'

const tPrefix = 'checkoutProcess.booking'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const TimerIcon = styled(Timer)`
    color: #80c700 !important;
    height: 24px;
    width: 24px;
`

const InformationItemWrapper = styled(InformationItem)`
    margin-bottom: 9px;
`

class BoxDuration extends React.Component {
    render() {
        const { duration, className } = this.props

        const durationUnit =
            duration.duration > 1
                ? t(`durationsMultiple.${duration.unit}`)
                : t(`durations.${duration.unit}`)
        return (
            <InformationItemWrapper
                className={className}
                icon={<TimerIcon />}
                text={duration.duration + ' ' + durationUnit}
                title={t('duration')}
            />
        )
    }
}

// InformationTitle.propTypes = {};
export default BoxDuration
