import { ofType } from 'redux-observable'
import { combineLatest } from 'rxjs'
import { mergeMap, takeUntil } from 'rxjs/operators'
import {
    getLocations,
    locationsLoaded,
} from '../../../../redux/modules/locationsDuck'
// import type { StateObservable } from 'redux-observable';
import {
    changeLocation,
    getIsLocationPreselected,
    presetValues,
} from '../../../../redux/modules/productDuck'
import {
    findAvailableCategory,
    findClosestLocation,
} from '../../../../redux/modules/productDuck/helpers'
import { geolocationLoaded } from '../../../../redux/modules/userGeolocation'
import { changePosition, userChangedPosition } from '../../LocationSelector'
// import type { Stream } from '../../../../types';

export default (action$ /*: Stream*/, state$ /*: StateObservable*/) =>
    combineLatest(
        action$.pipe(ofType(geolocationLoaded.toString())),
        action$.pipe(ofType(locationsLoaded.toString())),
        action$.pipe(ofType(presetValues.toString()))
    ).pipe(
        mergeMap(
            ([
                {
                    payload: { geolocation },
                },
            ]) => {
                const locations = getLocations(state$.value)
                if (!locations.length || !geolocation) {
                    return []
                }
                const availableLocations = locations.filter(item =>
                    findAvailableCategory(item)
                )
                const locationsToFind = availableLocations.length
                    ? availableLocations
                    : locations
                const location = findClosestLocation(
                    geolocation,
                    locationsToFind
                )
                const stream = [
                    changePosition({
                        position: geolocation,
                        setLocation: false,
                    }),
                ]
                const isLocationPreselected = getIsLocationPreselected(
                    state$.value
                )
                !isLocationPreselected &&
                    stream.push(changeLocation({ location, deep: true }))
                return stream
            }
        ),
        takeUntil(action$.pipe(ofType(userChangedPosition.toString())))
    )
