import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import { Row } from '../components/commonComponents'
// import type { SizeType } from '../../../types';
const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const BoldText = styled.span`
    font-weight: 600;
    text-transform: capitalize;
    color: #39393a;
`
const ThinText = styled.span`
    color: #80c700;
    font-size: 18px;
    margin-left: 3px;
`

const SizeDescriptionWrapper = styled(Row)`
    font-size: 16px;
    margin: 18px;
    flex-grow: 0;
    flex: none;
`
// type Porps = {
//     sizeType: SizeType
//     children: Array<React.Node> | React.Node
// }
const SizeDescription = ({ sizeType, children } /*: Porps*/) => (
    <SizeDescriptionWrapper>
        <ThinText>
            {t('size')} <BoldText>{sizeType.type}</BoldText>
        </ThinText>
        {children}
    </SizeDescriptionWrapper>
)

export default SizeDescription
