import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import actions from '../actions'

export default action$ =>
    action$.pipe(
        ofType(actions.pushEvent.toString()),
        tap(({ payload: { dataLayerObject } }) =>
            window.dataLayer.push(dataLayerObject)
        ),
        ignoreElements()
    )
