import React from 'react'
import styled from 'styled-components'
import { FixedContainer } from '../../components/commonComponents'
import LocationSelectorScreen from './LocationSelectorScreen'
// import type { PlacebLocation, Suggestion, Position } from '../../../../types';

const Root = styled(FixedContainer).attrs({ tabIndex: 0 })`
    outline: none;
`
const iosDevice =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
// type Props = {
//   locations: Array<PlacebLocation>,
//   onChange: Position => void,
//   onClose: void => void,
//   onInputChange: string => void,
//   suggestions: Array<Suggestion>,
//   userGeolocastion: Position,
//   className?: string,
// };
// type State = { inputValue: string };

class LocationSelector extends React.Component /*<Props, State>*/ {
    state = { inputValue: '' }
    root = React.createRef()
    onChangeInputValue = (value /*: string*/) => {
        this.setState(() => ({ inputValue: value }))
        this.props.onInputChange(value)
        if (!iosDevice) {
            const scrolledBlock =
                document.getElementsByClassName('scrollable-block')[0]
            if (scrolledBlock.hasChildNodes()) {
                scrolledBlock.children[0].scrollIntoView(true)
            }
        }
    }
    onChange = (value /*: Position*/) => {
        const { onChange, onClose } = this.props
        onChange(value)
        onClose()
    }
    onBlur = (e /*: Event*/) => {
        if (this.root.current && this.root.current.matches(':hover')) {
            return
        }
        if (!e.relatedTarget) {
            this.props.onClose()
        }
    }
    render() {
        const { locations, className, suggestions, userGeolocation, onClose } =
            this.props
        const { inputValue } = this.state
        return (
            <Root
                className={className}
                innerRef={this.root}
                onBlur={this.onBlur}
            >
                <LocationSelectorScreen
                    inputValue={inputValue}
                    locations={locations}
                    onChange={this.onChange}
                    onClose={onClose}
                    onInputChange={this.onChangeInputValue}
                    suggestions={suggestions}
                    userGeolocation={userGeolocation}
                />
            </Root>
        )
    }
}

export default LocationSelector
