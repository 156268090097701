import {
    dimensions,
    durations,
    durationsMultiple,
    sizeTypes,
} from '../../../../translations/deCommon'

export default {
    header: 'Schritt 1: Buchung',
    boxInformation: 'Box Informationen',
    size: 'Grösse',
    sizes: {
        extraSmall: 'Extra small',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        extraLarge: 'Extra large',
    },
    sizeTypes,
    dimensions,
    durations,
    durationsMultiple,
    location: 'Standort',
    duration: 'Mindestmietdauer',
    startDate: 'Startdatum auswählen',
    requiredFieldError: 'Dieses Feld ist Pflicht',
    minimumTerm: 'Mindestdauer',
    insurance: 'Versicherung (obligatorisch)',
    insuranceDescription: 'Die Kosten sind monatlich CHF 6 pro CHF 10’000 Warenwert',
    confirmInsurance: 'Ja, ich möchte eine Versicherung abschliessen (empfohlen)',
    boxValue: 'Wert deiner Waren (CHF)',
    disableInsuranceStrong: 'Nein, ich brauche keine Versicherung und bin mir bewusst, dass ich meiner Versicherung melden muss, dass meine Sachen an einem Drittstandort gelagert sind.',
    disableInsurance: ' Ich werde im Schadensfall keine Ansprüche gegenüber placeB erheben.',
    nextStep: 'Nächster Schritt',
    ok: 'OK',
    cancel: 'Abbrechen',
    insurancePartner: 'Dein Versicherungspartner',
    per: 'pro',
    noticePeriod: 'Kündigungsfristen',
    noticePeriodDescription:
        'Ohne Kündigung verlängert sich der Vertrag automatisch um %{period}. Du kannst aber auch flexibel vorzeitig kündigen.',
    insuranceInformation: 'Versicherung',
    insuranceInformationDescription:
        'Eine Versicherung gegen Diebstahl, Feuer und Wasserschäden ist obligatorisch.',
    moreInformation: 'Mehr Infos',
    incorrectValue: 'Ungültige Wert',
    modalTitle1: 'Kündigungsfristen',
    modalText1: `Es gibt keine Kündigungsfristen. Ohne Kündigung verlängert sich der Vertrag einfach immer um jeweils einen Monat (bzw. eine Woche falls du einen Wochenvertrag ausgewählt hast). Es ist aber kein Problem, wenn du die Lagerbox vorher nicht mehr brauchst. In diesem Fall wird, wenn nötig, der Rabatt rückwirkend korrigiert und du kannst uns jederzeit innert Wochenfrist verlassen. Schreibe uns dazu einfach eine E-Mail an info@placeb.ch.
    \n \t Achtung: wenn du am letzten Tag kündigst, stelle sicher, dass dein Raum leer ist, da du danach keinen Zugriff mehr auf die placeB Box hast.
    \n \t Dein Vertrag kannst du ganz einfach in der placeB App künden:
    \r \t - logge dich in der App ein
    \r \t - klicke auf “Meine Boxen”
    \r \t - Box auswählen
    \r \t - klicke auf den Menü Button (drei Punkte in der rechten oberen Ecke) und dann auf Vertragsdetails. Dort findest du ganz unten einen grünen Button mit der Aufschrift “Kündigen”.
    \n \t Fertig!`,
    modalTitle2: 'Sind meine Sachen versichert?',
    modalText2: `Nicht automatisch. Eine Versicherung gegen Diebstahl, Feuer und Wasserschäden ist aber obligatorisch. Du hast zwei Möglichkeiten:
    \n \t Entweder du kontaktierst deine Hausratversicherung oder du versicherst die eingelagerten Gegenstände bei uns.
    \n \t Ein Kreuzchen beim Vertrag genügt. Die Kosten sind mit monatlich 6 Franken pro 10’000 Franken Warenwert eher günstiger als bei einer eigenen Hausratversicherung.`,
}
