import {
    getSelectedCategory,
    getSelectedCategoryType,
    getSelectedDurationObject,
    getSelectedLocationObject,
} from '../../productDuck'
import getReservationPrice from './getReservationPrice'

export default state => {
    const category = getSelectedCategory(state)
    const selectedLocation = getSelectedLocationObject(state)
    const selectedDurationObject = getSelectedDurationObject(state)
    const { quantity, price, priceTotal, monthQuantity } =
        getReservationPrice(state)
    const sizeType = getSelectedCategoryType(state)
    return {
        location: selectedLocation,
        category,
        duration: selectedDurationObject,
        quantity,
        price,
        priceTotal,
        monthQuantity,
        sizeType,
    }
}
