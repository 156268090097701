import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Information = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    padding-left: 19px;
    margin-bottom: 25px;
    align-items: top;
`
const InformationDescription = styled.div`
    display: flex;
    flex-direction: column;
`
const ItemHeader = styled.h3`
    height: 19px;
    color: #000000;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin: 0 0 4px 0;
`
const ItemText = styled.div`
    color: #000000;
    font-family: Helvetica;
    font-size: 16px;
    margin: 0;
    line-height: 24px;
`
const Icon = styled.div`
    color: #80c700 !important;
    padding-right: 22px;
`

class InformationItem extends React.Component {
    render() {
        const { title, text, icon, className } = this.props
        return (
            <Information className={className}>
                <Icon>{icon}</Icon>
                <InformationDescription>
                    <ItemHeader>{title}</ItemHeader>
                    <ItemText>{text}</ItemText>
                </InformationDescription>
            </Information>
        )
    }
}

InformationItem.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    icon: PropTypes.element.isRequired,
}
export default InformationItem
