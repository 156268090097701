import { FormErrors } from 'redux-form'

export const scrollToFirstError = (errors?: FormErrors) => {
    if (!errors) {
        return
    }

    // Look for input field with matching name
    const firstErrorFieldName = Object.keys(errors)[0]
    let element = document.querySelector(`[name="${firstErrorFieldName}"]`)

    // Otherwise look for element with ID matching error field name
    if (!element) {
        element = document.getElementById(firstErrorFieldName)
    }

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
    }
}

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts?.pop()?.split(';').shift()
    }
}
