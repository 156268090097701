import React from 'react'
import styled from 'styled-components'
import { desktopWidth } from '../../../config'
import { FixedContainer, SelectorList } from '../components/commonComponents'
import Header from './components/Header'
import RadiusItem from './components/RadiusItem'

// type Props = {
//   options: ?number,
//   onChange: (number | string) => void,
//   onClose: void => void,
//   value: string | number,
//   className: string,
// };
const Root = styled(FixedContainer)`
    @media (min-width: ${desktopWidth}px) {
        border-top: 0;
        top: calc(100% - 1px);
        height: 300px;
    }
`

class RadiusSelector extends React.Component /*<Props>*/ {
    renderItem = item => (
        <RadiusItem
            key={item}
            active={this.props.value === item}
            item={item}
            onClick={() => this.onChange(item)}
        />
    )
    onChange = value => {
        const { onChange, onClose } = this.props
        onChange(value)
        onClose()
    }
    render() {
        const { options, className, onClose } = this.props
        const locationList = options.map(this.renderItem)
        return (
            <Root
                className={className}
                innerRef={ref => ref && ref.focus()}
                onBlur={onClose}
                tabIndex={0}
            >
                <Header onCrossButtonClick={onClose} />
                <SelectorList>{locationList}</SelectorList>
            </Root>
        )
    }
}

export default RadiusSelector
