import * as React from 'react'
import styled from 'styled-components'
import { createTranslator } from '../../../services'
import { Logo } from '../components/commonComponents'
import MapButton from './components/MapButton'

const t = createTranslator('boxFinder')

const HeaderWrapper = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
`

const LocationLabel = styled.div`
    flex: 1;
    font-size: 18px;
    color: #80c700;
    margin-left: 5px;

    @media screen and (max-width: 880px) {
        display: none;
    }
`

// type Props = {
//   openMap: void => void,
//   children: React.Node,
// };
const goToHomeScreen = () => (window.location = '/')
const Header = ({ openMap, children } /*: Props*/) => (
    <HeaderWrapper>
        <LocationLabel>{t('location')}</LocationLabel>
        {children}
        <MapButton onClick={openMap} />
        <Logo onClick={goToHomeScreen} />
    </HeaderWrapper>
)

export default Header
