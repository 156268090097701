import { ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import {
    catchError,
    exhaustMap,
    ignoreElements,
    map,
    mergeMap,
    mergeMapTo,
    tap,
} from 'rxjs/operators'
import {
    getLoggedUserProfile,
    loginError,
    loginSuccess,
    loginUser,
} from '../../../../redux/modules/auth'
import { firstOfType } from '../../../../services/rxOperators'
import * as Payment from '../../Payment'
import { getProfileFormValues } from '../../UserDetails/ProfileForm'
import actions, {
    createReservation,
    openBookingSuccess,
    registerUser,
    registrationFailed,
    registrationSuccess,
    reservationFailed,
    reservationSuccess,
    setLastReservation,
    updateUser,
    updateUserError,
    updateUserSuccess,
} from '../actions'

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.startReservation.toString()),
        exhaustMap(({ payload: password }) => {
            const state = state$.value

            const userInformation = getProfileFormValues(state)
            const { email } = userInformation
            const loggedUser = getLoggedUserProfile(state)

            const { creditCard, invoice, invoice3rdParty, couponCode } =
                Payment.getFormValues(state)

            const createReservation$ = Rx.concat(
                Rx.of(
                    createReservation({
                        card: !invoice && !invoice3rdParty && creditCard,
                        invoice3rdParty: invoice3rdParty,
                        couponCode: couponCode,
                    })
                ),
                Rx.race(
                    action$.pipe(
                        firstOfType(reservationSuccess),
                        mergeMap(({ payload: { reservation } }) =>
                            Rx.merge(
                                Rx.of(setLastReservation(reservation)),
                                Rx.timer(10).pipe(
                                    tap(openBookingSuccess),
                                    ignoreElements()
                                )
                            )
                        )
                    ),
                    action$.pipe(
                        firstOfType(reservationFailed),
                        ignoreElements()
                    )
                )
            )
            const loginUser$ = Rx.concat(
                Rx.of(loginUser({ email, password })),
                Rx.race(
                    action$.pipe(
                        firstOfType(loginSuccess),
                        mergeMapTo(createReservation$)
                    ),
                    action$.pipe(
                        firstOfType(loginError),
                        map(({ payload: { error } }) =>
                            reservationFailed({ error })
                        )
                    )
                )
            )
            const register$ = Rx.concat(
                Rx.of(
                    registerUser({
                        user: { ...userInformation, plainPassword: password },
                    })
                ),
                Rx.race(
                    action$.pipe(
                        firstOfType(registrationSuccess),
                        mergeMapTo(loginUser$)
                    ),
                    action$.pipe(
                        firstOfType(registrationFailed),
                        map(({ payload: { error } }) =>
                            reservationFailed({ error })
                        )
                    )
                )
            )
            const updateUser$ = Rx.concat(
                Rx.of(updateUser()),
                Rx.race(
                    action$.pipe(
                        firstOfType(updateUserSuccess),
                        mergeMapTo(createReservation$)
                    ),
                    action$.pipe(
                        firstOfType(updateUserError),
                        map(({ payload: { error } }) =>
                            reservationFailed({ error })
                        )
                    )
                )
            )
            return (loggedUser ? updateUser$ : register$).pipe(
                catchError(error => reservationFailed({ error }))
            )
        })
    )
