import geolib from 'geolib'
import { ofType } from 'redux-observable'
// import type { StateObservable } from 'redux-observable';
import { mergeMap } from 'rxjs/operators'
import RANGES from '../../../../constants/Ranges'
import { getLocations } from '../../../../redux/modules/locationsDuck'
import {
    changeLocation,
    getSelectedCategory,
    getSelectedLocationObject,
} from '../../../../redux/modules/productDuck'
import { getSelectedPosition } from '../../LocationSelector'
// import type { Stream } from '../../../../types';
import { radiusChangedByUser } from '../../RadiusSelector'

export default (action$ /*: Stream*/, state$ /*: StateObservable*/) =>
    action$.pipe(
        ofType(radiusChangedByUser.toString()),
        mergeMap(({ payload: { radius } }) => {
            const stream = []
            const locations = getLocations(state$.value)
            const selectedCategory = getSelectedCategory(state$.value)
            const selectedLocation = getSelectedLocationObject(state$.value)
            const selectedPosition = getSelectedPosition(state$.value)
            const filteredLocations =
                radius === RANGES.ALL_SWITZERLAND
                    ? locations
                    : locations.filter(
                          item =>
                              geolib.getDistanceSimple(selectedPosition, item) <
                              radius
                      )
            const locationsWithSameCategory = filteredLocations.filter(item =>
                item.categories.find(cat => cat.title === selectedCategory)
            )
            if (locationsWithSameCategory.includes(selectedLocation)) {
                return stream
            }
            const locationWithSameCategory = locationsWithSameCategory.pop()
            if (locationWithSameCategory) {
                stream.push(
                    changeLocation({
                        location: locationWithSameCategory,
                        deep: true,
                    })
                )
            }
            return stream
        })
    )
