import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import sha256 from 'sha256'
import { getProfileFormValues } from '../../../../components/CheckoutProcess'
import { getLoggedUserProfile } from '../../auth'
import { getLanguage } from '../../coreDuck'
import actions from '../actions'
import { GtmEvent } from '../constants'

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.pageLoaded.toString()),
        mergeMap(({ payload: { route } }) => {
            const userProfile =
                getProfileFormValues(state$.value) ||
                getLoggedUserProfile(state$.value) ||
                {}
            const contentLanguage = getLanguage(state$.value)
            const serverStatusCode = route === null ? '404' : '200'
            const userEmailHash = userProfile.email
                ? sha256.x2(userProfile.email)
                : ''
            return [
                actions.pushEvent({
                    dataLayerObject: {
                        event: GtmEvent.GEALL,
                        contentLanguage,
                        serverStatusCode,
                        userEmail: userProfile.email || '',
                        userEmailHash,
                        userId: userProfile.id ? userProfile.id + '' : '',
                        userZip: userProfile.zip || '',
                    },
                }),
            ]
        })
    )
