export default {
    header: 'Step 2: Your details',
    fillDetails: 'Fill in details',
    loginButton: 'Log in using an existing account',
    firstName: 'First name',
    lastName: 'Last name',
    company: 'Company (optional)',
    country: 'Country',
    email: 'Email',
    address: 'Address',
    address2: 'Additional information (optional)',
    zip: 'Zip',
    city: 'City',
    phone: 'Mobile phone',
    phoneHelperText: 'Please enter a mobile phone number',
    nextStep: 'Next step',
    confirm: 'Next step',
    required: 'This field cannot be empty',
    invalidCountry: 'Invalid country',
    incorrectEmail: 'Incorrect email',
    passwordError: 'The password is too short',
    incorrectPhone: 'Invalid mobile phone number',
    incorrectZip: 'Incorrect zip',
    loginModal: {
        logIn: 'Log in',
        email: 'E-Mail',
        cancel: 'Cancel',
        password: 'Password',
        emailError: 'Incorrect email',
        passwordError: 'The password is too short',
        requiredField: 'This field cannot be empty',
    },
}
