import { combineEpics } from 'redux-observable'
import { extractFromArray, mergeTranslations } from '../services'
import * as BoxFinder from './BoxFinder'
import * as CheckoutProcess from './CheckoutProcess'

const components = [BoxFinder, CheckoutProcess]

export const reducer = Object.assign(
    {},
    ...extractFromArray(components, 'reducer')
)

export const epic = combineEpics(...extractFromArray(components, 'epic'))
export const translations = mergeTranslations(
    extractFromArray(components, 'translations')
)
