import * as R from 'ramda'
import { isNullOrUndefined } from 'util'
import { durationUnit } from '../constants'
import createTranslator from './createTranslator'

export function categoryConverter(category) {
    const { width, depth, height, deduction, durations, nextBoxDimensions } =
        category
    const square = nextBoxDimensions.summary.area
    const volume = nextBoxDimensions.summary.volume
    const sizeUnits = 'mm'
    const minPrice = Math.min.apply(
        null,
        durations.map(item => {
            const { price } = item
            switch (item.unit) {
                case durationUnit.YEAR:
                    return price
                case durationUnit.MONTH:
                    return price
                case durationUnit.WEEK:
                    return price * 4
                case durationUnit.DAY:
                    return price * 30
                default:
                    return price
            }
        })
    )
    return {
        ...category,
        square,
        volume,
        sizeUnits,
        minPrice,
        nextBoxDimensions,
    }
}

export function locationConverter(location) {
    const { categories, availableCategories } = location
    const newCategories = availableCategories.map(categoryConverter)
    return {
        ...location,
        categories: newCategories,
        usedCategories: categories,
        availableCategories: null,
        isPlaceB: true,
    }
}

export const errorParser = R.compose(
    a => a.join('\n'),
    R.map(item => item.join(': ')),
    R.toPairs,
    R.map(({ errors: [_] }) => _),
    R.pickBy(v => !R.isEmpty(v))
)

export async function getUserPosition() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position =>
                resolve(position.coords)
            )
        } else {
            reject('Geolocation unavailable')
        }
    })
}

export const roundCurrency = value => {
    const rounded = Math.round(value * 20) / 20
    if (value <= rounded) {
        return Math.floor(value * 20) / 20
    }
    return rounded
}

export function getRealPrice({ unit, price }) {
    const result =
        unit === 'day'
            ? price / 30
            : unit === 'week'
            ? roundCurrency(price / 4)
            : price
    return result
}

export function getRealPricePerMonth({ unit, price }) {
    const result =
        unit === 'day'
            ? price / 30
            : unit === 'week'
            ? roundCurrency(price / 4)
            : price
    return result
}

export const coordsToShort = input => {
    const { latitude, longitude, lat, lng } = input
    return {
        lat: lat || latitude,
        lng: lng || longitude,
    }
}

const t = createTranslator('boxFinder')

export const getPricingTexts = (
    price,
    durationUnit,
    durationDuration,
    category,
    preOpened,
    offsetPerMonth = 0,
    appliedDiscount = null
) => {
    const showedUnit = durationUnit === 'year' ? 'month' : durationUnit
    const unitDurationLabel = `CHF / ${t(`durations.${showedUnit}`)}`
    const vat = `${t(`inclVat`)}`

    let priceLabel,
        priceLabelAfter,
        priceLabelExtended,
        pricePriceExtended,
        priceLabelAfterExtended,
        pricePriceAfterExtended

    const priceAfter =
        (preOpened
            ? category.durations[1].price / 2
            : category.durations[1].price) + offsetPerMonth

    // No `after price` for weekly bookings, 1 month bookings or when the price does not increase after the minimal duration
    if (
        durationUnit === 'week' ||
        (durationUnit === 'month' && durationDuration === 1) ||
        priceAfter === price
    ) {
        priceLabel = `CHF ${price}`
        priceLabelExtended = `${t(`inclVat`)}`
        pricePriceExtended = `CHF ${price} ${t(`per`)} ${t(
            `durations.inSentence.${showedUnit}`
        )}`
    } else {
        const duration = durationUnit === 'year' ? 12 : durationDuration
        priceLabel = `${t(`priceDiscountPrefix`)} ${duration} ${t(
            `durationsMultiple.inSentence.month`
        )}: ${price}`
        priceLabelAfter = `${t(`priceRegularPrefix`)}: ${priceAfter}`

        priceLabelExtended = `${t(`priceDiscountPrefix`)} ${duration} ${t(
            `durationsMultiple.inSentence.month`
        )}:`
        pricePriceExtended = `CHF ${price} ${t(`per`)} ${t(
            `durations.inSentence.month`
        )}`

        priceLabelAfterExtended = `${t(`priceRegularPrefix`)}:`
        pricePriceAfterExtended = `CHF ${priceAfter} ${t(`per`)} ${t(
            `durations.inSentence.month`
        )}`
    }

    const priceHint = appliedDiscount ? `${t(`includeDiscount`)}` : null

    return {
        unitDurationLabel,
        vat,
        priceLabel,
        priceLabelAfter,
        priceLabelExtended,
        pricePriceExtended,
        priceLabelAfterExtended,
        pricePriceAfterExtended,
        priceHint,
    }
}
