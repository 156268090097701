import Arrow from '@material-ui/icons/KeyboardArrowLeft'
import React from 'react'
import styled from 'styled-components'
import PlaceBTheme from '../../../../styles/PlacebTheme'
import { addressItemTitle } from '../../components/commonComponents'
// import type { PlacebLocation } from '../../../../types';

const mainColor = PlaceBTheme.palette.primary.main
const IconWrapper = styled(Arrow).attrs({ color: 'action' })`
    display: flex;
    position: absolute;
    left: 20px;
    margin: auto;
    cursor: pointer;
    top: 10px;
`
const HeaderWrapper = styled.div`
    display: flex;
    background: ${mainColor};
    height: 45px;
    align-items: center;
    justify-content: center;
`
const HeaderTitle = styled.div`
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`
// type Props = {
//   location: PlacebLocation,
//   onBackButtonClick: void => void,
// };
const Header = ({ location, onBackButtonClick } /*: Props*/) => (
    <HeaderWrapper>
        <IconWrapper onClick={onBackButtonClick} />
        <HeaderTitle>{addressItemTitle`${location}`}</HeaderTitle>
    </HeaderWrapper>
)

export default Header
