import React from 'react'
import Head from './Head'
import Loader from './loader'

interface Props {
    children: React.ReactNode
}

interface State {
    error?: any
}

export default class ErrorBoundary extends React.Component<Props, State> {
    state: State = {}

    static getDerivedStateFromError(error: any): State {
        return { error }
    }

    componentDidCatch(error: any, info: any) {
        const { Sentry } = window as any

        if (Sentry) {
            Sentry.captureException(error)
            if (info) Sentry.captureMessage(info)

            const url = new URL(window.location.href)
            window.location.replace(url.pathname)
        }
    }

    render() {
        if (this.state.error) {
            return (
                <>
                    <Head />
                    <Loader />
                </>
            )
        }

        return this.props.children
    }
}
