import React from 'react'
import styled from 'styled-components'
import Timer from '@material-ui/icons/QueryBuilder'
import InformationItem from './InformationItem'
import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n-lite'

const tPrefix = 'checkoutProcess.booking'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const TimerIcon = styled(Timer)`
    color: #80c700 !important;
    height: 24px;
    width: 24px;
`
const BoxSize = ({ sizeParameters, className }) => {
    const { subType, nextBoxDimensions } = sizeParameters
    const sizeGeneral = `${t(
        `sizeTypes.${subType.toLowerCase()}`
    )}, ${nextBoxDimensions.summary.volume.toFixed(1)}m³`

    return (
        <InformationItem
            className={className}
            icon={<TimerIcon />}
            text={
                <React.Fragment>
                    <div>{sizeGeneral}</div>
                    {nextBoxDimensions.boxes.map((b, index) => (
                        <div key={index}>
                            {`${
                                nextBoxDimensions.boxes.length > 1
                                    ? 'Box ' + (index + 1) + ': '
                                    : ''
                            }${b.depth}m × ${b.width}m × ${b.height}m`}
                        </div>
                    ))}
                </React.Fragment>
            }
            title={t('size')}
        />
    )
}

BoxSize.propTypes = {
    className: PropTypes.string,
    sizeParameters: PropTypes.object.isRequired,
}

export default BoxSize
