import * as R from 'ramda'
import { connect } from 'react-redux'
import { reduxForm, SubmissionError } from 'redux-form'
import { scrollToFirstError } from '../../../../core/utils'
import { getLoggedUserProfile } from '../../../../redux/modules/auth'
import parseYupErrors from '../../../../services/parseYupErrors'
import rest from '../../../../services/rest'
import initialValues from './initialValues'
import moduleName from './moduleName'
import ProfileForm from './ProfileForm'
import validationSchema from './validationSchema'

const mapStateToProps = state => ({
    initialValues: getLoggedUserProfile(state) || initialValues,
})

const reduxConnector = connect(mapStateToProps)

const onSubmit = async values => {
    try {
        const cleaned = validationSchema.validateSync(values, {
            abortEarly: false,
        })

        const payload = {
            company: cleaned.company,
            firstname: cleaned.firstName,
            lastname: cleaned.lastName,
            street: cleaned.street,
            zip_code: cleaned.zip,
            city: cleaned.city,
            country_code: 'ch',
            amount: 300,
        }

        cleaned.rating = await rest.getUserRating(payload)
        return cleaned
    } catch (_errors) {
        const errors = parseYupErrors(_errors)
        if (!R.isEmpty(errors)) {
            throw new SubmissionError(errors)
        }
    }
}

const hasOnlyIncorrectPhoneProperty = errors =>
    Object.keys(errors).length === 1 && errors.phone === 'incorrectPhone'

const validate = values => {
    try {
        validationSchema.validateSync(values, {
            abortEarly: false,
        })

        return {}
    } catch (_errors) {
        const errors = {}

        if (Array.isArray(_errors.inner)) {
            _errors.inner.forEach(error => {
                errors[error.path] = error.message
            })
        }

        return hasOnlyIncorrectPhoneProperty(errors) ? {} : errors
    }
}

const formCreator = reduxForm({
    form: moduleName,
    onSubmit,
    validate,
    onSubmitFail: scrollToFirstError,
    destroyOnUnmount: false,
    enableReinitialize: false,
    keepDirtyOnReinitialize: false,
})

export default R.compose(reduxConnector, formCreator)(ProfileForm)
