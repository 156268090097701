import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import Header from '../components/Header/Header'

const tPrefix = 'checkoutProcess.userDetails'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const UserDetails = ({ onButtonClick }) => (
    <Header onButtonClick={onButtonClick} step={2} title={t('header')} />
)

UserDetails.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
}
export default UserDetails
