import * as R from 'ramda'
import { prop } from 'ramda'
import { createAction as createReduxAction, handleActions } from 'redux-actions'
import { ofType } from 'redux-observable'
import { mapTo } from 'rxjs/operators'
import { globalizeSelectors } from '../../../services'
import { showCheaperBoxDetails } from '../CheaperModal'

export const moduleName = 'cheaperBoxNotification'
const defaultValue = { visible: false }
const createAction = action => createReduxAction(`${moduleName}/${action}`)

export const showCheaperNotification = createAction('SHOW_NOTIFICATION')
export const hideCheaperNotification = createAction('HIDE_NOTIFICATION')

const globalize = globalizeSelectors(prop(moduleName))

export default handleActions(
    {
        [showCheaperNotification]: R.always({ visible: true }),
        [hideCheaperNotification]: R.always({ visible: false }),
    },
    defaultValue
)
export const epic = action$ =>
    action$.pipe(
        ofType(showCheaperBoxDetails.toString()),
        mapTo(hideCheaperNotification())
    )
const _isNotificationVisible = R.prop('visible')
export const isCheaperNotificationVisible = globalize(_isNotificationVisible)
