import axios from 'axios'
import httpCodes from 'http-status-codes'
import { clientId, clientSecret, endpoint } from '../../config'
import rest from '../rest'

const apiPath = 'api/v1'

const responseInterceptor = async ({ config, status, data }) => {
    if (status === httpCodes.UNAUTHORIZED && config.repeat > 0) {
        return axios({ ...config, repeat: config.repeat - 1 })
    }
    return data
}

const requestInterceptor = function (config) {
    const repeat = config.repeat >= 0 ? config.repeat : 1
    return { ...config, repeat }
}

export default async function ({ credentials, token }) {
    try {
        let usedToken
        if (!token) {
            usedToken = await rest.authorizeAsUser({
                clientId,
                clientSecret,
                credentials,
            })
            global.localStorage.setItem('token', JSON.stringify(usedToken))
        } else {
            usedToken = token
        }

        const userSession = axios.create({
            baseURL: `${endpoint}/${apiPath}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usedToken.access_token}`,
            },
        })

        userSession.interceptors.response.use(responseInterceptor)
        userSession.interceptors.request.use(requestInterceptor)
        global.userSession = userSession
        global.userSession.user = await rest.getCurrentUser()

        try {
            global.userSession.user.card = (
                await rest.getDefaultCard(global.userSession.user.id)
            ).card
        } catch (error) {
            console.info('default card not found', error)
        }

        return global.userSession.user
    } catch (error) {
        if (token) {
            global.localStorage.clear()
            global.sessionStorage.clear()
        }
        console.error("Can't authenticate user", error)
        throw error
    }
}
