import with2Digits from './with2Digits'

export default ({ value, quantity, variant }) => {
    const monthQuantity =
        quantity < 28 ? Math.ceil(quantity / 7) / 4 : Math.round(quantity / 30)
    const priceTotal = Math.ceil(value / 10000) * 6 * monthQuantity //TODO get from params
    const dimension15 = with2Digits(Math.ceil(quantity / 7))
    return {
        name: 'insurance',
        category: 'insurance',
        quantity,
        priceTotal: with2Digits(priceTotal),
        price: with2Digits(priceTotal / quantity),
        variant,
        dimension13: 'insurance',
        dimension15,
        dimension16: with2Digits(monthQuantity),
    }
}
