import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import addYears from 'date-fns/addYears'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { getStartDate } from '../../../../components/CheckoutProcess/index'
import { getSelectedDurationObject } from '../../productDuck'
import with2Digits from './with2Digits'

export default state => {
    const durationObject = getSelectedDurationObject(state)
    if (!durationObject) {
        return {}
    } //Critical error
    const { duration, price, unit } = durationObject
    const startDate = getStartDate(state)
    const endDate = !startDate
        ? null
        : { week: addWeeks, month: addMonths, year: addYears }[unit](
              startDate,
              duration
          )
    const multiplier = {
        week: 0.25,
        month: 1,
        year: 12,
    }[unit]
    const quantity = !startDate
        ? null
        : differenceInCalendarDays(endDate, startDate)
    const monthQuantity = duration * multiplier
    const priceTotal = !startDate ? null : with2Digits(price * monthQuantity)
    return {
        quantity,
        priceTotal,
        price: !startDate ? null : with2Digits(priceTotal / quantity),
        monthQuantity: with2Digits(monthQuantity),
    }
}
