import MyLocationIcon from '@material-ui/icons/MyLocation'
import React from 'react'
import styled from 'styled-components'
import ButtonContainer from './ButtonContainer'

const Root = styled(ButtonContainer)`
    width: 44px;
`
// type Props = {
//   className: string,
//   onClick: void => void,
// };
const ShowMyLocationButton = ({ className, onClick } /*: Props*/) => (
    <Root className={className} onClick={onClick}>
        <MyLocationIcon />
    </Root>
)

export default ShowMyLocationButton
