import axios from 'axios'
import Cookie from 'cookie'
import httpCodes from 'http-status-codes'
import { clientId, clientSecret, endpoint } from '../config'
import { STATUS } from '../constants/index'

const getUserTokenFromStorage = () => {
    const value = global.localStorage.getItem('token')
    if (typeof value === 'object') {
        return JSON.parse(value)
    }
}
// const saveUserTokenToStorage = token => {
//     const serialized = JSON.stringify(token)
//     global.localStorage.setItem('token', serialized)
// }
const getGuestSession = () => global.guestSession
export const getUserSession = () => global.userSession

const tokenPath = 'oauth/v2/token'
// const apiPath1 = 'api/v1'
const apiPath2 = 'api/v2'

const tokenUrl = `${endpoint}/${tokenPath}`

async function refreshUserToken() {
    const prevToken = await getUserTokenFromStorage()

    if (!prevToken) {
        return
    }

    const _endpoint =
        `${tokenUrl}` +
        '?grant_type=refresh_token' +
        `&client_id=${clientId}` +
        `&client_secret=${clientSecret}` +
        `&refresh_token=${prevToken.refresh_token}`

    return axios
        .get(_endpoint, { baseUrl: '' })
        .catch(({ response, request, message }) => {
            if (response) {
                console.log(response)
                if (response.status === httpCodes.BAD_REQUEST) {
                    throw STATUS.INVALID_CREDENTIALS
                }
            } else if (request) {
                console.log(request)
                throw STATUS.NETWORK_ERROR
            } else {
                console.error('Error', message)
            }
        })
}

axios.interceptors.response.use(
    async ({ config, status, data }) => {
        if (status === httpCodes.UNAUTHORIZED && config.repeat > 0) {
            await refreshUserToken()
            return axios({ ...config, repeat: config.repeat - 1 })
        }
        return data
    },
    error => Promise.reject(error)
)

axios.interceptors.request.use(
    function (config) {
        const repeat = config.repeat >= 0 ? config.repeat : 1
        return { ...config, repeat }
    }
    // async function({ response, request, config }) {}
)

const authorizeAsUser = ({
    credentials,
    clientId: _clientId,
    clientSecret: _clientSecret,
}) => {
    const body = {
        client_id: _clientId,
        client_secret: _clientSecret,
        grant_type: 'password',
        username: credentials.username,
        password: credentials.password,
    }
    return getGuestSession()
        .post(tokenUrl, body, { baseURL: '' })
        .catch(({ response, request, message }) => {
            if (response) {
                if (response.status === httpCodes.BAD_REQUEST) {
                    throw STATUS.INVALID_CREDENTIALS
                }
            } else if (request) {
                console.log(request)
                throw STATUS.NETWORK_ERROR
            } else {
                console.error('Error', message)
            }
        })
}
export const authorizeAsGuest = ({
    clientId: _clientId,
    clientSecret: _clientSecret,
    endpoint: _endpoint,
}) => {
    const grant_type = 'client_credentials'
    const _tokenPath = 'oauth/v2/token'
    const path =
        `${_tokenPath}` +
        `?grant_type=${grant_type}` +
        `&client_id=${_clientId}` +
        `&client_secret=${_clientSecret}`

    return axios
        .get(path, { baseURL: `${_endpoint}` })
        .catch(({ response, request, message }) => {
            if (response) {
                console.log(response)
                if (response.status === httpCodes.BAD_REQUEST) {
                    throw STATUS.UNAUTHORIZED
                }
            } else if (request) {
                console.log(request)
                throw STATUS.NETWORK_ERROR
            } else {
                console.error('Error', message)
            }
        })
}

const getLocations = () => {
    const cookies = Cookie.parse(document.cookie)
    const browserLanguage = cookies['language-code']
    const path = `locations?_locale=${browserLanguage}`
    return getGuestSession().get(path, { baseURL: `${endpoint}/${apiPath2}` })
}

const validateDiscount = payload => {
    const cookies = Cookie.parse(document.cookie)
    const browserLanguage = cookies['language-code']
    const path = `reservations/discount/verify?_locale=${browserLanguage}`
    return getGuestSession().post(path, payload, {
        baseURL: `${endpoint}/${apiPath2}`,
    })
}

const getCurrentUser = () => {
    const path = 'user'
    return getUserSession()
        .get(path)
        .then(({ user }) => user)
}

const createReservation = (payload, userId) => {
    const path = `users/${userId}/reservations`
    return getUserSession()
        .post(path, payload, { baseURL: `${endpoint}/${apiPath2}` })
        .catch(({ response }) => {
            if (response && response.status === httpCodes.BAD_REQUEST) {
                throw response.data.errors
            }
            throw response
        })
}

const getUserRating = payload => {
    return getGuestSession().post('/', payload, {
        baseURL: 'https://guard.placeb.ch',
    })
}

const createSetupIntent = (payload, userId) => {
    const path = `/users/${userId}/create-setup-intent`
    return getUserSession()
        .post(path, payload, { baseURL: `${endpoint}/${apiPath2}` })
        .catch(({ response }) => {
            if (response && response.status === httpCodes.BAD_REQUEST) {
                throw response.data.errors
            }
            throw response
        })
}

const getDefaultCard = userId => {
    const path = `/users/${userId}/cards/default`
    return getUserSession()
        .get(path)
        .catch(({ response }) => {
            if (response && response.status === httpCodes.BAD_REQUEST) {
                throw response.data.errors
            }
            throw response
        })
}

const updateDefaultCard = (payload, userId) => {
    const path = `/users/${userId}/cards/default`
    return getUserSession()
        .put(path, payload, { baseURL: `${endpoint}/${apiPath2}` })
        .catch(({ response }) => {
            if (response && response.status === httpCodes.BAD_REQUEST) {
                throw response.data.errors
            }
            throw response
        })
}

const getTermsOfUse = () => {
    const path = 'texts/terms'
    return axios.get(path).then(({ text }) => text)
}

const changePassword = (payload, userId) => {
    const path = `users/${userId}/password`
    return axios.put(path, payload).catch(({ response, request, message }) => {
        if (response) {
            console.log(response)
            if (response.status === httpCodes.BAD_REQUEST) {
                throw STATUS.INCORRECT_CURRENT_PASSWORD
            }
        } else if (request) {
            console.log(request)
            throw STATUS.NETWORK_ERROR
        } else {
            console.error('Error', message)
            throw new Error('error')
        }
    })
}

const resetPassword = email => {
    const path = `users/${email}/password`
    return axios.delete(path).catch(({ response, request }) => {
        if (response) {
            if (response.status === httpCodes.NOT_FOUND) {
                throw response.data.message
            }
        } else if (request) {
            console.log(request)
            throw STATUS.NETWORK_ERROR
        } else {
            throw request
        }
    })
}
const registerUser = payload => {
    const path = 'users'

    if (payload.rating) {
        delete payload.rating
    }

    return getGuestSession()
        .post(path, payload)
        .catch(({ response, request, message }) => {
            if (response) {
                console.log(response)
                if (response.status === httpCodes.BAD_REQUEST) {
                    throw response.data.errors
                }
            } else if (request) {
                console.log(request)
                throw STATUS.NETWORK_ERROR
            } else {
                console.error('Error', message)
                throw request
            }
        })
}
const updateUser = payload => {
    const session = getUserSession()
    const path = `users/${session.user.id}`

    if (payload.rating) {
        delete payload.rating
    }

    return session
        .patch(path, payload)
        .catch(({ request, message, response }) => {
            if (response) {
                console.log(response)
                if (response.status === httpCodes.BAD_REQUEST) {
                    throw response.data.errors
                }
            } else if (request) {
                console.log(request)
                throw STATUS.NETWORK_ERROR
            } else {
                console.error('Error', message)
                throw request
            }
        })
}
const createCard = tokenId => {
    const session = getUserSession()
    const path = `users/${session.user.id}/cards`
    const body = { card: tokenId }
    return session.post(path, body)
}

export default {
    authorizeAsGuest,
    authorizeAsUser,
    getLocations,
    getCurrentUser,
    createReservation,
    createSetupIntent,
    getTermsOfUse,
    changePassword,
    resetPassword,
    registerUser,
    updateUser,
    createCard,
    getUserRating,
    getDefaultCard,
    updateDefaultCard,
    validateDiscount,
}
