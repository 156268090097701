import { googleGeolocationKey } from '../config'

const geocodeUrl = query =>
    `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${googleGeolocationKey}&components=country:CH`

const load = url => {
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error()
            }
            return response.json().then(data => {
                return data
            })
        })
        .catch(err => {
            console.log(err)
        })
}

const recognizeGeocode = ({ results }) => {
    const recognized = results.find(item => {
        return item.address_components.find(
            _item =>
                _item.types.includes('country') && _item.short_name === 'CH'
        )
    })

    return recognized ? recognized.geometry.location : null
}
export const geocoder = query => {
    const url = geocodeUrl(query)
    return load(url)
        .then(recognizeGeocode)
        .then(item =>
            item
                ? {
                      latitude: item.lat,
                      longitude: item.lng,
                  }
                : null
        )
}
export const getSuggestions = query => {
    return new Promise(resolve => {
        new global.google.maps.places.AutocompleteService().getPlacePredictions(
            {
                input: query,
                componentRestrictions: { country: 'CH' },
            },
            result => resolve(result || [])
        )
    })
}

export const getCoordsDyId = id => {
    return new Promise((resolve, reject) => {
        new global.google.maps.Geocoder().geocode(
            { placeId: id },
            (results, status, error) => {
                if (!results) {
                    console.log(status + error)
                }
                if (results.length) {
                    resolve(results.pop())
                } else {
                    reject()
                }
            }
        )
    }).then(res => {
        const { location } = res.geometry
        const latitude = location.lat()
        const longitude = location.lng()
        return { latitude, longitude }
    })
}
export const getAddressByCoords = ({ latitude, longitude }) => {
    return new Promise(resolve => {
        new global.google.maps.Geocoder().geocode(
            { latLng: { lat: latitude, lng: longitude } },
            function (results, status) {
                if (status === global.google.maps.GeocoderStatus.OK) {
                    if (results[1]) {
                        let country = null,
                            countryCode = null,
                            city = null,
                            cityAlt = null
                        let c, lc, component
                        let r = 0
                        const rl = results.length
                        for (; r < rl; r += 1) {
                            const result = results[r]

                            if (!city && result.types[0] === 'locality') {
                                for (
                                    c = 0,
                                        lc = result.address_components.length;
                                    c < lc;
                                    c += 1
                                ) {
                                    component = result.address_components[c]

                                    if (component.types[0] === 'locality') {
                                        city = component.long_name
                                        break
                                    }
                                }
                            } else if (
                                !city &&
                                !cityAlt &&
                                result.types[0] ===
                                    'administrative_area_level_1'
                            ) {
                                for (
                                    c = 0,
                                        lc = result.address_components.length;
                                    c < lc;
                                    c += 1
                                ) {
                                    component = result.address_components[c]

                                    if (
                                        component.types[0] ===
                                        'administrative_area_level_1'
                                    ) {
                                        cityAlt = component.long_name
                                        break
                                    }
                                }
                            } else if (
                                !country &&
                                result.types[0] === 'country'
                            ) {
                                country = result.address_components[0].long_name
                                countryCode =
                                    result.address_components[0].short_name
                            }

                            if (city && country) {
                                break
                            }
                        }

                        // console.log(
                        //   'City: ' +
                        //     city +
                        //     ', City2: ' +
                        //     cityAlt +
                        //     ', Country: ' +
                        //     country +
                        //     ', Country Code: ' +
                        //     countryCode
                        // );
                        resolve({ city, country, countryCode })
                    }
                }
            }
        )
    })
}
