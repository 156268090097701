import axios from 'axios'
import httpCodes from 'http-status-codes'
import { clientId, clientSecret, endpoint } from '../../config'
import rest from '../rest'

const apiPath = 'api/v1'

const responseInterceptor = async ({ config, status, data }) => {
    if (status === httpCodes.UNAUTHORIZED && config.repeat > 0) {
        return axios({ ...config, repeat: config.repeat - 1 })
    }
    return data
}

const requestInterceptor = function (config) {
    const repeat = config.repeat >= 0 ? config.repeat : 1
    return { ...config, repeat }
}

export default async function () {
    try {
        const token = await rest.authorizeAsGuest({
            clientId,
            clientSecret,
            endpoint,
        })
        const guestSession = axios.create({
            baseURL: `${endpoint}/${apiPath}`,
            //timeout: 5000,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token.access_token}`,
            },
        })
        guestSession.interceptors.response.use(responseInterceptor)
        guestSession.interceptors.request.use(requestInterceptor)
        global.guestSession = guestSession
    } catch (error) {
        console.error("Can't authenticate application", error)
        throw error
    }
}
