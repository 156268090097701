import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import { withLocale } from '../../../../core/ApplicationContext'
import FlexContainer from '../../../components/FlexContainer'
import Terms from './Terms'
// import type { PlacebDuration } from '../../../../types';

const tPrefix = 'checkoutProcess.booking'
const t = (phrase, params = {}) => I18n.t(`${tPrefix}.${phrase}`, params)

const TermsWrapper = styled(Terms)`
    background-color: #fafafa;
    padding-bottom: 17px;
`

const Root = styled(FlexContainer)`
    align-items: stretch;
`

const Content = styled.div`
    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

// type Props = {
//     duration: PlacebDuration
//     locale: string
// }
const TermsContainer = ({ duration: { unit: u }, locale } /*: Props*/) => {
    const unit = u === 'week' ? 'week' : 'month'
    const germanArticle =
        locale === 'de' && unit === 'week'
            ? 'eine '
            : locale === 'de' && unit === 'month'
            ? 'einen '
            : ''
    let period = `${t(`${'durations'}.${unit}`)}`
    period = locale === 'en' ? period.toLowerCase() : germanArticle + period
    return (
        <Root>
            <Content>
                <Terms
                    modalText={t('modalText1')}
                    modalTitle={t('modalTitle1')}
                    text={t('noticePeriodDescription', { period })}
                    title={t('noticePeriod')}
                />
            </Content>
        </Root>
    )
}

export default withLocale(TermsContainer)
