import React from 'react'
import { Helmet } from 'react-helmet'
import { pure } from 'recompose'
import { gtmKey } from '../config'
import PlaceBTheme from '../styles/PlacebTheme'

const Head = () => (
    <Helmet>
        <meta content="spa" name="appType" />
        <meta content={PlaceBTheme.palette.primary.main} name="theme-color" />
        {process.env.NODE_ENV === 'development' && (
            <script src="http://localhost:8097" />
        )}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-S8M8EBESVZ"></script>
        <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments)}
            gtag('js', new Date());

            gtag('config', 'G-S8M8EBESVZ');
            gtag('config', 'UA-62261689-3');
            `}
        </script>

    </Helmet>
)
export default pure(Head)
