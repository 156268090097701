import React from 'react'
import { iosAppLink } from '../../../config'

const styles = {
    width: '150px',
    maxWidth: '100%',
    maxHeight: '50px',
}
const imgPath =
    'https://www.placeb.ch/static/img/apple_app_store.6ef11b556928.png'
class AppleStoreBadge extends React.Component {
    render() {
        return (
            <a
                className="apple-store-badge"
                href={iosAppLink}
                rel="noopener noreferrer"
                target="_blank"
            >
                <img alt="Get it on App Store" src={imgPath} style={styles} />
            </a>
        )
    }
}
export default AppleStoreBadge
