import React from 'react'
import styled from 'styled-components'
import PlaceBTheme from '../../../../styles/PlacebTheme'
import { BoxWithAlignment } from '../../components/commonComponents'
import MapLineIcon from './map-icn.svg'

const mainColor = PlaceBTheme.palette.primary.main

const Root = styled(BoxWithAlignment)`
    width: 45px;
    height: 45px;
    cursor: pointer;
    border: 1px solid ${mainColor};
    border-radius: 3px;
`
const Icon = styled.img.attrs({ src: MapLineIcon })`
    width: 30px;
    height: 30px;
`
// type Props = { onClick: void => void }
const MapButton = ({ onClick } /*: Props*/) => (
    <Root onClick={onClick}>
        <Icon />
    </Root>
)

export default MapButton
