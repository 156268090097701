import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLocations } from '../../../redux/modules/locationsDuck'
import {
    getPrices,
    getSelectedLocationObject,
} from '../../../redux/modules/productDuck'
import { getGeolocation } from '../../../redux/modules/userGeolocation'
import { getSelectedPosition, hideLocationSelector } from '../LocationSelector'
import { getSelectedRadius } from '../RadiusSelector'
import LocationMap from './LocationMap'
import {
    changeMapCenter,
    getBounds,
    getMapCenter,
    mapMounted,
    mapPinClicked,
} from './locationsMapDuck'

const mapStateToProps = state => {
    const userPosition = getGeolocation(state)
    const range = getSelectedRadius(state)
    const selectedPosition = getSelectedPosition(state)
    const selectedLocation = getSelectedLocationObject(state)
    const locations = getLocations(state)
    const center = getMapCenter(state)
    const bounds = getBounds(state)
    const prices = getPrices(state)
    return {
        locations,
        userPosition,
        range,
        selectedPosition,
        value: selectedLocation,
        center,
        prices,
        bounds,
    }
}

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            onLocationClick: mapPinClicked,
            hideLocationSelector,
            mapMounted,
            changeMapCenter,
        },
        dispatch
    )
class LocationMapContainer extends React.Component /*<
  Props & { mapMounted: void => any }
>*/ {
    componentDidMount() {
        this.props.mapMounted()
    }
    render() {
        return global.google ? <LocationMap {...this.props} /> : null
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationMapContainer)
