import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled, { createGlobalStyle, css } from 'styled-components'
import { desktopWidth } from '../../../config'
import { LeftArrow, RightArrow } from './Arrows'
import LocationItem from './LocationItem'
// import type { PlacebLocation } from '../../../types';

const GlobalStyle = createGlobalStyle`
  .slick-track{
  display: flex;
  }
  * {
  min-height: 0;
  min-width: 0;
}
`
const Root = styled.div`
    ${({ showArrows }) =>
        showArrows &&
        css`
            @media (min-width: ${desktopWidth}px) {
                padding-left: 52px;
                padding-right: 52px;
            }
        `};
`

const settings = {
    slidesToScroll: 1,
    adaptiveHeight: false,
    swipeToSlide: true,
    infinite: false,
    centerPadding: '40px',
    slidesToShow: 3,
    speed: 500,
    swipe: true,
    arrows: true,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
}
// type Props = {
//   options: Array<PlacebLocation>,
//   onChange: PlacebLocation => void,
//   value: PlacebLocation,
//   className?: string,
// };
class LocationsSlider extends React.Component /*<Props>*/ {
    mouseClick = false
    isSliderReady = false
    sliderReady() {
        if (this.isSliderReady) {
            return
        }
        const { options, value } = this.props
        const newIndex = options.indexOf(value)
        this.slickGoTo(newIndex % options.length)
        this.value = value
        if (options.length > 3 && newIndex > options.length - 3) {
            this.slickGoTo(options.length - 3)
        } else if (options.length < 3 && newIndex !== 0) {
            this.slickGoTo(0)
        }
        this.isSliderReady = true
    }
    slickGoTo = index => {
        return this.slickGoTo && this.slider.slickGoTo(index)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { options, value: newValue } = nextProps
        const { value: oldValue } = this.props
        if (oldValue !== newValue) {
            if (this.isValueChangedOutside(newValue)) {
                let newIndex = options.indexOf(newValue) % options.length
                if (newIndex === options.length - 1) {
                    newIndex = newIndex - 2
                }
                if (newIndex === options.length - 2) {
                    newIndex = newIndex - 1
                }
                this.slickGoTo(0)
                this.value = newValue
            }
        }
    }
    isValueChangedOutside = newValue =>
        newValue &&
        (!this.value || (this.value && newValue.id !== this.value.id))
    onMouseUp = value => {
        return this.mouseClick && this.onChange(value)
    }
    onMouseDown = () => (this.mouseClick = true)
    onMouseMove = e => {
        if (this.mouseClick) {
            const nextMouseX = e.nativeEvent.pageX
            if (this.mouseClick && Math.abs(nextMouseX - this.mouseX) >= 20) {
                this.mouseClick = false
            }
        }
        this.mouseX = e.nativeEvent.pageX
    }
    onChange = value => {
        if (this.value === value) {
            return
        }
        this.value = value
        this.props.onChange(value)
    }
    afterChange = newIndex => {
        const { options } = this.props
        if (this.selfFixedPosition) {
            this.selfFixedPosition = false
            return
        }
        if (options.length > 3 && newIndex > options.length - 3) {
            this.slickGoTo(options.length - 3)
            this.selfFixedPosition = true
        } else if (options.length < 3 && newIndex !== 0) {
            this.selfFixedPosition = true
            this.slickGoTo(0)
        }
    }
    render() {
        const { options, value, className } = this.props
        if (!options.length || !value) {
            return null
        }
        const valueObject = options.find(item => item.id === value.id)
        const currentIndex = options.indexOf(valueObject)
        const opt =
            options.length < 3
                ? {
                      slidesToShow: options.length,
                  }
                : {}
        return (
            <Root className={className} showArrows={options.length > 3}>
                <GlobalStyle />
                <Slider
                    {...settings}
                    ref={slider => {
                        this.slider = slider
                        slider && this.sliderReady()
                    }}
                    initialSlide={currentIndex}
                    {...opt}
                    afterChange={this.afterChange}
                    arrows={!!options.length}
                >
                    {options.map((item, index) => (
                        <LocationItem
                            key={index}
                            active={index === currentIndex}
                            item={item}
                            onMouseDown={this.onMouseDown}
                            onMouseMove={this.onMouseMove}
                            onMouseUp={() => this.onMouseUp(item)}
                        />
                    ))}
                </Slider>
            </Root>
        )
    }
}

export default LocationsSlider
