import Clear from '@material-ui/icons/Clear'
import React from 'react'
import styled from 'styled-components'
import { desktopWidth } from '../../../config'

const LocationHeader = styled.div`
    background: white;
    flex-shrink: 0;
    display: flex;
    padding: 10px;
    flex-flow: row nowrap;
    @media (min-width: ${desktopWidth}px) {
        //display: none;
        padding: 0;
        position: relative;
        top: 0px;
        right: 0px;
        left: 0px;
    }
`
const ClearIcon = styled(Clear)`
    width: 45px !important;
    height: 35px !important;
    margin: 5px auto;
    color: #39393a;
`
const CrossButton = styled.div`
    height: 45px;
    width: 45px;
    margin: 0 6px;
    cursor: pointer;
    @media (min-width: ${desktopWidth}px) {
        display: none;
    }
`
// type Props = {
//   onCrossButtonClick: void => void,
//   children?: Array<React.Node> | React.Node,
//   className?: string,
// };
const SelectorHeaderCommon = (
    { onCrossButtonClick, children, className } /*: Props*/
) => (
    <LocationHeader className={className}>
        {children}
        <CrossButton onClick={onCrossButtonClick}>
            <ClearIcon />
        </CrossButton>
    </LocationHeader>
)

export default SelectorHeaderCommon
