import React from 'react'
// import PropTypes from 'prop-types';
import styled from 'styled-components'
import Location from '@material-ui/icons/Room'
import InformationItem from './InformationItem'
import { I18n } from 'react-redux-i18n-lite'

const tPrefix = 'checkoutProcess.booking'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const LocationIcon = styled(Location)`
    color: #80c700 !important;
    height: 24px;
    width: 24px;
`
class BoxLocation extends React.Component {
    render() {
        const { location, className } = this.props
        const locationParsed = `${location.street}, ${location.city}`
        return (
            <InformationItem
                className={className}
                icon={<LocationIcon />}
                text={locationParsed}
                title={t('location')}
            />
        )
    }
}

// InformationTitle.propTypes = {};
export default BoxLocation
