export const dimensions = {
    w: 'W',
    h: 'H',
    d: 'D',
}
export const sizeTypes = {
    xs: 'Extra Small',
    s: 'Small',
    m: 'Medium',
    l: 'Large',
    xl: 'Extra Large',
}
export const durations = {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    inSentence: {
        day: 'day',
        week: 'week',
        month: 'month',
        year: 'year',
    },
}
export const durationsMultiple = {
    day: 'Days',
    week: 'Weeks',
    month: 'Months',
    year: 'Years',
    inSentence: {
        day: 'days',
        week: 'weeks',
        month: 'months',
        year: 'years',
    },
}
