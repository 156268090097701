import { combineEpics } from 'redux-observable'
import { extractFromArray } from '../../services'
import { epic as boxFinderEpic } from './boxFinderDuck'
import * as CheaperModal from './CheaperModal'
import * as CheaperNotification from './CheaperNotification'
import * as LocationMap from './LocationMap'
import * as LocationSelector from './LocationSelector'
import * as RadiusSelector from './RadiusSelector'
import * as SizeTypeCalculator from './SizeTypeCalculator'
import { default as _translations } from './translations'

export { confirmButtonClicked } from './boxFinderDuck'
const components = [
    CheaperModal,
    LocationMap,
    LocationSelector,
    SizeTypeCalculator,
    RadiusSelector,
    CheaperNotification,
]
export const reducer = Object.assign(
    {},
    ...extractFromArray(components, 'reducer')
)
export const epic = combineEpics(
    boxFinderEpic,
    ...extractFromArray(components, 'epic')
)
export { default as BoxFinder } from './BoxFinderContainer'
export { default as FullMapScreen } from './FullMapScreen'

export {
    getShowCalculatorFlag,
    sizeChangedByCalculator,
} from './SizeTypeCalculator'
export { mapPinClicked } from './LocationMap'
export {
    getShowLocationSelectorFlag,
    userChangedPosition,
} from './LocationSelector'

export { getCheapestBox } from './CheaperModal'
export { getSelectedRadius, getShowRadiusSelectorFlag } from './RadiusSelector'
export const translations = { boxFinder: _translations }
