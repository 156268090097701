import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'

const tPrefix = 'boxFinder'
const t = (phrase, params) => I18n.t(`${tPrefix}.${phrase}`, params)

const NotificationContainer = styled.div`
    position: fixed;
    top: 0;
    z-index: 900;
    flex: 1;
    left: 0;
    right: 0;
    //padding: 20px;
    background-color: #4a90e2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    box-sizing: border-box;
`
const Text = styled.div`
    color: #ffffff;
    font-family: Helvetica;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    margin-left: 10px;
    font-size: 2.5vmin;
    line-height: 3vw;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: space-between;
    //margin-top: 15px;
    align-self: stretch;
    width: 300px;
    margin: 10px;
`
const ButtonCancel = styled.div`
    font-family: Helvetica;
    font-size: 14px;
    text-align: center;
    flex: 1;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
`
const Button = styled(ButtonCancel)`
    margin-left: 14px;
    border: none;
    background-color: #206ec9;
`
// export type Props = {
//   onNotificationClose: void => any,
//   onNotificationConfirm: void => any,
//   category: string,
// };
const CheaperNotification = (
    { onNotificationClose, onNotificationConfirm, category } /*: Props*/
) => (
    <NotificationContainer>
        <Text>{t('notificationText', { category: category })}</Text>
        <ButtonContainer>
            <ButtonCancel onClick={onNotificationClose}>
                {t('cancelButton')}
            </ButtonCancel>
            <Button onClick={onNotificationConfirm}>{t('agreeButton')}</Button>
        </ButtonContainer>
    </NotificationContainer>
)

export default CheaperNotification
