import { ElementsConsumer } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, formValueSelector, change } from 'redux-form'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClearIcon from '@material-ui/icons/Clear'
import { createTranslator } from '../../../services'
import { getPricingTexts } from '../../../services/utils'
import FlexContainer from '../../components/FlexContainer'
import PriceFooter from '../../components/PriceFooter'
import EditButton from '../components/EditButton'
import FullScreen from '../components/FullScreen'
import Input from '../components/Input'
import PersonalInformation from '../components/PersonalInformation'
import {
    AcceptTermsCheckbox,
    PasswordModal,
    PaymentHeader,
    PaymentSelector,
} from './components'
import StripeProvider from '../components/stripeProvider'
import rest from '../../../services/rest'

const t = createTranslator('checkoutProcess.payment')
const iphoneDevice = !!navigator.platform && /iPhone/.test(navigator.platform)

const styles = {
    checkboxLabel: {
        fontFamily: 'Helvetica',
        fontSize: '16px',
        lineHeight: '24px',
    },
    checkbox: {
        marginTop: '16px',
    },
    iconStyle: {
        width: '20px',
        height: '20px',
        marginRight: '9px',
    },
    background: {
        alignItems: 'stretch',
        alignSelf: 'stretch',
        backgroundColor: '#FAFAFA',
    },
}

const Root = styled(FullScreen)`
    overflow-y: scroll;
    -webkit-overflow-scrolling: ${iphoneDevice ? 'touch' : 'no'};
`

const MaxWidthWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 20px;

    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
        padding: 30px 20px;
    }
`

const InformationBlockInline = styled(FlexContainer)`
    display: flex;
    flex-direction: row;
`

const InformationBlock = styled(FlexContainer)`
    align-items: flex-start;
    box-shadow: inset 0 1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
`

const InformationBlockWrapper = styled(InformationBlock)`
    @supports (-ms-ime-align: auto) {
        display: block;
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    @-moz-document url-prefix() {
        display: block;
    }
`

const PersonalInformationWrapper = styled(PersonalInformation)`
    flex: 1;
`

const Title = styled.h3`
    color: #000000;
    margin: 0 0 10px 0;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
`

const Hint = styled.div`
    margin: 0 0 15px 0;
    font-size: 14px;
    line-height: 21px;
`

const DiscountLine = styled.div`
    display: flex;
    width: 100%;
`

const ValidateButton = styled.button`
    margin-left: 25px;
    background-color: #ffffff;
    padding: 5px 20px;
    color: #80c700;
    border: 1px solid #80c700;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
`

const ClearIconButton = styled(IconButton)`
    padding: 5px !important;
`

const InputWrapperLong = styled(Input)`
    width: 100% !important;
`

class Discount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: null,
            error: false,
            message: null,
        }
        this.clear = this.clear.bind(this)
        this.onValidate = this.onValidate.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    clear() {
        this.props.dispatch(change('payment', 'couponCode', ''))
        this.props.onApplyDiscount(null)
    }

    onChange(newValue) {
        if (
            this.state.code &&
            newValue !== this.state.code &&
            this.state.code === this.props.input.value
        ) {
            // value has changed since last validation
            this.props.onApplyDiscount(null)
            this.setState({
                code: null,
                error: false,
                message: null,
            })
        }
        this.props.input.onChange(newValue)
    }

    onValidate() {
        const { duration, category, location } = this.props
        const selector = formValueSelector('payment')
        const code = selector(this.props.state, 'couponCode')

        if (!code) {
            return
        }

        rest.validateDiscount({
            durationUnit: duration.unit,
            duration: duration.duration,
            category: category.id,
            location: location.id,
            discount: code,
        }).then(({ error, message, type }) => {
            this.setState({
                code: code,
                error,
                message,
            })
            if (!error) {
                this.props.onApplyDiscount(type)
            }
        })
    }

    render() {
        const { input, meta } = this.props
        let display
        if (meta.error) {
            display = {
                error: true,
                errorText: meta.error,
            }
        } else if (this.state.code === input.value) {
            if (this.state.error) {
                display = {
                    error: true,
                    errorText: this.state.message,
                }
            } else if (this.state.message) {
                display = {
                    helperText: this.state.message,
                }
            }
        }

        const _input = { ...input, onChange: this.onChange }

        return (
            <DiscountLine>
                <InputWrapperLong
                    title={t('enterCode')}
                    {..._input}
                    {...display}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ClearIconButton onClick={this.clear}>
                                    <ClearIcon style={{ color: '#bbb' }} />
                                </ClearIconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <ValidateButton onClick={this.onValidate}>
                    {t('applyCouponCode')}
                </ValidateButton>
            </DiscountLine>
        )
    }
}

class Payment extends React.Component {
    static propTypes = {
        userInformation: PropTypes.object.isRequired,
        price: PropTypes.object.isRequired,
        openUserDetails: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        openTnC: PropTypes.func.isRequired,
        duration: PropTypes.object.isRequired,
        className: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            appliedDiscount: null,
        }
        this.applyDiscount = this.applyDiscount.bind(this)
    }

    renderAcceptTerms = ({ input: { value, onChange }, meta: { error } }) => (
        <AcceptTermsCheckbox
            checked={value}
            error={error}
            onClick={() => onChange(!value)}
        />
    )

    applyDiscount(type) {
        this.setState({
            appliedDiscount: type,
        })
    }

    onSubmit = (elements, stripe) => {
        // HACK
        window.stripeHelpers = {
            elements,
            stripe,
        }
        this.props.onConfirm()
    }

    render() {
        const {
            className,
            userInformation,
            price,
            category,
            preOpened,
            openUserDetails,
            duration,
        } = this.props

        const pricingTexts = getPricingTexts(
            price.price,
            duration.unit,
            duration.duration,
            category,
            preOpened,
            price.insurancePerMonth,
            this.state.appliedDiscount
        )

        return (
            <Root className={className}>
                <StripeProvider>
                    <PaymentHeader onButtonClick={openUserDetails} />
                    <MaxWidthWrapper>
                        <InformationBlockInline>
                            <PersonalInformationWrapper
                                userInformation={userInformation}
                            />
                            <EditButton onClick={openUserDetails} />
                        </InformationBlockInline>
                    </MaxWidthWrapper>
                    <InformationBlock id="creditCard" style={styles.background}>
                        <MaxWidthWrapper>
                            <Title>{t('paymentOptions')}</Title>
                            <PaymentSelector rating={userInformation.rating} />
                        </MaxWidthWrapper>
                    </InformationBlock>
                    <InformationBlockWrapper id="couponCode">
                        <MaxWidthWrapper>
                            <Title>{t('couponCode')}</Title>
                            <input
                                name="discount"
                                style={{ display: 'none' }}
                            />
                            <Field
                                component={Discount}
                                name={'couponCode'}
                                props={{
                                    ...this.props,
                                    onApplyDiscount: this.applyDiscount,
                                }}
                            />
                        </MaxWidthWrapper>
                    </InformationBlockWrapper>
                    <InformationBlock
                        id="acceptTerms"
                        style={styles.background}
                    >
                        <MaxWidthWrapper>
                            <Field
                                component={this.renderAcceptTerms}
                                name="acceptTerms"
                            />
                            <Hint>{t('hint')}</Hint>
                        </MaxWidthWrapper>
                    </InformationBlock>
                    <ElementsConsumer>
                        {({ elements, stripe }) => (
                            <PriceFooter
                                autocomplete="off"
                                buttonTitle={t('confirm')}
                                onClick={() => this.onSubmit(elements, stripe)}
                                pricingTexts={pricingTexts}
                                relative={iphoneDevice}
                            />
                        )}
                    </ElementsConsumer>
                    <PasswordModal />
                </StripeProvider>
            </Root>
        )
    }
}

export default Payment
