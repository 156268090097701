import * as R from 'ramda'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import emptyUser from '../../../../constants/emptyUser'
import { getLoggedUserProfile } from '../../../../redux/modules/auth'
import rest from '../../../../services/rest'
import { getProfileFormValues } from '../../UserDetails/ProfileForm'
import { updateUser, updateUserError, updateUserSuccess } from '../actions'

export default (action$, state$) =>
    action$.pipe(
        ofType(updateUser.toString()),
        mergeMap(async () => {
            try {
                const userProfileForm = getProfileFormValues(state$.value)
                const loggedUser = getLoggedUserProfile(state$.value)
                const pick = R.pick(R.keys(emptyUser))
                const diff = R.compose(
                    R.pickBy((val, key) => loggedUser[key] !== val),
                    pick
                )(userProfileForm)
                if (R.isEmpty(diff)) {
                    return updateUserSuccess('Update user: nothing to update')
                }
                await rest.updateUser(diff)
                return updateUserSuccess()
            } catch (error) {
                return updateUserError({ error })
            }
        })
    )
