import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getAvailableCategories,
    getSelectedLocationObject,
} from '../../../redux/modules/productDuck'
import SizeTypeCalculator from './SizeTypeCalculator'
import {
    changeCategory,
    changeSizeType,
    confirmChoose,
    getCategory,
    getCategoryObject,
    getShowCalculatorFlag,
    getSizeType,
    hideSelector,
} from './sizeTypeCalculatorDuck'
// import type { Action } from '../../../types';
const mapStateToProps = state => ({
    showSelector: getShowCalculatorFlag(state),
    selectedLocation: getSelectedLocationObject(state),
    category: getCategory(state),
    sizeType: getSizeType(state),
    categoryObject: getCategoryObject(state),
    availableCategories: getAvailableCategories(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            confirmChoose,
            changeCategory: category => changeCategory({ category }),
            changeSizeType: sizeType => changeSizeType({ sizeType }),
            onClose: hideSelector,
        },
        dispatch
    )
export default connect(mapStateToProps, mapDispatchToProps)(SizeTypeCalculator)
