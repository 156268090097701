import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    changeCategory,
    changeLocation,
    getCheapestLocation,
    getNearestLocation,
    getNearSizeAvailableCategory,
    getSelectedCategoryObject,
} from '../../../redux/modules/productDuck'
import { getSelectedRadius } from '../RadiusSelector'
import NoBoxesFooter from './NoBoxesFooter'

function mapStateToProps(state) {
    const categoryObject = getSelectedCategoryObject(state)
    const selectedRadius = getSelectedRadius(state)
    const cheapestLocation = getCheapestLocation(state)
    const nearestLocation = getNearestLocation(state)
    const smallerAvailableCategory = getNearSizeAvailableCategory(state, 'min')
    const largerAvailableCategory = getNearSizeAvailableCategory(state, 'max')
    return {
        categoryObject,
        selectedRadius,
        cheapestLocation,
        nearestLocation,
        smallerAvailableCategory,
        largerAvailableCategory,
    }
}

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            changeLocation: location =>
                changeLocation({ location, setPosition: true }),
            changeCategory: category => changeCategory({ category }),
        },
        dispatch
    )

const NoBoxesFooterContainer = props =>
    !props.categoryObject ? <NoBoxesFooter {...props} /> : null

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoBoxesFooterContainer)
