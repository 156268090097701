import { i18nReducer } from 'react-redux-i18n-lite'
import { combineEpics } from 'redux-observable'
import * as screens from '../../components'
import { extractFromArray } from '../../services'
import * as auth from './auth'
import * as coreDuck from './coreDuck'
import * as gtm from './gtmDuck'
import * as locations from './locationsDuck'
import * as productDuck from './productDuck'
import * as userGeolocation from './userGeolocation'

const modules = [gtm, productDuck, coreDuck, userGeolocation, auth, locations]
const reducers = Object.assign({}, ...extractFromArray(modules, 'reducer'))

export default {
    i18n: i18nReducer,
    ...reducers,
    ...screens.reducer,
}

export const epic = combineEpics(...extractFromArray(modules, 'epic'))
