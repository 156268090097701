import * as R from 'ramda'
import { ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators'
import { getLoggedUserProfile } from '../../../../redux/modules/auth'
import {
    getSelectedCategoryObject,
    getSelectedDurationObject,
    getSelectedLocationObject,
} from '../../../../redux/modules/productDuck'
import rest from '../../../../services/rest'
import { createAndConfirmSetupIntent } from '../../Payment/components/utils'
import actions, {
    createReservation,
    reservationFailed,
    reservationSuccess,
} from '../actions'
import { getInsuranceValue, getStartDate } from '../selectors'

export const formatDate = date => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
}

export default (action$, state$) =>
    action$.pipe(
        ofType(createReservation.toString()),
        exhaustMap(({ payload: { card, couponCode, invoice3rdParty } }) => {
            const state = state$.value
            const loggedUser = getLoggedUserProfile(state)
            const startDate = getStartDate(state)
            const insuranceValue = getInsuranceValue(state)
            const duration = getSelectedDurationObject(state)
            const location = getSelectedLocationObject(state)
            const category = getSelectedCategoryObject(state)
            const date = formatDate(startDate)
            const reservationObject = {
                location: location.id,
                duration: duration.id,
                startDate: date,
                boxValue: insuranceValue || 0,
                includeInsurance: !!insuranceValue,
                discount: couponCode,
                payByBill3rdParty: invoice3rdParty,
            }

            return (
                card
                    ? Rx.from(Promise.resolve(card)).pipe(
                          mergeMap(() =>
                              createAndConfirmSetupIntent(
                                  loggedUser.token,
                                  loggedUser.id,
                                  window.stripeHelpers.stripe,
                                  window.stripeHelpers.elements
                              )
                          ),
                          map(intent => {
                              return R.assoc(
                                  'paymentMethod',
                                  intent.payment_method,
                                  reservationObject
                              )
                          })
                      )
                    : Rx.of(R.assoc('payByBill', true, reservationObject))
            ).pipe(
                catchError(error =>
                    Rx.throwError(actions.creditCardError(error))
                ),
                mergeMap(_reservationObject =>
                    rest.createReservation(_reservationObject, loggedUser.id)
                ),
                map(priceInfo => ({ location, category, duration, priceInfo })),
                map(reservation => reservationSuccess({ reservation })),
                catchError(error =>
                    error.type
                        ? [error, reservationFailed({ error: 'Invalid card' })]
                        : [reservationFailed({ error })]
                )
            )
        })
    )
