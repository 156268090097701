import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const themeColor = '#80C700'

export default createMuiTheme({
    palette: {
        primary: {
            main: themeColor,
        },
        secondary: {
            main: '#000000',
        },
        error: {
            main: '#ff0000',
        },
        action: 'white',
    },
    overrides: {
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                top: 30,
            },
        },
        MuiIcon: {
            root: {
                overflow: 'visible',
            },
        },
        MuiInput: {
            underline: {
                '&:hover': {
                    borderColor: 'red',
                },
                '&:after': {
                    borderBottomColor: '#181818 !important',
                },
                '&:hover:before': {
                    borderBottom: '2px solid #858585',
                },
                '&:before': {
                    borderBottom: '2px solid #858585',
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #858585',
                },
                '&$disabled:before': {
                    borderBottom: '1px dotted #858585',
                },
                '&:hover:not($disabled):error:before !important': {
                    borderBottom: '2px solid #858585',
                },
            },
        },
        MuiPaper: {
            root: {
                // maxHeight: '100%!important',
                overflowY: 'auto',
            },
        },
        MuiPickersModal: {
            dialog: {
                overflow: 'auto!important',
            },
        },
    },
})
