import React from 'react'
import styled from 'styled-components'
import { desktopWidth } from '../../../config'
import BoxSizeTypes from '../../../constants/BoxSizeTypes'
import Dimensions from '../components/Dimensions'
import SteppedSlider from '../components/SteppedSlider'
import SizeDescription from './SizeDescription'
// import type { PlacebCategory } from '../../../types';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex: 1;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
`

const ImageWrapper = styled.div`
    position: relative;
    padding-right: 18px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;
    flex: 1;
    flex-shrink: 0;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        div[tabindex='1'] & {
            display: flex;
        }
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) and (max-width: 1023px) {
        div[tabindex='1'] & {
            text-align: center;
        }
    }
`
const SizeTypeImage = styled.img`
    //margin: 5px 100px;
    margin: 5px 10px;
    display: flex;
    //flex:1;
    text-align: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    height: 100px;
    max-width: 165px;
    @media (min-height: 640px) {
        height: 170px;
        max-width: 250px;
    }
    @media (min-height: 655px) {
        height: 200px;
        max-width: 300px;
    }
    @media (min-height: 733px) {
        height: 270px;
        max-width: 340px;
    }
    @media (min-height: 760px) {
        height: 300px;
        max-width: 360px;
    }
    //@media (min-height: 860px) {
    //    height: 400px;
    //    max-width: 495px;
    //}
    //@media (min-height: 965px) {
    //    height: 540px;
    //    max-width: 600px;
    //}
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        div[tabindex='1'] & {
            display: inline-block;
        }
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) and (min-width: 1024px) and (max-width: 1500px) {
        div[tabindex='1'] & {
            margin-left: 0 !important;
        }
    }
`

const DimensionsWrapper = styled(Dimensions)`
    margin-left: 20px;
    margin-right: 20px;

    @media (min-width: 640px) and (max-width: ${desktopWidth - 1}px) {
        position: absolute;
        right: 0;
    }
`

const SteppedSliderWrapper = styled(SteppedSlider)`
    margin-left: 21px;
    margin-right: 21px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 22px;
`
const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL']
// type Props = {
//   value: string,
//   className?: string,
//   category: PlacebCategory,
//   onChange: string => void,
//   children?: Array<React.Node> | React.Node,
// };
class SizeTypePicker extends React.PureComponent /*<Props>*/ {
    onChange = (newIndex /*: number*/) =>
        this.props.onChange(sizes[newIndex].toLowerCase())
    render() {
        const { value, children, className, category } = this.props

        const currentIndex = sizes.indexOf(value.toUpperCase())
        return (
            <Root className={className}>
                <ImageWrapper>
                    <SizeTypeImage
                        src={
                            category
                                ? category.imageUrl
                                : BoxSizeTypes[value.toLowerCase()].image
                        }
                        //src={BoxSizeTypes[value.toLowerCase()].image}
                    />
                    {category && <DimensionsWrapper category={category} />}
                </ImageWrapper>
                <SizeDescription sizeType={BoxSizeTypes[value.toLowerCase()]}>
                    {children}
                </SizeDescription>
                <SteppedSliderWrapper
                    label={sizes}
                    onChange={this.onChange}
                    optionsNumber={sizes.length}
                    value={currentIndex}
                />
            </Root>
        )
    }
}
export default SizeTypePicker
