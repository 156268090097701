import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import * as React from 'react'
import { FC, ReactNode, useEffect, useState } from 'react'
import { stripeKey } from '../../../config'

interface Props {
    children?: ReactNode
    locale: any
}

const StripeProvider: FC<Props> = props => {
    const [
        stripePromise,
        setStripePromise,
    ] = useState<Promise<Stripe | null> | null>(null)

    useEffect(() => setStripePromise(loadStripe(stripeKey)), [])

    return (
        <Elements options={{ locale: props.locale }} stripe={stripePromise}>
            {props.children}
        </Elements>
    )
}

export default StripeProvider
