import boxFinder from '../components/BoxFinder/translations/en'
import checkoutProcess from '../components/CheckoutProcess/translations/en'

export default {
    boxFinder,
    checkoutProcess,
    bookingPage: {
        header: {
            step1: 'Step 1: Booking',
            step2: 'Step 2: Your details',
            step3: 'Step 3: Payment',
        },
        step1: {
            insuranceModal: {
                title: 'Are you sure you do not need an insurance?',
                description:
                    "An insurance against theft, fire and water damage is obligatory. You can contact your homeowner's insurance or you can get insurance from us. The costs are CHF 6 per CHF 10.000 of goods value per month",
                yes: 'Yes',
                no: 'No',
            },
            startDate: 'Choose a start date',
            requiredFieldError: 'Please specify value to be insured',
            minimumTerm: 'minimum period',
            insurance: 'Insurance',
            insuranceDescription:
                'The costs are CHF 6 per CHF 10.000 of goods value per month',
            confirmInsurance:
                'Yes, I would like an insurance policy (recommended)',
            boxValue: 'Value of your goods (CHF)',
            disableInsurance:
                'No, I already have insurance and in case of loss, I will not file a claim against placeB',
            nextStep: 'Next step',
            ok: 'OK',
            cancel: 'Cancel',
            durations: {
                day: 'Day',
                week: 'Week',
                month: 'Month',
                year: 'Year',
            },
        },
        step2: {
            loginModal: {
                login: 'Log in',
                cancel: 'Cancel',
                email: 'Email',
                password: 'Password',
                emailError: 'Wrong email',
                passwordError: 'The password is too short',
            },
            passwordModal: {
                login: 'Log in',
                cancel: 'Cancel',
                choosePassword: 'Choose new password',
                password: 'Password',
                email: 'Email',
                passwordError: 'The password is too short',
                newPasswordPropose:
                    'To use the placeB app, you need a personal password. Please create a password here and remember it well.',
            },
            requiredFieldError: 'This field is required',
            loginButton: 'Already have an account?',
            name: 'Name',
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company (optional)',
            country: 'Country',
            email: 'Email',
            address: 'Address',
            address2: 'Additional information (optional)',
            zip: 'Zip',
            city: 'City',
            phone: 'Phone',
            confirm: 'Next step',
        },
        step3: {
            edit: 'Edit',
            creditCard: 'Credit card',
            invoice: 'Invoice (+ CHF 5 / Invoice)',
            accept: 'Yes, I accept the General Terms and Conditions',
            confirm: 'Book now',
            paymentOptions: 'Payment options',
            couponCode: 'Coupon code',
            doYouHaveCode: 'Do you have a coupon code?',
            incorrectValue: 'Incorrect value',
            acceptTerms: 'Please accept terms',
        },
        bookingSuccess: {
            successLabel:
                'Thank you! You have successfully booked your box. Here are the next steps. :',
            verifyEmail: 'Verify email',
            installMobileApp: 'Install the placeB app on your smartphone',
            openYourBox:
                'Open the box. Only if you are standing in front of it, however! ',
            bookedBox: 'Booked boxes',
            installPropose:
                'Install the placeB app to profit from all benefits',
            personalInformation: 'Personal Information',
            verified: 'Verified',
            editInfo: 'Edit personal information',
            openBox: 'Open Box',
            openBuilding: 'Open Building',
            viewDetails: 'View details',
            mobileApp: 'Mobile App',
            youHaventInstall: "You haven't installed the mobile app yet!",
            verifyPhone: 'Verify Phone',
            feedbackForm:
                'https://docs.google.com/forms/d/e/1FAIpQLScRTZfDRko3MEOsC5QMHpVnnNu9xx8g-ZsgETbPYuwUR0rnUw',
        },
        durationSelect: {
            perMonth: 'Per month',
            perWeek: 'Per week',
            perDay: 'Per day',
            ourBestseller: 'Our Bestseller',
            durations: {
                day: 'Day',
                days: 'Days',
                week: 'Week',
                weeks: 'weeks',
                month: 'Month',
                months: 'Months',
                year: 'Year',
                years: 'years',
            },
        },
        summaryRibbon: {
            insurance: 'Insurance',
            invoice: 'Invoice (+ CHF 5 / Invoice)',
            total: 'Total',
            price: '%{price} CHF / %{unit}',
            priceMonth: '%{price} CHF / Month',
            month: 'Month',
            year: 'Year',
            week: 'Week',
            day: 'Day',
            bill: 'Invoice',
        },
    },
    filter: {
        volume: 'Volume',
        price: '%{price} CHF / %{unit}',
        sizeUnit: 'Size in m³',
        categoryItem: {
            priceString: 'Starting from %{minPrice} CHF / months',
        },
        searchPage: {
            hintText: 'Enter your postal code or your location',
        },
    },
    boxItem: {
        volumeString: 'Volume: %{volume} m³',
        areaString: 'Space: %{square} m²',
    },
    categoryDetails: {
        priceString: 'Starting from %{minPrice} CHF / months',
        squareString: 'Space: %{square} m²',
        volumeString: 'Volume: %{volume} m³',
        sizeString:
            'Size: %{depth} %{sizeUnits} × %{width} %{sizeUnits} × %{height} %{sizeUnits} (L×W×H)',
        aboutTheLocation: 'About the location',
        bookNow: 'Book now',
        wantCharged: 'There are no costs yet',
        benefits: {
            a: 'Access 24/7',
            b: 'Keyless',
            c: 'Video surveillance',
        },
        price: '%{price} CHF / %{unit}',
        volume: 'Volume',
    },
    userProfile: {
        personalInformation: 'Personal Information',
        name: 'Name',
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Company (optional)',
        country: 'Country',
        email: 'Email',
        address: 'Address',
        address2: 'Additional information (optional)',
        zip: 'Zip',
        city: 'City',
        phone: 'Mobile phone',
        sendPostcard: 'Send verification letter',
        verified: 'Verified',
        saveChanges: 'Save changes',
        resendSms: 'Resend verification SMS',
    },
    autoComplete: {
        popularLocations: 'Popular locations',
    },
    actions: {
        wrongCreds: 'Wrong email address or password',
        registrationFailed: 'Registration failed',
        reservationFailed: "The booking could not be completed. This could be for the following reasons:\n" +
            "• Your telephone number or email address is already stored in our system (you may have already made a booking or registered with us beforehand). In this case, please click on ‘Log in with existing account’ when entering your personal details.\n" +
            "• Your payment method is not covered. Please top up your credit card or choose another payment method.\n",
        error: 'Error',
        cantSave: "Can't save",
        cantOpen: "Can't open",
        profileUpdated: 'Profile updated',
        noDoors: 'No doors for openings',
    },
    registration: {
        confirm: 'Register',
    },
    auth: {
        error: 'Login failed',
    },
}
