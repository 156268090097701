import { ofType } from 'redux-observable'
import { mergeMapTo } from 'rxjs/operators'
import {
    userChangedSize,
    userSelectedLocationFromSlider,
    valueChanged,
} from '../../../../redux/modules/productDuck'
import { mapPinClicked } from '../../LocationMap'
import { userChangedPosition } from '../../LocationSelector'
import { sizeChangedByCalculator } from '../../SizeTypeCalculator'
// import type { Stream } from '../../../../types';

export default (action$ /*: Stream*/) =>
    action$.pipe(
        ofType(
            mapPinClicked.toString(),
            userSelectedLocationFromSlider.toString(),
            userChangedPosition.toString(),
            userChangedSize.toString(),
            sizeChangedByCalculator.toString()
        ),
        mergeMapTo([valueChanged()])
    )
