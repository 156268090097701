import * as locationSelectorDuck from './locationSelectorDuck'

export { default } from './LocationSelectorPortalContainer'

export const reducer = {
    locationSelector: locationSelectorDuck.default,
}
export {
    getShowLocationSelectorFlag,
    userChangedPosition,
    changePosition,
    getSelectedPosition,
    setPosition,
    hideLocationSelector,
} from './locationSelectorDuck'
export const epic = locationSelectorDuck.epic
