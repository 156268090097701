import * as R from 'ramda'
import { createAction as createReduxAction, handleActions } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { delay, mergeMap } from 'rxjs/operators'
import RANGES from '../../../constants/Ranges'
import { globalizeSelectors } from '../../../services'

const moduleName = 'radiusSelector'
const defaultState = {
    showSelector: false,
    selectedRadius: RANGES[0],
}
const globalize = globalizeSelectors(R.prop(moduleName))

const createAction = type => createReduxAction(`${moduleName}/${type}`)
export const showRadiusSelector = createAction('SHOW_SELECTOR')
export const hideRadiusSelector = createAction('HIDE_SELECTOR')
const mergeState = object => state => R.merge(state, object)
export const changeRadius = createAction('CHANGE_RADIUS')
export const radiusChangedByUser = createAction('RADIUS_CHANGED_BY_USER')
export default handleActions(
    {
        [showRadiusSelector]: mergeState({ showSelector: true }),
        [hideRadiusSelector]: mergeState({ showSelector: false }),
        [changeRadius]: (state, { payload: { radius } }) =>
            R.merge(state, { selectedRadius: radius }),
    },
    defaultState
)

const radiusChangedByUserEpic = action$ =>
    action$.pipe(
        ofType(radiusChangedByUser.toString()),
        delay(10),
        mergeMap(({ payload: { radius } }) => [changeRadius({ radius })])
    )

const _getShowShelectorFlag = R.prop('showSelector')
export const getShowRadiusSelectorFlag = globalize(_getShowShelectorFlag)
const _getSelectedRadius = R.prop('selectedRadius')

export const epic = combineEpics(radiusChangedByUserEpic)
export const getSelectedRadius = globalize(_getSelectedRadius)
