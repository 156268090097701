import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import moduleName from './moduleName'
import { default as _reducer } from './reducer'

const persistConfig = {
    key: 'product',
    storage: storageSession,
    whitelist: ['selectedPosition', 'positionChangedByUser'],
}

export const reducer = {
    [moduleName]: persistReducer(persistConfig, _reducer),
}

export {
    boxFinderReady,
    changeCategory,
    changeLocation,
    presetValues,
    userChangedSize,
    userSelectedLocationFromSlider,
    changeSizeType,
    userChangedSizeType,
    valueChanged,
    openBoxFinder,
    changeDuration,
} from './actions'

export {
    getPrices,
    getIsValueChanged,
    getBoxFinderReadyFlag,
    getPreopenedFlag,
    getSelectedDurationObject,
    getSelectedDuration,
    getDurations,
    getCategories,
    getAvailableCategories,
    getSelectedCategoryObject,
    getSelectedLocationObject,
    getCheapestLocation,
    getNearestLocation,
    getNearSizeAvailableCategory,
    getSelectedCategoryTypeObject,
    getSelectedCategory,
    getSelectedCategoryType,
    getIsLocationPreselected,
    getSelectedLocation,
} from './selectors'
