import React from 'react'
import styled, { css } from 'styled-components'
import PlaceBTheme from '../../../styles/PlacebTheme'

import { NoWrapText } from './commonComponents'

const Root = styled.div`
    display: flex;
    min-width: 0;
    align-items: center;
    box-sizing: border-box;
    height: 45px;
    flex: 2;
    border: 1px solid ${PlaceBTheme.palette.primary.main};
    margin-right: 10px;
    padding-right: 10px;
    margin-top: 1px;
    ${props =>
        !props.active &&
        css`
            border-color: #dddddd;
        `};
    cursor: pointer;
`
const LocationValue = styled(NoWrapText)``
export const Icon = styled.img`
    height: 21px;
    width: 21px;
    color: #39393a;
    margin: 10px;
    margin-top: 13px;
    flex-shrink: 0;
`
// type Props = {|
//   icon: string,
//   value: string,
//   onClick: void => void,
//   className?: string,
// |};
const StaticInput = ({ icon, value, onClick, className } /*: Props*/) => (
    <Root className={className} onClick={onClick}>
        <Icon src={icon} />
        <LocationValue>{value}</LocationValue>
    </Root>
)
export default StaticInput
