import * as yup from 'yup'
import rest from '../../../services/rest'
import { useSelector } from 'react-redux'

export default yup.object().shape({
    acceptTerms: yup.bool().oneOf([true]).required(),
    invoice: yup.bool(),
    invoice3rdParty: yup.bool(),
    creditCard: yup
        .object()
        .when(
            ['invoice', 'invoice3rdParty'],
            (invoice, invoice3rdParty, schema) =>
                !invoice && !invoice3rdParty
                    ? schema.required()
                    : schema.notRequired()
        ),
    couponCode: yup
        .string()
        .notRequired()
        .test({
            name: 'invalid-coupon',
            // eslint-disable-next-line object-shorthand
            test: async function (value) {
                if (!value) {
                    return true
                }

                const response = await rest.validateDiscount({
                    durationUnit: this.parent.duration.unit,
                    duration: this.parent.duration.duration,
                    category: this.parent.category.id,
                    location: this.parent.location.id,
                    discount: value,
                })

                if (response.error) {
                    return new yup.ValidationError(
                        response.message,
                        value,
                        'couponCode'
                    )
                }
                return !response.error
            },
        }),
})
