import { FormHelperText as BaseFormHelperText } from '@material-ui/core'
import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
// @ts-ignore
import { I18n } from 'react-redux-i18n-lite'
import { change } from 'redux-form'
import styled from 'styled-components'

const tPrefix = 'checkoutProcess.payment'
const t = (phrase: string) => I18n.t(`${tPrefix}.${phrase}`)

export const CardRoot = styled.div`
    @media (min-width: 450px) {
        width: 350px;
    }

    .StripeElement {
        height: 18.667px;
        padding: 6px 0 7px;
        color: rgba(0, 0, 0, 0.87);
        border-bottom: 2px solid #858585;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .StripeElement::placeholder {
        color: #ababab;
    }

    .StripeElement--focus {
        border-bottom-color: rgba(0, 0, 0, 0.87);
    }
`

export const FormHelperText = styled(BaseFormHelperText)`
    position: static !important;
`

export const cardElementStyle = {
    base: {
        fontSize: '16px',
        '::placeholder': {
            color: '#ababab',
        },
    },
}

const CreditCardForm: React.FC<any> = props => {
    const dispatch = useDispatch()
    const [error, setError] = useState<string>()

    const onChange = useCallback(
        (e: StripeCardElementChangeEvent) => {
            if (!e.complete) {
                setError(e?.error?.message)

                dispatch(
                    change(
                        props.formName,
                        props.field.input.name,
                        undefined,
                        true
                    )
                )

                return
            }

            const { stripe, elements } = props

            setError(undefined)

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return
            }

            const cardElement = elements.getElement(CardElement)

            if (!cardElement) {
                return
            }

            stripe
                .createToken(cardElement)
                .then((response: any) => {
                    if (response.error) {
                        throw response.error
                    }

                    dispatch(
                        change(
                            props.formName,
                            props.field.input.name,
                            response.token,
                            true
                        )
                    )
                })
                .catch(console.error)
        },
        [dispatch, props.field.input.name, props.stripe]
    )

    const _error = error || (props.field.meta.error && t('required'))

    return (
        <CardRoot className={props.className} onClick={props.onClick}>
            <CardElement
                onChange={onChange}
                options={{
                    hidePostalCode: true,
                    disabled: props.disabled,
                    style: cardElementStyle,
                }}
            />
            {_error && <FormHelperText error>{_error}</FormHelperText>}
        </CardRoot>
    )
}

export default React.memo(CreditCardForm)
