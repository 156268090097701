import { combineEpics } from 'redux-observable'
import bookingPageLoadedEpic from './bookingPageLoadedEpic'
import boxFinderLoadedEpic from './boxFinderLoadedEpic'
import boxSelectedEpic from './boxSelectedEpic'
import confirmPaymentEpic from './confirmPaymentEpic'
import formFieldInputChangeEpic from './formFieldInputChangeEpic'
import formFieldInputErrorEpic from './formFieldInputErrorEpic'
import routeChangedEpic from './gtmReactionEpic'
import pageLoadedEpic from './pageLoadedEpic'
import paymentPageLoadedEpic from './paymentPageLoadedEpic'
import productChangedEpic from './productChangedEpic'
import purchaseCompletedEpic from './purchaseCompletedEpic'
import pushEventEpic from './pushEventEpic'
import userDetailsPageLoadedEpic from './userDetailsPageLoadedEpic'

export default combineEpics(
    pageLoadedEpic,
    boxFinderLoadedEpic,
    productChangedEpic,
    routeChangedEpic,
    boxSelectedEpic,
    bookingPageLoadedEpic,
    userDetailsPageLoadedEpic,
    paymentPageLoadedEpic,
    purchaseCompletedEpic,
    pushEventEpic,
    confirmPaymentEpic,
    formFieldInputChangeEpic,
    formFieldInputErrorEpic
)
