import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
// import type { StateObservable } from 'redux-observable';
import { mergeMap } from 'rxjs/operators'
import { getLocations } from '../../../../redux/modules/locationsDuck'
import { changeLocation } from '../../../../redux/modules/productDuck'
import {
    findClosestLocation,
    getMinRadius,
} from '../../../../redux/modules/productDuck/helpers'
import { getCoordsDyId } from '../../../../services/Geocoding'
import { changePosition, setPosition } from '../../LocationSelector'
import { changeRadius } from '../../RadiusSelector'
// import type { Stream } from '../../../../types';
//import { changeMapCenter } from '../../LocationMap';

export default (action$ /*: Stream*/, state$ /*: StateObservable*/) =>
    action$.pipe(
        ofType(changePosition.toString()),
        mergeMap(({ payload: { position, setLocation, byUser } }) =>
            Observable.create(async observer => {
                const locations = getLocations(state$.value)
                let location
                if (position.isPlaceB) {
                    location = position
                } else {
                    if (position.isSuggestion) {
                        const cords = await getCoordsDyId(position.place_id)
                        position = { ...position, ...cords }
                    }
                    location = findClosestLocation(position, locations)
                    const tolerableRadius = getMinRadius(position, location)
                    observer.next(changeRadius({ radius: tolerableRadius }))
                    //observer.next(changeMapCenter({ center: position }));
                }
                observer.next(setPosition({ position, byUser }))
                setLocation &&
                    observer.next(
                        changeLocation({ location, setPosition: false })
                    )
            })
        )
    )
