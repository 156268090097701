import * as R from 'ramda'
import { createSelector } from 'reselect'
import { getIsReservationPending } from '../../../components/CheckoutProcess/checkoutDuck/selectors'
import { getIsLoginProcessPending } from '../auth'

export { default as epic } from './epic'

export const isTasksPending = createSelector(
    [getIsReservationPending, getIsLoginProcessPending],
    (...flags) => R.any(R.equals(true))(flags)
)
export const getLanguage = state => state.i18n.locale
export { initApp, routeChanged } from './actions'
