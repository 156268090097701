import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import allowNull from '../../../../services/allowNull'
import FlexContainer from '../../../components/FlexContainer'
import Input from '../../components/Input'
import Terms from './Terms'

const tPrefix = 'checkoutProcess.booking'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)

const InsuranceSelectorContainer = styled(FlexContainer)`
    background-color: #fafafa;
    padding: 30px 0;
    align-items: flex-start;
    box-shadow: inset 0 1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    -webkit-text-size-adjust: 100%;
`

const Content = styled.div`
    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const Title = styled.div`
    flex: 1;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    font-family: Helvetica, sans-serif;
    padding: 0 19px;
`

const Text = styled.p`
    color: #000000;
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    margin: 9px 0;
    line-height: 24px;
    padding: 0 19px;
`

const InputWrapper = styled(Input)`
    display: inline-block;
    flex-direction: column;
    margin-left: 37px !important;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        width: 100%;
    }
`
const StrongStyle = styled.span`
    font-weight: bold;
`
const InputContainer = styled(FlexContainer)`
    flex-direction: row;
    padding: 0 19px;
    align-self: stretch;
    align-items: flex-start;
`

const PartnerImage = styled.img`
    max-width: 150px;
    padding-right: 19px;
`

const PartnerText = styled.p`
    color: #000000;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    margin-top: 4px;
    line-height: 16px;
    padding-right: 19px;
`

const styles = {
    group: {
        'align-self': 'stretch',
    },
    radio: {
        borderColor: '#DDDDDD',
        paddingTop: '20px',
        paddingLeft: 19,
        paddingRight: 19,
        cursor: 'pointer',
    },
    label: {
        fontSize: 16,
    },
    iconStyle: {
        height: '20px',
        width: '20px',
    },
    unchecked: {
        fill: 'grey',
    },
}

const TwoCol = styled.div`
    display: flex;
`

const Spacer = styled.div`
    flex-grow: 3;
`

class InsuranceSelector extends React.Component {
    onChange = value => {
        if (!isNaN(+value) && +value < 500000) {
            this.props.onChange(value.trim())
        }
    }

    disableInsurance = () => {
        this.props.onChange(null)
    }

    enableInsurance = () => this.props.onChange('')

    render() {
        if (!this.props.hasInsurance) {
            this.disableInsurance()
            return <div></div>
        }
        const { value, classes, error } = this.props
        const placeholder = t('boxValue')
        const insurance = value !== null
        const label = (
            <span>
                <StrongStyle>{t('disableInsuranceStrong')}</StrongStyle>
                {t('disableInsurance')}
            </span>
        )
        const errorText = t('incorrectValue')
        return (
            <InsuranceSelectorContainer>
                <Content>
                    <TwoCol>
                        <div>
                            <Title>{t('insurance')}</Title>
                            <Text>{t('insuranceDescription')}</Text>
                        </div>
                        <Spacer />
                        <div>
                            <PartnerImage
                                src="https://placeb-web.s3.amazonaws.com/images/mobi-logo.original.png"
                                alt="mobiliar"
                            />
                            <PartnerText>{t('insurancePartner')}</PartnerText>
                        </div>
                    </TwoCol>
                    <FormControlLabel
                        checked={insurance}
                        classes={{
                            root: classes.radio,
                            label: classes.label,
                        }}
                        control={<Radio color="primary" />}
                        id="insuranceValue"
                        label={
                            <StrongStyle>{t('confirmInsurance')}</StrongStyle>
                        }
                        onChange={this.enableInsurance}
                    />
                    <InputContainer>
                        <InputWrapper
                            disabled={!insurance}
                            error={!!error}
                            errorText={errorText}
                            onChange={this.onChange}
                            onCheck={() => this.resetError('insuranceValue')}
                            title={placeholder}
                            value={this.props.value || ''}
                        />
                    </InputContainer>
                    <FormControlLabel
                        checked={!insurance}
                        classes={{
                            root: classes.radio,
                            label: classes.label,
                        }}
                        control={<Radio color="primary" />}
                        label={label}
                        onChange={this.disableInsurance}
                    />

                    {this.props.hasInsurance && (
                        <Terms
                            modalText={t('modalText2')}
                            modalTitle={t('modalTitle2')}
                            text={t('insuranceInformationDescription')}
                        />
                    )}
                </Content>
            </InsuranceSelectorContainer>
        )
    }
}

InsuranceSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: allowNull(PropTypes.string.isRequired),
}

export default withStyles(styles)(InsuranceSelector)
