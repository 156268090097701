import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import {
    getInsuranceValue,
    getProfileFormValues,
} from '../../../../components/CheckoutProcess'
import { routes } from '../../../../constants'
import actions from '../actions'
import { GtmEvent, HitType, PageType } from '../constants'
import {
    getBoxProduct,
    getInsuranceProduct,
    getProductState,
    readableDuration,
} from '../utils'

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.paymentPageLoaded.toString()),
        mergeMap(() => {
            const { duration, quantity } = getProductState(state$.value)
            const variant = readableDuration(duration)
            const insuranceValue = getInsuranceValue(state$.value)
            const { zip, email, id } = getProfileFormValues(state$.value) || {}
            const products = [getBoxProduct(state$.value)]
            if (insuranceValue) {
                products.push(
                    getInsuranceProduct({
                        quantity,
                        variant,
                        value: insuranceValue,
                    })
                )
            }
            return [
                actions.pushEvent({
                    dataLayerObject: {
                        event: GtmEvent.ECCHP,
                        hitType: HitType.pageView,
                        page: `/${routes.payment}`,
                        pageType: PageType.checkout,
                        userEmail: email,
                        userZip: zip,
                        userId: id ? id + '' : null,
                        ecommerce: {
                            checkout: {
                                actionField: { step: 3 },
                                products,
                            },
                        },
                    },
                }),
            ]
        })
    )
