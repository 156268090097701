import * as R from 'ramda'
import { I18n } from 'react-redux-i18n-lite'
import { createAction, handleActions } from 'redux-actions'
import { initialize } from 'redux-form'
import { combineEpics, ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { mapTo, mergeMap, take, tap } from 'rxjs/operators'
import { globalizeSelectors } from '../../services'
import authorizeGuest from '../../services/placebLogic/authorizeGuest'
import authorizeUser from '../../services/placebLogic/authorizeUser'
import { getUserSession } from '../../services/rest'

const tPrefix = 'actions'

export const loginSuccess = createAction('LOGIN_SUCCESS')
export const loginError = createAction('LOGIN_ERROR')
export const setLoggedUser = createAction('SET_LOGGED_USER')

const sessionRefreshed = createAction('SESSION_REFRESHED')

export const authorizeAsGuest = createAction('AUTHORIZE_AS_GUEST')
export const guestAuthorized = createAction('GUEST_AUTHORIZED')
export const guestAuthorizationError = createAction('GUEST_AUTHORIZATION_ERROR')
export const loginUser = createAction('LOGIN_USER')
export const logoutUser = createAction('LOGOUT_USER')
export const refreshUserSession = createAction('REFRESH_USER_SESSION')

const defaultValue = {
    isLoginProcessPending: false,
    isUserLoggedIn: false,
    loggedUser: null,
}

const globalize = globalizeSelectors(R.prop('auth'))

const _reducer = handleActions(
    {
        [loginSuccess]: (state, { payload: { user } }) => ({
            isLoginProcessPending: false,
            isUserLoggedIn: true,
            loggedUser: user,
        }),
        [loginUser]: R.mergeDeepLeft({ isLoginProcessPending: true }),
        [loginError]: R.mergeDeepLeft({ isLoginProcessPending: false }),
        [logoutUser]: () => ({
            isUserLoggedIn: false,
            loggedUser: null,
            isLoginProcessPending: false,
        }),
        [setLoggedUser]: (state, action) => ({
            loggedUser: action.payload.user,
        }),
        [sessionRefreshed]: (state, action) => ({
            loggedUser: action.payload.user,
        }),
    },
    defaultValue
)

const loginUserEpic = action$ =>
    action$.pipe(
        ofType(loginUser.toString()),
        mergeMap(({ payload: { email, password, token } }) =>
            Observable.create(async observer => {
                try {
                    const credentials = {
                        username: email,
                        password,
                    }

                    const user = await authorizeUser({ credentials, token })
                    observer.next(loginSuccess({ user }))
                    observer.next(setLoggedUser({ user }))
                    observer.next(initialize('profileForm', user))
                } catch (error) {
                    if (email) {
                        const errorText = I18n.t(`${tPrefix}.wrongCreds`)
                        alert(errorText)
                    }
                    observer.next(loginError())
                    throw error
                }
            })
        )
    )
export const refreshUserSessionEpic = action$ =>
    action$.pipe(
        ofType(refreshUserSession.toString()),
        mergeMap(() => {
            return Observable.create(async observer => {
                try {
                    const rawToken = global.localStorage.getItem('token')

                    if (typeof rawToken !== 'string') {
                        console.log('Cant refresh session')
                        return
                    }

                    const token = JSON.parse(rawToken)
                    token && (await authorizeUser({ token }))
                    const { user } = getUserSession()
                    observer.next(sessionRefreshed({ user }))
                } catch (e) {
                    console.log(e)
                }
            })
        })
    )

export const logoutUserEpic = action$ =>
    action$.pipe(
        ofType(logoutUser.toString()),
        tap(() => window.localStorage.setItem('token', null))
    )

export const authorizeGuestEpic = action$ =>
    action$.pipe(
        ofType(authorizeAsGuest.toString()),
        take(1),
        mergeMap(async () => {
            try {
                await authorizeGuest()
                return guestAuthorized()
            } catch (error) {
                return guestAuthorizationError({ error })
            }
        })
    )

export const guestAuthorizedEpic = action$ =>
    action$.pipe(
        ofType(guestAuthorized.toString()),
        mapTo(refreshUserSession())
    )

export const epic = combineEpics(
    guestAuthorizedEpic,
    authorizeGuestEpic,
    loginUserEpic,
    logoutUserEpic,
    refreshUserSessionEpic
)

const _getLoggedUserProfile = R.prop('loggedUser')
export const getLoggedUserProfile = globalize(_getLoggedUserProfile)
const _getIsLoginProcessPending = R.prop('isLoginProcessPending')
export const getIsLoginProcessPending = globalize(_getIsLoginProcessPending)
export const reducer = { auth: _reducer }
