import * as React from 'react'
import styled from 'styled-components'
import { SelectorList } from '../../components/commonComponents'
import AddressItem from './AddressItem'
import Header from './Header'
import UserPositionItem from './UserPositionItem'
// import type { PlacebLocation, Suggestion, Position } from '../../../../types';
//import ScrollbarWrapper from "react-scrollbar";

const Root = styled.div`
    /*outline:none*/
`
const iosDevice =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

// type Props = {
//   locations: Array<PlacebLocation>,
//   onChange: Position => void,
//   onClose: void => void,
//   onInputChange: string => void,
//   suggestions: Array<Suggestion>,
//   inputValue: string,
//   className?: string,
//   userGeolocation: Position,
// };
// type State = { show: boolean };
class LocationSelectorScreen extends React.Component /*<Props, State>*/ {
    state = { show: false }
    renderItem = (item /*: Position*/, index /*: number*/) /*: React.Node*/ => (
        <AddressItem
            key={index}
            item={item}
            onClick={() => this.props.onChange(item)}
        />
    )
    renderUserPosition = (item /*: Position*/) => (
        <UserPositionItem
            item={item}
            onClick={() => this.props.onChange(item)}
        />
    )
    showList = () => this.setState(() => ({ show: true }))

    render() {
        const {
            locations,
            className,
            suggestions,
            userGeolocation,
            onInputChange,
            inputValue,
            onClose,
        } = this.props
        const { show } = this.state
        const locationList = [...suggestions, ...locations].map(this.renderItem)
        const userPositionNode =
            userGeolocation && this.renderUserPosition(userGeolocation)
        return (
            <Root className={className}>
                <Header
                    inputValue={inputValue}
                    onChangeInputValue={onInputChange}
                    onCrossButtonClick={onClose}
                    onFocus={this.showList}
                />
                {iosDevice && (
                    <SelectorList show={show} style={{ overflow: 'auto' }}>
                        {userPositionNode}
                        {locationList}
                    </SelectorList>
                )}
                {!iosDevice && (
                    <SelectorList className={'scrollable-block'} show={show}>
                        {userPositionNode}
                        {locationList}
                    </SelectorList>
                )}
            </Root>
        )
    }
}

export default LocationSelectorScreen
