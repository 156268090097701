import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { ButtonMain } from './ButtonMain'
import { desktopWidth } from '../../config'

const Footer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 1px 0 0 #dddddd;
    background-color: white;
`

const MaxWidthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;

    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const extendedBreakpoint = 1250
const PriceSmallContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    height: 100%;

    @media (min-width: ${extendedBreakpoint - 1}px) {
        display: none;
    }
`

const PriceLargeContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    margin-right: 5px;
    height: 100%;

    @media (max-width: ${extendedBreakpoint}px) {
        display: none;
    }
`

const BoldSpan = styled.span`
    font-weight: bold;
`

const Small = styled.div`
    margin-top: 8px;
    font-size: 12px;
`

const SmallSpan = styled.span`
    font-size: 12px;
`

const PriceHint = styled.div`
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.54);

    @media (min-width: ${extendedBreakpoint}px) {
        margin-top: 5px;
    }
`

const PricePriceAfterExtended = styled.div`
    margin-top: 5px;
`

const Bold = styled.div`
    font-weight: bold;
    margin-bottom: 5px;

    @media (min-width: ${desktopWidth}px) {
        font-size: 18px;
    }

    @media (max-width: 360px) {
        font-size: 14px;
    }
`

const Regular = styled.div`
    margin-bottom: 5px;

    @media (min-width: ${desktopWidth}px) {
        font-size: 18px;
    }

    @media (max-width: 360px) {
        font-size: 14px;
    }
`

const FooterButton = styled(ButtonMain)`
    flex: 1;
    border-radius: 2px;

    @media (max-width: ${desktopWidth}px) {
        padding: 0;
        font-size: 16px;
    }
`

const PriceFooter = props => (
    <Footer className={props.className} relative={props.relative}>
        <MaxWidthWrapper>
            <PriceSmallContainer>
                <Regular>{props.pricingTexts.unitDurationLabel} <SmallSpan>({props.pricingTexts.vat})</SmallSpan>:</Regular>
                <Bold>{props.pricingTexts.priceLabel}</Bold>
                {props.pricingTexts.priceLabelAfter && (
                    <Regular>{props.pricingTexts.priceLabelAfter}</Regular>
                )}
                {props.pricingTexts.priceHint && (
                    <PriceHint>{props.pricingTexts.priceHint}</PriceHint>
                )}
            </PriceSmallContainer>
            <PriceLargeContainer>
                <div>
                    <BoldSpan>{props.pricingTexts.pricePriceExtended}</BoldSpan>
                </div>
                <Small>{props.pricingTexts.priceLabelExtended}</Small>
                {props.pricingTexts.pricePriceAfterExtended && (
                    <PricePriceAfterExtended>
                        <span>
                            {props.pricingTexts.priceLabelAfterExtended}
                        </span>
                        &nbsp;
                        <span>
                            {props.pricingTexts.pricePriceAfterExtended}
                        </span>
                    </PricePriceAfterExtended>
                )}
                {props.pricingTexts.priceHint && (
                    <PriceHint>{props.pricingTexts.priceHint}</PriceHint>
                )}
            </PriceLargeContainer>
            <FooterButton onClick={props.onClick}>
                {props.buttonTitle}
            </FooterButton>
        </MaxWidthWrapper>
    </Footer>
)

PriceFooter.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    relative: PropTypes.bool,
    pricingTexts: PropTypes.object,
}

export default PriceFooter
