import React from 'react'
import OverlayView from './OverlayView'
import styled from 'styled-components'

const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
})
const Circle = styled.div`
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
`
const RangeCircle = styled(Circle)`
    background-color: rgba(99, 167, 199, 0.4);
    width: 65px;
    height: 65px;
`

const BlueCircle = styled(Circle)`
    background-color: #379dcd;
    width: 17px;
    height: 17px;
    border: 3px solid white;
`

class UserLocationMarker extends React.Component {
    render() {
        const { position } = this.props

        return (
            <OverlayView
                getPixelPositionOffset={getPixelPositionOffset}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={position}
            >
                <RangeCircle>
                    <BlueCircle />
                </RangeCircle>
            </OverlayView>
        )
    }
}

export default UserLocationMarker
