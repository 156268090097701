import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React from 'react'

const defaultErrorText = "This field can't be empty"

const Input = ({
    className,
    title,
    onClick,
    onChange,
    errorText,
    helperText,
    value,
    error,
    disabled,
    type,
    onBlur,
    onFocus,
    inputProps = {},
}) => {
    const _helperText = error
        ? errorText || defaultErrorText
        : helperText
        ? helperText
        : null

    return (
        <TextField
            autofill={type}
            className={className}
            disabled={disabled}
            error={error}
            helperText={_helperText}
            InputProps={inputProps}
            onBlur={onBlur}
            onChange={e => onChange(e.target.value)}
            onClick={onClick}
            onFocus={onFocus || onClick}
            placeholder={title}
            type={type}
            value={value}
            fullWidth
        />
    )
}

export default Input
