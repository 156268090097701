import * as cheaperNotificationDuck from './cheaperNotificationDuck'

const { moduleName, default: _reducer } = cheaperNotificationDuck
export const reducer = { [moduleName]: _reducer }
export { default } from './CheaperNotificationContainer'
export {
    hideCheaperNotification,
    isCheaperNotificationVisible,
    showCheaperNotification,
} from './cheaperNotificationDuck'
export const epic = cheaperNotificationDuck.epic
