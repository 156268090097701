import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSelectedCategory } from '../../../redux/modules/productDuck'
// import type { Action } from '../../../types'
import { showCheaperBoxDetails } from '../CheaperModal'

import CheaperNotification from './CheaperNotification'
import {
    hideCheaperNotification,
    isCheaperNotificationVisible,
} from './cheaperNotificationDuck'

const mapStateToProps = state => ({
    category: getSelectedCategory(state),
    isVisible: isCheaperNotificationVisible(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            onNotificationClose: hideCheaperNotification,
            onNotificationConfirm: showCheaperBoxDetails,
        },
        dispatch
    )

const CheaperNotificationContainer = (
    props /*: { isVisible: boolean } & Props*/
) => (props.isVisible ? <CheaperNotification {...props} /> : null)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheaperNotificationContainer)
