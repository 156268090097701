import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import duck from '../duck'

const tPrefix = 'checkoutProcess.payment'

const Content = styled.div`
    a {
        text-decoration: none;
        color: #80c700;
        font-weight: bold;
        font-family: Helvetica, sans-serif;
        cursor: pointer;
    }
    p {
        margin-bottom: 10px;
    }
`

class RatingModal extends React.Component {
    render() {
        const { isVisible, onClose } = this.props
        return (
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                onClose={onClose}
                open={isVisible}
            >
                <DialogTitle id="alert-dialog-title">
                    {I18n.t(`${tPrefix}.moreInfo`)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: I18n.t(`${tPrefix}.ratingInformation`),
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        {I18n.t(`${tPrefix}.close`)}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    isVisible: duck.selectors.getIsRatingModalVisible(state),
})
const mapDispatchToProps = {
    onClose: duck.actions.hideRatingModal,
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(RatingModal)
