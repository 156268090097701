import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'

const tPrefix = 'checkoutProcess.payment'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-left: -17px;
`

const StyledToc = styled.span`
    a {
        text-decoration: none;
        color: #80c700;
        font-weight: bold;
        font-family: Helvetica, sans-serif;
        cursor: pointer;
    }
`

const StyledErrorToc = styled.span`
    color: #f44336;
    a {
        color: #f44336;
    }
`

const ErrorField = styled.div`
    color: #f44336;
    font-size: 0.75rem;
`

const AcceptTermsCheckbox = ({ checked, onClick, error }) => {
    const TocContainer = error ? StyledErrorToc : StyledToc
    const label = (
        <TocContainer dangerouslySetInnerHTML={{ __html: t('acceptTerms') }} />
    )

    return (
        <>
            <StyledFormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        color={'primary'}
                        onClick={onClick}
                    />
                }
                label={label}
            />
            {error && <ErrorField>{error && t('termsRequired')}</ErrorField>}
        </>
    )
}

export default AcceptTermsCheckbox
