import Arrow from '@material-ui/icons/KeyboardArrowLeft'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../components/FlexContainer'
import Stepper from './Stepper'

const Root = styled(FlexContainer)`
    background-color: #4a4a4a;
    box-shadow: 0 1px 0 0 #c0c0c0;
`

const MaxWidthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding-right: 20px;

    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const IconWrapper = styled(Arrow).attrs({ color: 'primary' })`
    display: flex;
    cursor: pointer;
    padding: 15px;
`

const Title = styled.div`
    flex: 1;
    margin-right: 15px;
    color: white;
    font-family: Helvetica, serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
`

const Header = ({ step, title, onButtonClick, className }) => (
    <Root className={className}>
        <MaxWidthWrapper>
            <IconWrapper onClick={onButtonClick} />
            <Title>{title}</Title>
            <Stepper stepNumber={3} value={step - 1} />
        </MaxWidthWrapper>
    </Root>
)

Header.propTypes = {
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}
export default Header
