import { combineEpics } from 'redux-observable'
import { epic as checkoutEpic } from './checkoutDuck'
import * as Payment from './Payment'
import { default as _translations } from './translations'

export { getProfileFormValues } from './UserDetails'
export const translations = { checkoutProcess: _translations }
export {
    reducer,
    getStartDate,
    getInsuranceValue,
    openBooking,
    paymentConfirmed,
    getOrderedFlag,
    isPayByCard,
    getDiscount,
    getIsReservationPending,
} from './checkoutDuck'
export { default as Booking } from './Booking'
export { default as UserDetails } from './UserDetails'
export { default as Payment } from './Payment'
export { default as BookingSuccess } from './BookingSuccess'
export { default as moduleName } from './moduleName'

export const epic = combineEpics(Payment.epic, checkoutEpic)
