import * as R from 'ramda'

export default ({ inner }) => {
    let result = {}
    if (inner) {
        R.forEach(
            a => (result = R.assocPath(a.path.split('.'), a.message, result)),
            inner
        )
    }
    return result
}
