import React from 'react'
import styled, { css } from 'styled-components'
import PlaceBTheme from '../../../../styles/PlacebTheme'
import { ListItem, rangeItemTitle } from '../../components/commonComponents'

const mainColor = PlaceBTheme.palette.primary.main

const Root = styled(ListItem)`
    padding-left: 30px;
    ${props =>
        props.active &&
        css`
            color: ${mainColor};
        `};
    cursor: pointer;
`
// type Props = {
//   item: ?number,
//   active: boolean,
//   onClick: (?number) => void,
// };

const RadiusItem = ({ active, onClick, item } /*: Props*/) => (
    <Root key={item} active={active} onClick={onClick}>
        {rangeItemTitle`${item}`}
    </Root>
)
export default RadiusItem
