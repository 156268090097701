// noinspection SpellCheckingInspection
export default {
    GEALL: 'GE-ALL',
    ECFLO: 'EC-FLO',
    ECFCH: 'EC-FCH',
    ECFAD: 'EC-FAD',
    ECCHB: 'EC-CHB',
    ECCHP: 'EC-CHP',
    ECCHA: 'EC-CHA',
    ECORD: 'EC-ORD',
    ECCHC: 'EC-CHC',
}
