import booking from '../Booking/translations/de'
import bookingSuccess from '../BookingSuccess/translations/de'
import payment from '../Payment/translations/de'
import userDetails from '../UserDetails/translations/de'
import account from '../Account/translations/de'

export default {
    booking,
    bookingSuccess,
    userDetails,
    payment,
    account,
}
