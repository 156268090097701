import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getSelectedCategoryObject,
    getSelectedCategoryType,
    userChangedSizeType,
} from '../../../redux/modules/productDuck'
import SizeTypePicker from './SizeTypePicker'

const mapStateToProps = state => ({
    value: getSelectedCategoryType(state),
    category: getSelectedCategoryObject(state),
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { onChange: sizeType => userChangedSizeType({ sizeType }) },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SizeTypePicker)
