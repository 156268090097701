export default {
    successLabel: 'Thank you! You have successfully booked your box.',
    nextSteps: 'Next steps',
    installMobileApp: 'Install the placeB app on your smartphone',
    verifyEmail: 'Verify your email',
    verifyPhone: 'Verify your phone',
    personalInformation: 'Personal Information',
    bookedBoxes: 'Booked boxes',
    install: 'Install the placeB mobile app',
    installSubheader: 'Get your key now and benefit from all features',
    myBoxes: 'My boxes',
}
