import { connect } from 'react-redux'
import { openBoxFinder } from '../../../redux/modules/productDuck'
import {
    getLastReservation,
    getStartDate,
    openUserDetails,
} from '../checkoutDuck'
import { getProfileFormValues } from '../UserDetails'
import BookingSuccess from './BookingSuccess'

const mapStateToProps = state => ({
    ...getLastReservation(state),
    startDate: getStartDate(state),
    userInformation: getProfileFormValues(state) || {},
    openBoxFinder,
    openUserDetails,
})

export default connect(mapStateToProps)(BookingSuccess)
