import React from 'react'
import { rangeItemTitle } from '../../components/commonComponents'
import StaticInput from '../../components/StaticInput'
import RadiusIcon from './radius-icn.svg'

// type Props = {
//   onClick: void => void,
//   value: string,
//   className?: string,
// }
const RangeSelector = ({ value, onClick, className } /*: Props*/) => (
    <StaticInput
        className={className}
        icon={RadiusIcon}
        onClick={onClick}
        value={rangeItemTitle`${value}`}
    />
)

export default RangeSelector
