import * as R from 'ramda'
import { handleActions } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { delay, mergeMap } from 'rxjs/operators'
import { actionCreator, globalizeSelectors } from '../../../services'
import { getSuggestions } from '../../../services/Geocoding'

const moduleName = 'locationSelector'
const defaultValue = {
    suggestions: [],
    showLocationSelector: false,
    selectedPosition: null,
}
const createAction = actionCreator(moduleName)
export const setPlaceSuggestions = createAction('SET_PLACE_SUGGESTIONS')
export const setPosition = createAction('SET_POSITION')
export const changePosition = createAction('CHANGE_POSITION')
export const userChangedPosition = createAction(
    'USER_CHANGED_POSITION',
    R.objOf('position')
)
export const loadSuggestions = createAction(
    'LOAD_SUGGESTIONS',
    R.objOf('criteria')
)
export const hideLocationSelector = createAction(
    'HIDE_LOCATION_SELECTOR',
    () => null
)
export const showLocationSelector = createAction(
    'SHOW_LOCATION_SELECTOR',
    () => null
)

export default handleActions(
    {
        [setPlaceSuggestions.toString()]: (
            state,
            { payload: { suggestions } }
        ) => ({ ...state, suggestions }),
        [setPosition.toString()]: (
            state,
            { payload: { position, byUser } }
        ) => ({
            ...state,
            selectedPosition: position,
            ...(byUser ? { positionChangedByUser: true } : {}),
        }),
        [hideLocationSelector.toString()]: R.mergeDeepLeft({
            showLocationSelector: false,
        }),
        [showLocationSelector.toString()]: R.mergeDeepLeft({
            showLocationSelector: true,
        }),
    },
    defaultValue
)

const globalize = globalizeSelectors(R.prop('locationSelector'))

const _getPlaceSuggestions = R.prop('suggestions')
const _getSelectedPosition = R.prop('selectedPosition')
const _getShowLocationSelectorFlag = R.prop('showLocationSelector')
export const getPlaceSuggestions = globalize(_getPlaceSuggestions)
export const getSelectedPosition = globalize(_getSelectedPosition)
export const getShowLocationSelectorFlag = globalize(
    _getShowLocationSelectorFlag
)
const userChangedPositionEpic = action$ =>
    action$.pipe(
        ofType(userChangedPosition.toString()),
        delay(10),
        mergeMap(({ payload: { position } }) => [
            changePosition({ position, setLocation: true, byUser: true }),
        ])
    )
const loadSuggestionsEpic = action$ =>
    action$.pipe(
        ofType(loadSuggestions.toString()),
        mergeMap(async ({ payload: { criteria } }) => {
            if (criteria.length < 1) {
                return setPlaceSuggestions({ suggestions: [] })
            }
            const res = await getSuggestions(criteria)
            const suggestions = res.map(item => ({
                name: item.description,
                id: item.id,
                place_id: item.place_id,
                isSuggestion: true,
            }))
            return setPlaceSuggestions({ suggestions })
        })
    )

export const epic = combineEpics(userChangedPositionEpic, loadSuggestionsEpic)
