import LocationOn from '@material-ui/icons/LocationOn'
import React from 'react'
import styled from 'styled-components'
import { NoWrapText } from '../components/commonComponents'
// import type { PlacebLocation } from '../../../types';
const Root = styled.div`
    outline: none;
    display: flex !important;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center !important;
    height: 50px;
    border: 1px solid #dddddd;
    border-right-width: 0;
    div.slick-slide:last-child > div > & {
        border-right-width: 1px;
    }

    flex-shrink: 0;
    padding-right: 5px;
    text-align: center;
    transition: background-color 0.5s ease 0.1s;
    background-color: ${props => (!props.active ? '#F6F6F6' : 'white')};
    color: #39393a;
    cursor: pointer;
`
const Address = styled(NoWrapText)`
    font-size: 14px;
    font-weight: bold;
    width: 100%;
`
const City = styled(NoWrapText)`
    margin-top: 2px;
    font-size: 13px;
    width: 100%;
    text-align: left;
`
const LocationOnWrapper = styled(LocationOn)`
    margin: 5px;
`
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;
`
// type Props = {
//   className: string,
//   item: PlacebLocation,
//   active: boolean,
// };
const LocationItem = (props /*: Props*/) => (
    <Root {...props}>
        <LocationOnWrapper
            color={props.active ? 'primary' : 'secondary'}
            style={{ height: 25, flexShrink: 0 }}
        />
        <Right>
            <Address>{props.item.street}</Address>
            <City>{props.item.city}</City>
        </Right>
    </Root>
)
export default LocationItem
