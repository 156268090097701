import PropTypes from 'prop-types'
import React from 'react'
import LoginButton from './LoginButton'
import LoginModal from './LoginModal'

class Login extends React.Component {
    constructor() {
        super()
        this.state = {
            show: false,
        }
    }
    showModal = () => {
        this.setState(() => ({ show: true }))
    }
    hideModal = () => {
        this.setState(() => ({ show: false }))
    }
    render() {
        const { show } = this.state
        const { login } = this.props
        return (
            <React.Fragment>
                <LoginButton onClick={this.showModal} />
                <LoginModal
                    onClose={this.hideModal}
                    onConfirm={login}
                    show={show}
                />
            </React.Fragment>
        )
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
}
export default Login
