import React from 'react'
import styled from 'styled-components'
import LocationMap from '../LocationMap'
import ConfirmButton from './components/ConfirmButton'
import Header from './components/Header'
import ShowMyLocationButton from './components/ShowMyLocationButton'
// import type { PlacebLocation, Position } from '../../../types';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const ConfirmButtonWrapper = styled(ConfirmButton)`
    display: flex;
`

const BottomLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    background: transparent;
    width: 50%;
`

const ShowMyLocationButtonWrapper = styled(ShowMyLocationButton)`
    margin: 0 20px 20px;
`
// export type Props = {
//   userPosition: Position,
//   onClose: void => any,
//   selectedLocation: PlacebLocation,
//   goToUserLocation: void => any,
// };
const FullMapScreen = (
    { userPosition, onClose, selectedLocation, goToUserLocation } /*: Props*/
) => {
    return (
        <Root>
            <Header location={selectedLocation} onBackButtonClick={onClose} />
            <BottomLine>
                <ConfirmButtonWrapper onClick={onClose} />
                {userPosition && (
                    <ShowMyLocationButtonWrapper onClick={goToUserLocation} />
                )}
            </BottomLine>
            <LocationMap />
        </Root>
    )
}
export default FullMapScreen
