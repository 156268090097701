import { actionCreator } from '../../../services'
import moduleName from './moduleName'

const createAction = actionCreator(moduleName)
const pushEvent = createAction('PUSH_EVENT')
const pageLoaded = createAction('PAGE_LOADED')
const boxFinderLoaded = createAction('BOX_FINDER_LOADED')
const productChanged = createAction('PRODUCT_CHANGED')
const boxSelected = createAction('BOX_SELECTED')
const bookingPageLoaded = createAction('BOOKING_PAGE_LOADED')
const userDetailsPageLoaded = createAction('USER_DETAILS_PAGE_LOADED')
const paymentPageLoaded = createAction('PAYMENT_PAGE_LOADED')
const confirmPayment = createAction('PAYMENT_CONFIRMED')
const orderCompleted = createAction('ORDER_COMPLETED')
const formFieldInputError = createAction('FORM_FIELD_INPUT_ERROR')
const formFieldInputChange = createAction('FORM_FIELD_INPUT_CHANGE')
const userLogin = createAction('USER_LOGIN')

export default {
    pushEvent,
    pageLoaded,
    boxFinderLoaded,
    productChanged,
    boxSelected,
    bookingPageLoaded,
    userDetailsPageLoaded,
    paymentPageLoaded,
    confirmPayment,
    orderCompleted,
    formFieldInputError,
    formFieldInputChange,
    userLogin,
}
