import Calendar from '@material-ui/icons/Today'
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import React from 'react'
import styled from 'styled-components'
import { withLocale } from '../../../../core/ApplicationContext'
import { createTranslator } from '../../../../services'
import InformationItem from './InformationItem'

const localeMap = {
    en: enLocale,
    de: deLocale,
    fr: frLocale,
}

const t = createTranslator('checkoutProcess.booking')
const CalendarIcon = styled(Calendar)`
    color: #80c700 !important;
    height: 24px;
    width: 24px;
`
const InformationItemWrapper = styled(InformationItem).attrs({
    icon: <CalendarIcon />,
})`
    display: flex;
    flex: 2;
`
// type Props = {
//     startDate: Date
//     className: string
//     locale: string
// }
const BoxStartDate = ({ startDate, className, locale } /*: Props*/) => {
    const date = format(startDate, 'dd. MMMM yyyy', {
        locale: localeMap[locale],
    })
    return (
        <InformationItemWrapper
            className={className}
            title={t('startDate')}
            text={date}
        />
    )
}

export default withLocale(BoxStartDate)
