import { ofType } from 'redux-observable'
import { combineLatest } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { presetValues } from '../../productDuck'
import actions from '../actions'
import { GtmEvent, HitType } from '../constants'
import { getBoxFinderLoadedDataObject } from '../utils'

export default (action$, state$) =>
    combineLatest(
        action$.pipe(ofType(actions.boxFinderLoaded.toString())),
        action$.pipe(ofType(presetValues.toString()))
    ).pipe(
        mergeMap(() => [
            actions.pushEvent({
                dataLayerObject: {
                    hitType: HitType.pageView,
                    event: GtmEvent.ECFLO,
                    ...getBoxFinderLoadedDataObject(state$.value),
                },
            }),
        ])
    )
