import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import PlaceBTheme from '../../../styles/PlacebTheme'
// import type { PlacebCategory } from '../../../types';

const tPrefix = 'boxFinder.dimensions'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const mainColor = PlaceBTheme.palette.primary.main

const Font = styled.div`
    font-size: 16px;
`
const Bold = styled(Font)`
    font-weight: bold;
`
const Name = styled(Bold)`
    //color: ${mainColor};
    font-weight: normal;
`
const Unit = styled(Font)`
    margin-left: 5px;
`

const Root = styled.div`
    position: absolute;
    bottom: 20px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    right: 0px;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        div[tabindex='1'] & {
            display: inline-flex;
            //top: 43%;
        }
    }
`
const Column = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
`
const NamesColumn = styled(Column)`
    width: 18px;
`
// type Props = {
//   category: PlacebCategory,
//   className?: string,
// };
const Dimensions = ({ category, className } /*: Props*/) => {
    let { height, width, depth } = category
    height = (Math.round(height / 100) / 10).toFixed(1)
    width = (Math.round(width / 100) / 10).toFixed(1)
    depth = (Math.round(depth / 100) / 10).toFixed(1)
    return (
        <Root className={className}>
            <NamesColumn>
                <Name>{t('d')}</Name>
                <Name>{t('w')}</Name>
                <Name>{t('h')}</Name>
            </NamesColumn>
            <Column>
                <Bold>{depth}</Bold>
                <Bold>{width}</Bold>
                <Bold>{height}</Bold>
            </Column>
            <Column>
                <Unit>m</Unit>
                <Unit>m</Unit>
                <Unit>m</Unit>
            </Column>
        </Root>
    )
}

export default Dimensions
