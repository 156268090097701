import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
    margin: 0;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 24px;
`

class PersonalInformation extends React.Component {
    render() {
        const { userInformation, className } = this.props
        return (
            <div className={className}>
                <Text>
                    {userInformation.firstName} {userInformation.lastName}
                </Text>
                <Text>{userInformation.organization}</Text>
                <Text>{userInformation.email}</Text>
                <Text>{userInformation.phone}</Text>
                <Text>{userInformation.street}</Text>
                <Text>
                    {userInformation.zip} {userInformation.city}
                </Text>
            </div>
        )
    }
}
export default PersonalInformation
