import boxFinder from '../components/BoxFinder/translations/de'
import checkoutProcess from '../components/CheckoutProcess/translations/fr'

export default {
    boxFinder,
    checkoutProcess,
    bookingPage: {
        header: {
            step1: 'Étape 1 : Réserver',
            step2: 'Étape 2 : Tes détails',
            step3: 'Étape 3 : Paiement',
        },
        step1: {
            insuranceModal: {
                title: `Es-tu sûr que tu n'as pas besoin d'une assurance ?`,
                description: `Une assurance contre le vol, l'incendie et les dégâts des eaux est obligatoire. Tu peux contacter ton assurance habitation ou t'assurer chez nous. Les coûts sont de 6 CHF par mois par valeur de marchandise de 10’000 CHF`,
                yes: 'Oui',
                no: 'Non',
            },
            startDate: 'Choisir la date de début',
            requiredFieldError: 'Ce champ est obligatoire',
            minimumTerm: 'Durée minimum',
            insurance: 'Assurance',
            insuranceDescription:
                'Les frais sont de CHF 6 par mois par CHF 10’000 valeur des marchandises',
            confirmInsurance: 'Oui, je souhaite une assurance (recommandé)',
            boxValue: 'Valeur de tes marchandises (CHF)',
            disableInsurance: `Non, j'ai une assurance et je ne ferai pas de réclamation auprès de placeB en cas de dommage.`,
            nextStep: 'Prochaine étape',
            ok: 'OK',
            cancel: 'Annuler',
            durations: {
                day: 'Jour',
                week: 'Semaine',
                month: 'Mois',
                year: 'Année',
            },
        },
        step2: {
            loginModal: {
                login: 'Se connecter',
                cancel: 'Annuler',
                email: 'E-Mail',
                password: 'Mot de passe',
                emailError: 'E-mail erroné',
                passwordError: 'Le mot de passe est trop court',
            },
            passwordModal: {
                login: 'Se connecter',
                cancel: 'Annuler',
                choosePassword: 'Choisissez votre nouveau mot de passe',
                password: 'Mot de passe',
                email: 'E-Mail',
                passwordError: 'Le mot de passe est trop court',
                newPasswordPropose: `Pour pouvoir utiliser l'app placeB, tu as besoin d'un mot de passe personnel. Crées-en un ici et notes-le bien.`,
            },
            requiredFieldError: 'Ce champ est obligatoire',
            loginButton: 'As-tu déjà un compte ?',
            name: 'Nom',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            company: 'Société (optionnel)',
            country: 'Pays',
            email: 'E-Mail',
            address: 'Adresse',
            address2: `Complément d'adresse (optionnel)`,
            zip: 'Code postal',
            city: 'Ville',
            phone: 'Téléphone mobile',
            nextStep: 'Prochaine étape',
            confirm: 'Prochaine étape',
        },
        step3: {
            edit: 'Modifier',
            invoice: 'Facture (+ CHF 5 / facture)',
            accept: `Oui, j'accepte les conditions générales`,
            confirm: 'Réserver maintenant',
            paymentOptions: 'Options de paiement',
            couponCode: 'Code promo',
            doYouHaveCode: 'As-tu un code promo ?',
            incorrectValue: 'Valeur invalide',
            acceptTerms: `Oui, j'accepte les conditions générales de vente`,
        },
        bookingSuccess: {
            successLabel:
                'Merci ! Tu as réservé ta box avec succès. Voici les prochaines étapes :',
            verifyEmail: `Vérifier l'e-mail`,
            installMobileApp: `Installer l'application placeB sur le téléphone mobile`,
            openYourBox: 'Ouvres la box. Mais pas avant de se trouver devant !',
            bookedBox: 'Boxes réservées',
            installPropose: `Installes l'application placeB pour profiter de tous ses avantages`,
            personalInformation: 'Informations',
            verified: 'Confirmé',
            editInfo: 'Modifier les informations',
            openBox: 'Ouvrir la box',
            openBuilding: 'Ouvrir le bâtiment',
            viewDetails: 'Afficher les détails',
            mobileApp: 'Application mobile',
            youHaventInstall: `Tu n'as pas encore installé l'application sur ton téléphone !`,
            verifyPhone: 'Vérification du numéro de téléphone',
            feedbackForm:
                'https://docs.google.com/forms/d/e/1FAIpQLSdNPH_zdK_4GXwH7TS_yAYFRiuqKnanX-DCI4-W0vDR8f95Jg',
        },
        durationSelect: {
            perMonth: 'par mois',
            perWeek: 'par semaine',
            perDay: 'par jour',
            ourBestseller: 'Notre best-seller',
            durations: {
                day: 'Jour',
                days: 'Jours',
                week: 'Semaine',
                weeks: 'Semaines',
                month: 'Mois',
                months: 'Mois',
                year: 'Année',
                years: 'Années',
            },
        },
        summaryRibbon: {
            insurance: 'Assurance',
            invoice: 'Facture (+ CHF 5 / facture)',
            total: 'Total',
            price: '%{price} CHF / %{unit}',
            priceMonth: '%{price} CHF / Mois',
            month: 'Mois',
            year: 'Année',
            week: 'Semaine',
            day: 'Jour',
            bill: 'Facture',
        },
    },
    filter: {
        volume: 'Volumes',
        price: '%{price} CHF / %{unit}',
        sizeUnit: 'Taille en m³',
        categoryItem: {
            priceString: 'À partir de %{minPrice} CHF / Mois',
        },
        searchPage: {
            hintText: 'Saisis ton code postal ou ta ville',
        },
    },
    boxItem: {
        volumeString: 'Volume : %{volume} m³',
        areaString: 'Surface : %{square} m²',
    },
    categoryDetails: {
        priceString: 'À partir de %{minPrice} CHF / Mois',
        squareString: 'Surface : %{square} m²',
        volumeString: 'Fläche: %{volume} m³',
        sizeString:
            'Taille : %{depth} %{sizeUnits} × %{width}%{sizeUnits} × %{height} %{sizeUnits} (L×L×H)',
        aboutTheLocation: `À propos de l'emplacement`,
        bookNow: 'Réserver maintenant',
        wantCharged: `Il n'y a pas encore de frais à payer`,
        benefits: {
            a: 'Accès 24/7',
            b: 'Sans clé',
            c: 'Vidéosurveillance',
        },
        price: '%{price} CHF / %{unit}',
        volume: 'Volume',
    },
    userProfile: {
        personalInformation: 'Informations',
        name: 'Nom',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        company: 'Société (optionnel)',
        country: 'Pays',
        email: 'E-Mail',
        address: 'Adresse',
        address2: `Complément d'adresse (optionnel)`,
        zip: 'Code postal',
        city: 'Ville',
        phone: 'Téléphone',
        sendPostcard: `Envoyer la vérification de l'adresse`,
        verified: 'Confirmation',
        saveChanges: 'Enregistrer les modifications',
        requiredFieldError: 'Ce champ est obligatoire',
        resendSms: 'Renvoyer le SMS de vérification',
    },
    autoComplete: {
        popularLocations: 'Emplacements populaires',
    },
    actions: {
        wrongCreds: 'E-mail ou mot de passe erroné',
        registrationFailed: `Échec de l'enregistrement`,
        reservationFailed: "La réservation n'a pas pu être complétée. Cela peut être dû aux raisons suivantes :\n" +
            "• Ton numéro de téléphone ou ton adresse e-mail sont déjà enregistrés chez nous dans le système (tu as éventuellement déjà eu une réservation auparavant ou tu t'es préalablement enregistré chez nous). Dans ce cas, nous te prions de cliquer sur « s'inscrire avec un compte existant » lors de la saisie des données personnelles.\n" +
            "• Ton moyen de paiement n'est pas couvert. Veuillez recharger votre carte de crédit ou choisir un autre moyen de paiement.",
        error: 'Erreur',
        cantSave: 'Impossible de sauvegarder',
        cantOpen: 'Ouverture impossible',
        profileUpdated: 'Modifications acceptées',
        noDoors: 'Pas de portes à ouvrir',
    },
    registration: {
        confirm: `S'enregistrer`,
    },
    auth: {
        error: 'Échec de connexion',
    },
}
