// import type { Action } from 'redux';
import { createAction } from 'redux-actions'

export { default as createTranslator } from './createTranslator'
// export { default as allowNull } from './allowNull'
export { default as globalizeSelectors } from './globalizeSelectors'
export { default as mergeTranslations } from './mergeTranslations'
export { coordsToShort } from './utils'
export const extractFromArray = (array, property) =>
    array.map(item => item[property]).filter(_ => _)

// type ActionCreator = (
//   action: string,
//   payloadCreator?: (any) => any,
//   metaCreator?: (any) => any
// ) => {
//   type: string,
//   payload: Action,
// };

export const actionCreator =
    (moduleName /*: string*/) /*: ActionCreator*/ =>
    (
        action /*: string*/,
        payloadCreator /*?: any => any*/,
        metaCreator /*?: any => any*/
    ) /*: Action*/ =>
        createAction(`${moduleName}/${action}`, payloadCreator, metaCreator)
