import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import actions from '../actions'
import { GtmEvent, PageType } from '../constants'
import HitType from '../constants/HitType'
import { getBoxFinderLoadedDataObject } from '../utils'

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.productChanged.toString()),
        mergeMap(({ payload: { method } }) => [
            actions.pushEvent({
                dataLayerObject: {
                    ...getBoxFinderLoadedDataObject(state$.value),
                    hitType: HitType.event,
                    event: GtmEvent.ECFCH,
                    componentCategory: PageType.boxFinder,
                    componentSubcategory: 'update_product',
                    componentAttribute1: method,
                },
            }),
        ])
    )
