import React from 'react'
import styled from 'styled-components'
// import type { PlacebDuration } from '../../../types';
import { createTranslator } from '../../../services'
import SteppedSlider from '../components/SteppedSlider'

const t = createTranslator('boxFinder')
const Root = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    padding: 21px 21px 30px 21px;
    border: 0 solid #dddddd;
    background-color: #fafafa;
    box-shadow: inset 0 1px 0 0 #dddddd;
    /*@media (max-height: 568px) {
      margin-bottom: 15px;
    }*/
    box-sizing: border-box;
    height: 100px;
`
const Head = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    color: #80c700;
    font-size: 18px;
`

const BoldText = styled.div`
    display: inline-block;
    margin-left: 5px;
    font-weight: 600;
    text-transform: capitalize;
    color: #39393a;
`
const SteppedSliderWrapper = styled(SteppedSlider)`
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

// export type Props = {
//   className?: string,
//   options: Array<PlacebDuration>,
//   value: number,
//   onChange: number => any,
// };
class DurationSlider extends React.Component /*<Props>*/ {
    renderDurationString = () => {
        const { options, value } = this.props
        const selectedDuration = options[value]
        const { duration, unit } = selectedDuration
        const translationPrefix =
            duration > 1 ? 'durationsMultiple' : 'durations'
        return ` ${duration} ${t(`${translationPrefix}.${unit}`)}`
    }
    render() {
        const { options, value, className, onChange } = this.props
        return (
            <Root className={className}>
                <Head>
                    {t('minimumRentalPeriod')}
                    <BoldText>{this.renderDurationString()}</BoldText>
                </Head>
                <SteppedSliderWrapper
                    className={'duration-slider'}
                    label={t('durationLabel')}
                    onChange={onChange}
                    optionsNumber={options.length}
                    value={value}
                />
            </Root>
        )
    }
}

export default DurationSlider
