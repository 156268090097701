import withStyles from '@material-ui/core/styles/withStyles'
import Slider from '@material-ui/lab/Slider'
import range from 'lodash/range'
import React from 'react'
import styled from 'styled-components'
import PlaceBTheme from '../../../styles/PlacebTheme'

const themeColor = PlaceBTheme.palette.primary.main
const VerticalDivider = styled.div`
    height: 15px;
    flex: 1;
    border-left: 2px solid #dddddd;
    &:nth-child(5) {
        border-right: 2px solid #dddddd;
    }
`
const DividerWrapper = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    margin-top: 4px;
    justify-content: center;
    flex: 1;
    width: 100%;
`
const SliderLabel = styled.div`
    position: absolute;
    top: 20px;
    margin-left: -8px;
    display: ${props => (props.hide ? 'none' : 'flex')};
    @media screen and (max-width: 410px) {
        display: none;
    }
`
const SliderLabelLast = styled.div`
    position: absolute;
    display: ${props => (props.hide ? 'none' : 'flex')};
    right: 0;
    top: 20px;
    @media screen and (max-width: 410px) {
        display: none;
    }
`
const Root = styled.div`
    position: relative;
    display: flex;
`

const Dividers = ({ number, value, label }) => (
    <DividerWrapper className={'divider'}>
        {range(0, number - 1).map((item, index) => (
            <VerticalDivider key={index}>
                <SliderLabel className={'divider-label'} hide={value === index}>
                    {label[index]}
                </SliderLabel>
            </VerticalDivider>
        ))}
        <SliderLabelLast hide={value === number - 1}>
            {label[number - 1]}
        </SliderLabelLast>
    </DividerWrapper>
)

const styles = {
    root: {
        flex: 1,
        paddingLeft: 0,
        paddingRight: 0,
        cursor: 'pointer',
        marginTop: '-10px',
        paddingTop: 22,
        paddingBottom: 22,
        touchAction: 'pan-x',
    },
    thumb: {
        backgroundColor: `${themeColor} !important`,
        border: 'none !important',
        width: '24px !important',
        height: '24px !important',
    },
    track: {
        height: 3,
    },
}
// type Props = {
//   optionsNumber: number,
//   value: number,
//   onChange: number => void,
//   className?: string,
//   label: string,
//   classes: Array<string>,
// };

class SteppedSlider extends React.PureComponent /*<Props>*/ {
    onChange = (event, newValue) => this.props.onChange(newValue)

    render() {
        const { optionsNumber, value, className, label, classes } = this.props
        return (
            <Root className={className}>
                <Dividers label={label} number={optionsNumber} value={value} />
                <Slider
                    classes={classes}
                    className={'stepper-slider'}
                    max={optionsNumber - 1}
                    min={0}
                    // disableFocusRipple={true}
                    onChange={this.onChange}
                    step={1}
                    value={value}
                />
            </Root>
        )
    }
}

export default withStyles(styles)(SteppedSlider)
