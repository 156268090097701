import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import Header from '../../components/Header/Header'

const tPrefix = 'checkoutProcess.booking'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const BookingHeader = ({ onButtonClick }) => (
    <Header onButtonClick={onButtonClick} step={1} title={t('header')} />
)

BookingHeader.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
}
export default BookingHeader
