import React from 'react'
import styled from 'styled-components'
import {desktopWidth} from '../../config'
import BoxFinderFooter from './BoxFinderFooter'
import CheaperModalContainer from './CheaperModal'
import NotificationContainer from './CheaperNotification'
import {PreOpenedMessage} from './components'
import DurationSlider from './DurationSlider'
import Header from './Header'
import LocationMapContainer from './LocationMap'
import LocationSelector from './LocationSelector'
import LocationsSlider from './LocationSlider'
import NoBoxesFooter from './NoBoxesFooter'
import RadiusSelector from './RadiusSelector'
import SizePickerContainer from './SizePicker'
import SizeTypeCalculatorPortalContainer from './SizeTypeCalculator'
import SizeTypePickerContainer from './SizeTypePicker'

const Root = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
const SizePickerContainerWrapper = styled(SizePickerContainer)`
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 18px;
    @media (max-height: 568px) {
        margin-bottom: 8px;
    }
`
const FilterWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${desktopWidth}px) {
        width: 50%;
    }
`
const Row = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
`
const LocationMapWrapperContainer = styled(LocationMapContainer)`
    display: flex;
    flex: 1;
    width: 50%;
    @media (max-width: ${Number(desktopWidth) - 1}px) {
        display: none;
    }
`
const Container = styled.div`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 376px;
    padding-bottom: 80px;
`
const LocationSelectorWrapper = styled(LocationSelector)`
    flex: 5;
`
const RadiusSelectorWrapper = styled(RadiusSelector)`
    flex: 3;
`

interface Props {
    isLocationPreopened: boolean
    openMap: () => void
}

const BoxFinder: React.FC<Props> = props => (
    <Root>
        <CheaperModalContainer/>
        <NotificationContainer/>
        <Header openMap={props.openMap}>
            {/* FIXME
            // @ts-ignore */}
            <LocationSelectorWrapper/>
            {/* FIXME
            // @ts-ignore */}
            <RadiusSelectorWrapper/>
        </Header>
        <LocationsSlider/>
        <Row>
            <FilterWrapper>
                <Container>
                    {props.isLocationPreopened && <PreOpenedMessage/>}
                    <SizeTypePickerContainer>
                        <SizeTypeCalculatorPortalContainer/>
                    </SizeTypePickerContainer>
                    <SizePickerContainerWrapper/>
                    <DurationSlider/>
                </Container>
                <BoxFinderFooter/>
                <NoBoxesFooter/>
            </FilterWrapper>
            <LocationMapWrapperContainer/>
        </Row>
    </Root>
)
export default BoxFinder
