import Clear from '@material-ui/icons/Clear'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import ScrollArea from 'react-scrollbar'
import styled from 'styled-components'
import { desktopWidth } from '../../../config'
import BoxSizeTypes from '../../../constants/BoxSizeTypes'
import { ButtonMain } from '../../components/ButtonMain'
import { SizePicker } from '../SizePicker'
import { SizeTypePicker } from '../SizeTypePicker'
// import type { PlacebCategory, PlacebLocation, SizeType } from '../../../types';

const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const Fixed = styled.div`
    touch-action: none;
    position: fixed;
    overflow-x: hidden;
    z-index: 999;
    top: 0;
    bottom: 0;
    background: white;
    flex: 1;
    flex-direction: column;
    display: flex;
    right: 0;
    left: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    @media (min-width: ${desktopWidth}px) {
        top: 10%;
        right: 10%;
        bottom: auto;
        left: 10%;
        border: 1px solid #dddddd;
    }
    @media (max-height: 650px) {
        top: 5%;
        bottom: 5%;
    }
    @media (max-height: 600px) {
        top: 0;
        bottom: 0;
    }
`

const ListWrapper = styled.div`
    -webkit-text-size-adjust: 100%;
    @media (min-width: ${desktopWidth}px) {
        display: none;
    }
`
const Header = styled.div`
    position: relative;
    right: 0;
    left: 0;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    top: 0;
    padding: 28px 25px;
    font-size: 18px;
    font-weight: bold;
    z-index: 1000;
`
const ClearTop = styled(Clear)`
    float: right;
    cursor: pointer;
`
const ListItem = styled.li`
    list-style: none;
    font-size: 16px;
    padding: 8px 0;
    color: #1a1a1a;
    border-bottom: 2px solid #dddddd;
    &:last-child {
        border-bottom: 0;
    }
`

const FixedButtonContainer = styled.div`
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    position: relative;
    background-color: #ffffff;
    display: flex;
`
const RightListWrapper = styled(ListWrapper)`
    flex: 1;
    flex-direction: column;
    margin-right: 100px;
    margin-left: 60px;
    display: flex;
    max-height: 600px;
    @media (max-height: 780px) {
        max-height: 418px;
    }
`
const LeftColumn = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 50%;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
`
const RightColumn = styled(LeftColumn)`
    display: none;
    @media (min-width: ${desktopWidth}px) {
        display: flex;
    }
`
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 20px;
    @media (min-width: ${desktopWidth}px) {
        padding: 40px;
    }
`
const SizePickerWrapper = styled(SizePicker)`
    margin-bottom: 18px;
`

const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 380px;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        div[tabindex='1'] & {
            display: block;
        }
    }
`

const ScrollAreaWrapper = styled(ScrollArea).attrs({
    speed: 0.8,
    horizontal: false,
    verticalContainerStyle: { opacity: 0.3 },
})``
const renderStuffList = (capacity = []) => {
    const boxCapasityAvailable = capacity.map((item, index) => {
        return <ListItem key={index}> {item} </ListItem>
    })
    return capacity.length ? boxCapasityAvailable : null
}
// type Props = {
//   className?: string,
//   onClose: void => any,
//   category: string,
//   sizeType: SizeType,
//   availableCategories: Array<string>,
//   categoryObject: PlacebCategory,
//   changeSizeType: string => any,
//   changeCategory: string => any,
//   confirmChoose: void => any,
//   selectedLocation: PlacebLocation,
// };
const SizeTypeCalculator = (
    {
        className,
        onClose,
        category,
        sizeType,
        availableCategories,
        categoryObject,
        changeSizeType,
        changeCategory,
        confirmChoose,
        selectedLocation,
    } /*: Props*/
) => {
    const boxSizeType = BoxSizeTypes[sizeType.toLowerCase()]

    const categoryOptions = boxSizeType
        ? boxSizeType.options.filter(item =>
              selectedLocation.usedCategories.includes(item)
          )
        : []
    const stuffList = categoryObject
        ? renderStuffList(categoryObject.capacity)
        : null
    return (
        <Fixed className={className}>
            <ScrollAreaWrapper>
                <Header>
                    {t('calculatorQuestion')}
                    <ClearTop onClick={onClose} />
                </Header>
                <Content>
                    <LeftColumn>
                        <ControlsWrapper>
                            <SizeTypePicker
                                category={categoryObject}
                                onChange={changeSizeType}
                                value={sizeType}
                            />
                            {categoryOptions && category && (
                                <SizePickerWrapper
                                    availableCategories={availableCategories}
                                    onChange={changeCategory}
                                    options={categoryOptions}
                                    value={category}
                                />
                            )}
                            <ListWrapper>{stuffList}</ListWrapper>
                        </ControlsWrapper>
                        <FixedButtonContainer>
                            <ButtonMain onClick={confirmChoose}>
                                {t('chooseThisSize')}
                            </ButtonMain>
                        </FixedButtonContainer>
                    </LeftColumn>
                    <RightColumn>
                        <RightListWrapper>
                            <ScrollAreaWrapper>{stuffList}</ScrollAreaWrapper>
                        </RightListWrapper>
                    </RightColumn>
                </Content>
            </ScrollAreaWrapper>
        </Fixed>
    )
}

export default SizeTypeCalculator
