import handleActions from 'redux-actions/es/handleActions'
import BoxSizeTypes, {
    getSizeTypeByCategory,
} from '../../../constants/BoxSizeTypes'
import RANGES from '../../../constants/Ranges'
import {
    boxFinderReady,
    changeCategory,
    changeDuration,
    changeLocation,
    changeSizeType,
    presetValues,
    valueChanged,
} from './actions'

const defaultValue = {
    selectedRadius: RANGES[0],
    selectedDuration: 1,
    selectedCategoryType: 'xs',
    selectedCategory: BoxSizeTypes.xs.options[0],
    boxFinderReady: false,
    isLocationPreselected: true,
    positionChangedByUser: false,
    isValueChanged: false,
}

const createReducer = (payloadProperty, storeProperty) => (state, action) => ({
    ...state,
    [storeProperty]: action.payload[payloadProperty],
})

export default handleActions(
    {
        [changeDuration]: createReducer('duration', 'selectedDuration'),
        [changeCategory]: (state, { payload: { category } }) => ({
            ...state,
            selectedCategory: category,
            selectedCategoryType:
                getSizeTypeByCategory(category) || state.selectedCategoryType,
        }),
        [changeSizeType]: (state, { payload: { sizeType } }) => ({
            ...state,
            selectedCategoryType: sizeType,
        }),
        [changeLocation]: (state, { payload: { location } }) => ({
            ...state,
            selectedLocation: location.id,
        }),
        [boxFinderReady]: state => ({ ...state, boxFinderReady: true }),
        [valueChanged]: state => ({ ...state, isValueChanged: true }),
        [presetValues]: (state, action) => ({
            ...state,
            ...action.payload,
            isLocationPreselected: !!action.payload.selectedLocation,
        }),
    },
    defaultValue
)
