export const dimensions = {
    w: 'B',
    h: 'H',
    d: 'T',
}
export const sizeTypes = {
    xs: 'Extra Small',
    s: 'Small',
    m: 'Medium',
    l: 'Large',
    xl: 'Extra Large',
}
export const durations = {
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    year: 'Année',
    inSentence: {
        day: 'Jour',
        week: 'Semaine',
        month: 'Mois',
        year: 'Année',
    },
}

export const durationsMultiple = {
    day: 'Jours',
    week: 'Semaines',
    month: 'Mois',
    year: 'Années',
    inSentence: {
        day: 'Jours',
        week: 'Semaines',
        month: 'Mois',
        year: 'Années',
    },
}
