import { default as _reducer } from './sizeTypeCalculatorDuck'

export {
    epic,
    getShowCalculatorFlag,
    sizeChangedByCalculator,
    showCalculatorSelector,
} from './sizeTypeCalculatorDuck'
export { default } from './SizeTypeCalculatorPortalContainer'
export const reducer = { sizeTypeCalculator: _reducer }
