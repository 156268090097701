import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { routes } from '../../../../constants'
import actions from '../actions'
import { GtmEvent, HitType, PageType } from '../constants'
import { getBoxProduct } from '../utils'

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.bookingPageLoaded.toString()),
        mergeMap(() => {
            const boxProduct = getBoxProduct(state$.value)

            typeof window.gtag === 'function' &&
                window.gtag('event', 'begin_checkout', {
                    currency: 'CHF',
                    value: boxProduct.priceTotal,
                    items: [
                        {
                            item_id: boxProduct.dimension13,
                            item_name: boxProduct.name,
                            index: 0,
                            location_id: boxProduct.category,
                            price: boxProduct.price,
                            quantity: boxProduct.quantity,
                        },
                    ],
                })

            return [
                actions.pushEvent({
                    dataLayerObject: {
                        event: GtmEvent.ECCHB,
                        hitType: HitType.pageView,
                        page: `/${routes.booking}`,
                        pageType: PageType.checkout,
                        ecommerce: {
                            checkout: {
                                actionField: { step: 1 },
                                products: [boxProduct],
                            },
                        },
                    },
                }),
            ]
        })
    )
