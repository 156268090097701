import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n-lite'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import {
    cardElementStyle,
    CardRoot,
    FormHelperText,
} from '../components/creditCardForm'
import { createAndConfirmSetupIntent } from '../Payment/components/utils'
import { ButtonMain } from '../../components/ButtonMain'
import styled from 'styled-components'
import rest from '../../../services/rest'

const t = phrase => I18n.t(`checkoutProcess.account.${phrase}`)

const MaxWidthWrapper = styled.div`
    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const Footer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 1px 0 0 #dddddd;
    background-color: white;
    padding: 16px;
`

const CardForm = ({ user, setUser }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState()

    const handleSubmit = async event => {
        event.preventDefault()
        let tokenResp, setupResp

        if (!stripe || !elements) {
            return
        }

        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
            return
        }

        setError(null)

        try {
            tokenResp = await stripe.createToken(cardElement)
            if (tokenResp.error) {
                console.error(tokenResp.error)
                setError(t('invalidCard'))
                return
            }

            setupResp = await createAndConfirmSetupIntent(
                tokenResp.token,
                user.id,
                stripe,
                elements
            )
        } catch (e) {
            setError(t('invalidCard'))
            return
        }

        cardElement.clear()

        user.card = setupResp.card
        setUser(user)
    }

    return (
        <form onSubmit={handleSubmit}>
            <CardRoot>
                <CardElement
                    options={{
                        hidePostalCode: true,
                        style: cardElementStyle,
                    }}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
            </CardRoot>
            <Footer>
                <MaxWidthWrapper>
                    <ButtonMain disabled={!stripe} type="submit">
                        {t('save')}
                    </ButtonMain>
                </MaxWidthWrapper>
            </Footer>
        </form>
    )
}

export default CardForm
