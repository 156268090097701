export default {
    header: 'Step 3: Payment',
    paymentOptions: 'Payment options',
    creditCard: 'Credit card',
    invoice: 'Invoice (+ CHF 5 / Invoice)',
    couponCode: 'Coupon code',
    enterCode: 'Enter code',
    termsRequired: 'Please accept our terms and conditions.',
    acceptTerms:
        'Yes, I accept the <a href="https://www.placeb.ch/en/terms" target="_blank">General Terms and Conditions</a> and the <a href="https://www.placeb.ch/en/privacy-policy" target="_blank">Privacy statement</a>',
    confirm: 'Book now',
    required: "This field can't be empty",
    incorrectValue: 'Incorrect value',
    hint: 'Important note: You can cancel your booking within the next 7 days for free.',
    invoiceDepot:
        'Invoice with prepayment & deposit (+ CHF 5 / invoice, deposit: 3 months rent)',
    invoiceDepotHelpText:
        "The access will be activated as soon as we have received the first month's rent and the deposit.",
    invoice3rdParty: 'Payment by a social authority (cost advocacy necessary)',
    invoice3rdPartyHelpText:
        'The access will be activated as soon as we have received the cost advocacy from the social authority.',
    ratingInformation: `<p>Thank you for your booking. In order to be able to offer the best possible prices even with the payment method "invoice", we must protect ourselves from possible payment failures. For this reason, we perform a credit check on all customers. The check of your request has shown that you are not listed in the database of our external service provider in the area of credit information, Intrum, with a required rating.</p>
    <p>
    Of course, you can still make the desired booking. However, we ask for your understanding that we have to insist on an advance payment incl. deposit, a cost advocacy by a social authority or on payment by credit card. Thank you for informing us about your preferred payment method.
    </p>
    <p>
    In order for us to be able to meet your desired payment method for future bookings, you have the possibility to submit <a target="_blank" href="https://www.intrum.ch/de/konsumenten/uber-intrum/ihre-privatsphare-ist-unsere-prioritat/kontaktformular-erlauterung-bonitatsprufung/">a confirmation of residence to the company Intrum</a>.
    </p>
    `,
    moreInfo: 'More information',
    close: 'Close',
    applyCouponCode: 'Validate',
}
