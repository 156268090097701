import {
    dimensions,
    durations,
    durationsMultiple,
    sizeTypes,
} from '../../../../translations/enCommon'

export default {
    header: 'Step 1: Booking',
    boxInformation: 'Box Information',
    size: 'Size',
    sizes: {
        extraSmall: 'Extra small',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        extraLarge: 'Extra large',
    },
    dimensions,
    sizeTypes,
    durations,
    durationsMultiple,
    location: 'Location',
    duration: 'Minimum duration',
    durationTitle: 'Duration',
    startDate: 'Choose Start Date',
    requiredFieldError: 'Please specify value to be insured',
    minimumTerm: 'minimum period',
    insurance: 'Insurance (mandatory)',
    insuranceDescription: 'The costs are CHF 6 per CHF 10.000 of goods value per month',
    confirmInsurance: 'Yes, I need a placeB-insurance (recommended)',
    boxValue: 'Value of your goods (CHF)',
    disableInsuranceStrong: 'No, I do not need placeB-insurance and I am aware that I must notify my insurance company that my items are stored at a third-party location.',
    insurancePartner: 'Your insurance partner',
    disableInsurance: ' I will not make any claims against placeB in the event of damage.',
    nextStep: 'Next step',
    ok: 'OK',
    cancel: 'Cancel',
    per: 'per',

    noticePeriod: 'Notice Period',
    noticePeriodDescription:
        'Without cancellation, the contract will automatically renew by a %{period}. However, you also have the flexibility to cancel early.',
    insuranceInformation: 'Insurance',
    insuranceInformationDescription:
        'Insurance for theft, fire and water damage is compulsory',
    moreInformation: 'More information',
    incorrectValue: 'Incorrect value',
    modalTitle1: 'Cancellation policy',
    modalText1: `
    There are no notice periods. Without notice, the contract is simply extended by one month (or one week if you have selected a weekly contract). However, it is not a problem if you no longer need the storage box before then. In this case, if necessary, the discount will be corrected retroactively and you can leave us at any time within a week. Simply send us an e-mail to info@placeb.ch.
    \n \t Please note: if you cancel on the last day, make sure that your room is empty, as you will no longer have access to the placeB box after that.
    \n \t You can easily cancel your contract in the placeB app, simply:
    \r \t - log in to the app
    \r \t - Go to “My boxes”
    \r \t - Select a box
    \r \t - click on the menu button (three dots in the top right corner) and then on contract details. There you will find a green button at the bottom labeled "Cancel".
    \n \t Done!
  `,
    modalTitle2: 'Are my things insured?',
    modalText2: `Not automatically. However, an insurance for theft, fire and water damage is compulsory. You have two options:
    \n \t Either you can contact your home insurance for an additional policy or you ensure your stores items with us.
    \n \t A simple click in the checkout process is all you need. At 6.- to ensure a value of up to 10’000 Franks, our offer is even cheaper than most home insurers.`,
}
