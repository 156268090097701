import React from 'react'
// @ts-ignore
import { I18n } from 'react-redux-i18n-lite' // FIXME: Missing type definitions
import { Field } from 'redux-form'
import styled from 'styled-components'
import { getPricingTexts } from '../../../services/utils'
import FlexContainer from '../../components/FlexContainer'
import PriceFooter from '../../components/PriceFooter'
import EditButton from '../components/EditButton'
import FullScreen from '../components/FullScreen'
import BoxDuration from '../components/InformationItems/BoxDuration'
import BoxLocation from '../components/InformationItems/BoxLocation'
import BoxSize from '../components/InformationItems/BoxSize'
import BookingHeader from './components/BookingHeader'
import InsuranceSelector from './components/InsuranceSelector'
import StartDatePicker from './components/StartDatePicker'
import TermsContainer from './components/TermsContainer'

const iphoneDevice = !!navigator.platform && /f/.test(navigator.platform)

const Root = styled(FullScreen)`
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: ${iphoneDevice ? 'touch' : 'no'};
`

const MaxWidthWrapper = styled.div`
    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const GreyLine = styled.div`
    box-sizing: border-box;
    height: 2px;
    margin: 16px 22px 14px 19px;
    border: 1px solid #dddddd;
`

const BoxInformation = styled(FlexContainer)`
    padding: 20px 0 20px 20px;
    flex-direction: row;
    justify-content: space-between;
`

const Title = styled.div`
    font-family: Helvetica, sans-serif;
    flex: 1;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
`

const tPrefix = 'checkoutProcess.booking'
const t = (phrase: string) => I18n.t(`${tPrefix}.${phrase}`)

const renderInsuranceSelector = (props: any) => (
    <InsuranceSelector
        hasInsurance={props.hasInsurance}
        meta={props.meta}
        {...props.input}
        error={props.meta.error}
        onChange={(val: any) => props.input.onChange(val == null ? 'no' : val)}
    />
)

const renderStartDate = ({ input }: any) => <StartDatePicker {...input} />

const format = (a: any) => (a === 'no' ? null : a)
const formatDate = (v: any) => new Date(v)

interface Props {
    location: string
    category: object
    duration: {
        id: number
        price: number
        duration: number
        unit: string
    }
    price: {
        price: number
        insurancePerMonth: number
    }
    preOpened: boolean
    openBoxFinder: () => void
    handleSubmit: () => void
}

class Booking extends React.Component<Props> {
    bookingBlock = React.createRef<HTMLDivElement>()
    state = { insuranceValueError: false }

    componentWillUnmount() {
        if (this.bookingBlock.current) {
            this.bookingBlock.current.scrollIntoView()
        }
    }

    onBack() {
        window.history.back()
    }

    render() {
        const {
            location,
            category,
            duration,
            price,
            handleSubmit,
            preOpened,
        } = this.props

        const pricingTexts = getPricingTexts(
            price.price,
            duration.unit,
            duration.duration,
            category,
            preOpened,
            price.insurancePerMonth
        )

        // @ts-ignore
        const hasInsurance = category.title && category.title.charAt(0) !== 'P'

        return (
            <Root ref={this.bookingBlock}>
                <BookingHeader onButtonClick={this.onBack} />
                <MaxWidthWrapper>
                    <BoxInformation>
                        <Title>{t('boxInformation')}</Title>
                        <EditButton onClick={this.onBack} />
                    </BoxInformation>
                    <BoxSize sizeParameters={category} />
                    <BoxLocation location={location} />
                    <BoxDuration duration={duration} />
                    <GreyLine />
                    <Field
                        component={renderStartDate}
                        format={formatDate}
                        name="startDate"
                        type="date"
                    />
                </MaxWidthWrapper>
                <Field
                    component={renderInsuranceSelector}
                    format={format}
                    name="insuranceValue"
                    props={{ hasInsurance: hasInsurance }}
                />
                <TermsContainer
                    duration={duration}
                />
                <PriceFooter
                    buttonTitle={t('nextStep')}
                    onClick={handleSubmit}
                    pricingTexts={pricingTexts}
                    relative={iphoneDevice}
                />

                <img src="https://secure.adnxs.com/px?id=1870266&seg=39559685&t=2" width="1" height="1" />
            </Root>
        )
    }
}

export default Booking
