import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'
import styled from 'styled-components'

import PlaceBTheme from '../../../styles/PlacebTheme'
import { BoxWithAlignment } from '../components/commonComponents'

const mainColor = PlaceBTheme.palette.primary.main

const LeftArrowIconWrapper = styled(LeftArrowIcon).attrs({
    color: 'secondary',
})``
const RightArrowIconWrapper = styled(RightArrowIcon).attrs({
    color: 'secondary',
})``
const RightArrowRoot = styled(BoxWithAlignment)`
    background: #dddddd;
    z-index: 99;
    height: 100%;
    width: 52px;
    &::before {
        content: none;
    }
    &:hover {
        background: ${mainColor};
    }
    right: -52px !important;
`
// type Props = {
//   className: string,
//   onClick: void => void,
// };
export const RightArrow = ({ className, onClick } /*: Props*/) => (
    <RightArrowRoot className={className} onClick={onClick}>
        <RightArrowIconWrapper />
    </RightArrowRoot>
)

const LeftArrowRoot = styled(RightArrowRoot).attrs({
    color: 'secondary',
})`
    right: initial !important;
    left: -52px !important;
`

export const LeftArrow = ({ className, onClick } /*: Props*/) => (
    <LeftArrowRoot className={className} onClick={onClick}>
        <LeftArrowIconWrapper />
    </LeftArrowRoot>
)
