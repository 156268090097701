import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import {
    boxFinderReady,
    changeCategory,
    changeLocation,
    getSelectedCategory,
    getSelectedCategoryType,
} from '../../../../redux/modules/productDuck'
import {
    chooseCategoryBySizeType,
    findAvailableCategoryAndSizeType,
    ifSizeTypeAvailable,
} from '../../../../redux/modules/productDuck/helpers'
import { coordsToShort } from '../../../../services/utils'
import { changeMapCenter } from '../../LocationMap'
import { changePosition } from '../../LocationSelector'

export default (action$ /*: any*/, state$ /*: any*/) /*: []*/ =>
    action$.pipe(
        ofType(changeLocation.toString()),
        mergeMap(({ payload: { location, setPosition, init } }) => {
            const stream = [
                changeMapCenter({ center: coordsToShort(location) }),
            ]
            init && stream.push(boxFinderReady())
            if (setPosition) {
                stream.unshift(
                    changePosition({ position: location, setLocation: false })
                )
            }
            const selectedCategory = getSelectedCategory(state$.value)
            const selectedCategoryType = getSelectedCategoryType(state$.value)
            const theSameSizeTypeAvailable = ifSizeTypeAvailable(
                location,
                selectedCategoryType
            )
            if (theSameSizeTypeAvailable) {
                //save sizeType and find available category
                const categoryInTheSameSizeType = chooseCategoryBySizeType(
                    location,
                    selectedCategoryType,
                    selectedCategory
                )
                if (categoryInTheSameSizeType !== selectedCategory) {
                    stream.unshift(
                        changeCategory({ category: categoryInTheSameSizeType })
                    )
                    return stream
                }
                return stream
            }
            // find available categories in other sizeTypes
            const { category, sizeType } =
                findAvailableCategoryAndSizeType(location)
            if (category && sizeType) {
                //founded
                stream.unshift(changeCategory({ category }))
                return stream
            }
            // no available categories in the location
            const category1 = chooseCategoryBySizeType(
                location,
                selectedCategoryType
            ) //Try to find used but not available category
            if (category1) {
                stream.unshift(changeCategory({ category: category1 }))
                return stream
            } else {
                const categoryTitle =
                    (location.categories[0] && location.categories[0].title) ||
                    location.usedCategories[0]
                categoryTitle &&
                    stream.unshift(
                        changeCategory({
                            category: categoryTitle,
                        })
                    )
                return stream
            }
        })
    )
