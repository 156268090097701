import * as R from 'ramda'
import handleActions from 'redux-actions/es/handleActions'
import * as Payment from '../Payment'
import actions, {
    changeDiscount,
    disableCard,
    reservationFailed,
    reservationSuccess,
    setLastReservation,
} from './actions'

const defaultValue = {
    insuranceValue: '',
    payByCard: true,
    discount: false,
    lastReservation: null,
    ordered: false,
    isReservationPending: false,
    isPasswordModalVisible: false,
    isRatingModalVisible: false,
}
export default handleActions(
    {
        [changeDiscount]: (state, { payload: { discount } }) => ({
            ...state,
            discount,
        }),
        [setLastReservation]: (state, { payload }) => ({
            ...state,
            lastReservation: payload,
        }),
        [disableCard]: R.assoc('payByCard', false),
        [reservationFailed]: R.assoc('isReservationPending', false),
        [reservationSuccess]: state => ({
            ...state,
            isReservationPending: false,
            ordered: true,
        }),
        [actions.startReservation]: R.assoc('isReservationPending', true),
        [Payment.actions.showPasswordModal]: R.assoc(
            'isPasswordModalVisible',
            true
        ),
        [Payment.actions.hidePasswordModal]: R.assoc(
            'isPasswordModalVisible',
            false
        ),
        [Payment.actions.passwordModalConfirmed]: R.assoc(
            'isPasswordModalVisible',
            false
        ),
        [Payment.actions.showRatingModal]: R.assoc(
            'isRatingModalVisible',
            true
        ),
        [Payment.actions.hideRatingModal]: R.assoc(
            'isRatingModalVisible',
            false
        ),
    },
    defaultValue
)
