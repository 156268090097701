import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import FlexContainer from '../../components/FlexContainer'
import FullScreen from '../components/FullScreen'
import CardForm from './CardForm'
import Login from '../components/Login/Login'
import StripeProvider from '../components/stripeProvider'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    loginUser,
    getLoggedUserProfile,
    setLoggedUser,
} from '../../../redux/modules/auth'
import { initialize } from 'redux-form'
const t = phrase => I18n.t(`checkoutProcess.account.${phrase}`)
const iphoneDevice = !!navigator.platform && /iPhone/.test(navigator.platform)

const Root = styled(FullScreen)`
    padding: 24px;
    overflow-y: auto;
    -webkit-overflow-scrolling: ${iphoneDevice ? 'touch' : 'no'};
    font-family: Helvetica, sans-serif;
    margin-bottom: 25px;
    h2 {
        margin: 0px 0px 24px;
        font-weight: bold;
        font-size: 1.125rem;
    }
`

const CurrentCard = styled.div`
    margin-bottom: 32px;
`

const MaxWidthWrapper = styled.div`
    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const AccountWrapper = styled(FlexContainer)`
    align-items: stretch;
`
const Account = ({ login, user, setUser }) => {
    const [card, setCard] = useState(undefined)

    const onSetUser = newUser => {
        setUser({ user: newUser })
        initialize('profileForm', newUser)
        setCard(newUser.card)
    }

    useEffect(() => {
        setCard(user?.card)
    }, [user?.card])

    return (
        <Root>
            <StripeProvider>
                <AccountWrapper>
                    <MaxWidthWrapper>
                        {user ? (
                            <div>
                                <h2>{t('currentCard')}</h2>
                                <CurrentCard>
                                    {card
                                        ? `**** **** **** ${card.last4} (${
                                              card.exp_month
                                          }/${
                                              card.exp_year
                                          }, ${card.brand.toUpperCase()})`
                                        : t('noCard')}
                                </CurrentCard>
                                <h2>{t('updateCard')}</h2>
                                <CardForm setUser={onSetUser} user={user} />
                            </div>
                        ) : (
                            <Login login={login} />
                        )}
                    </MaxWidthWrapper>
                </AccountWrapper>
            </StripeProvider>
        </Root>
    )
}

const mapStateToProps = state => ({
    user: getLoggedUserProfile(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            login: loginUser,
            setUser: setLoggedUser,
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(Account)
