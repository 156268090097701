import * as R from 'ramda'
import largeImage from './boxSizes/box-l.svg'
import mediumImage from './boxSizes/box-m.svg'
import smallImage from './boxSizes/box-s.svg'
import extraLargeImage from './boxSizes/box-xl.svg'
import extraSmallImage from './boxSizes/box-xs.svg'
import basicStorageImage from './boxSizes/box-xxl.svg'

const Types = {
    xs: {
        description: 'Extra Closet, 1-5m³',
        stuffNumber: 2,
        image: extraSmallImage,
        options: ['S1', 'S2', 'S3', 'S4', 'S5'],
    },
    s: {
        description: '1 - 2.5 room Apartment, 6-10m³',
        stuffNumber: 8,
        image: smallImage,
        options: ['S6', 'S7', 'S8', 'S9', 'M10'],
    },
    m: {
        description: '3.5 - 4 room Apartment, 12-20m³',
        stuffNumber: 14,
        image: mediumImage,
        options: [
            'M11',
            'M12',
            'M13',
            'M14',
            'M15',
            'M16',
            'M17',
            'M18',
            'M19',
            'L20',
        ],
    },
    l: {
        description: '5 room Apartment, 22-30m³',
        stuffNumber: 16,
        image: largeImage,
        options: [
            'L21',
            'L22',
            'L23',
            'L24',
            'L25',
            'L26',
            'L27',
            'L28',
            'L29',
            'L30',
        ],
    },
    xl: {
        description: '6 - 7 Zimmer Wohnung, 32-50m³',
        stuffNumber: 17,
        image: extraLargeImage,
        options: [
            'L31',
            'L32',
            'L33',
            'L34',
            'L35',
            'L36',
            'L37',
            'L38',
            'L39',
            'L40',
            'L41',
            'L42',
            'L43',
            'L44',
            'L45',
            'L46',
            'L47',
            'L48',
            'L49',
            'L50',
        ],
    },
    xxl: {
        description: 'Kommerzielles Lager, 50-100m³',
        stuffNumber: 20,
        image: basicStorageImage,
        options: ['L53', 'L54', 'L55', 'L72', 'L86', 'L89', 'L96'],
    },
}

export const getSizeTypeByCategory = category => {
    const res = R.pickBy(item => R.contains(category, item.options), Types)
    return R.keys(res)[0]
}

export { Types as default }
