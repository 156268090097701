import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
// import type { Dispatch } from 'redux';
import {
    getSelectedLocationObject,
    openBoxFinder,
} from '../../../redux/modules/productDuck'
import { getGeolocation } from '../../../redux/modules/userGeolocation'
import { goToUserLocation } from '../LocationMap'
import FullMapScreen from './FullMapScreen'
// import type { Action } from '../../../types';

const mapStateToProps = state => ({
    userPosition: getGeolocation(state),
    selectedLocation: getSelectedLocationObject(state),
    onClose: openBoxFinder,
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators({ goToUserLocation }, dispatch)

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    FullMapScreen
)
