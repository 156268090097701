import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    changeDuration,
    getDurations,
    getSelectedDuration,
    getSelectedDurationObject,
} from '../../../redux/modules/productDuck'
import DurationSlider from './DurationSlider'
// import type { Action } from '../../../types';

const mapStateToProps = state => ({
    options: getDurations(state),
    value: getSelectedDuration(state),
    visible: getSelectedDurationObject(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators({ onChange: changeDuration }, dispatch)

const DurationSliderContainer = (props /*: Props & { visible: boolean }*/) =>
    props.visible && props.options && props.options[props.value] ? (
        <DurationSlider {...props} />
    ) : null
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DurationSliderContainer)
