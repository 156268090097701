import Clear from '@material-ui/icons/Clear'
import Room from '@material-ui/icons/Room'
import React from 'react'
import styled from 'styled-components'
import { desktopWidth } from '../../../config'
// import type {
//   CheapestBox,
//   PlacebLocation,
//   SizeTypeObject,
// } from '../../../types';
import { createTranslator } from '../../../services'
import PriceFooter from '../../components/PriceFooter'
import Dimensions from '../components/Dimensions'
import { getPricingTexts } from '../../../services/utils'

const t = createTranslator('boxFinder')
const Overlay = styled.div`
    touch-action: none;
    position: fixed;
    overflow-x: hidden;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex: 1;
    background: rgba(0, 0, 0, 0.4);
`
const Fixed = styled.div`
    touch-action: none;
    position: fixed;
    overflow-x: hidden;
    z-index: 99;
    top: 0;
    //bottom: 0;
    background: white;
    flex: 1;
    flex-direction: column;
    display: flex;
    right: 0;
    left: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    @media (min-width: ${desktopWidth}px) {
        top: 10%;
        right: 10%;
        bottom: auto;
        left: 10%;
        border: 1px solid #dddddd;
    }
    @media (max-height: 650px) {
        top: 0;
        bottom: 30%;
    }
    @media (max-height: 600px) {
        top: 0;
        bottom: 20%;
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    @media screen and (orientation: landscape) and (max-height: 420px) {
        bottom: 0;
    }
`

const ClearTop = styled(Clear)`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 30px !important;
    width: 30px !important;
`
const Header = styled.div`
    position: relative;
    right: 0;
    left: 0;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    top: 0;
    padding: 10px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`
const AddressBlock = styled.div`
    display: flex;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const HeaderThin = styled.div`
    font-family: Helvetica;
    line-height: 22px;
    font-size: 16px;
    font-weight: normal;
`
const LocationBlock = styled.div`
    margin-left: 10px;
`
const RoomWrapper = styled(Room)`
    color: #80c700 !important;
    height: 30px !important;
    width: 30px !important;
`
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    @media screen and (orientation: landscape) {
        overflow-y: auto;
        display: block;
    }
`
const ImageWrapper = styled.div`
    position: relative;
    padding-right: 18px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;
    flex: 1;
    flex-shrink: 0;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        div[tabindex='1'] & {
            display: block;
        }
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) and (max-width: 1023px) {
        div[tabindex='1'] & {
            text-align: center;
        }
    }
`
const SizeTypeImage = styled.img`
    //margin: 5px 100px;
    margin: 5px 10px;
    display: flex;
    //flex:1;
    text-align: center;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    height: 120px;
    max-width: 165px;
    @media (min-height: 640px) {
        height: 180px;
        max-width: 250px;
    }
    @media (min-height: 655px) {
        //height: 280px;
        //max-width: 340px;
        height: 210px;
        max-width: 300px;
    }
    @media (min-height: 733px) {
        //height: 250px;
        //max-width: 310px;
        height: 270px;
        max-width: 340px;
    }
    @media (min-height: 760px) {
        height: 300px;
        max-width: 370px;
    }
    @media (min-height: 860px) {
        height: 400px;
        max-width: 495px;
    }
    @media (min-height: 965px) {
        height: 540px;
        max-width: 600px;
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        div[tabindex='1'] & {
            display: inline-block;
        }
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) and (min-width: 1024px) and (max-width: 1500px) {
        div[tabindex='1'] & {
            margin-left: 0 !important;
        }
    }
`

const DimensionsWrapper = styled(Dimensions)`
    margin-left: 20px;
    margin-right: 20px;

    @media (min-width: 640px) and (max-width: ${desktopWidth - 1}px) {
        position: absolute;
        right: 0;
    }
`

const InformationBlock = styled.div`
    box-shadow: inset 0 1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
    display: flex;
    flex-direction: row;
    padding: 20px;
`
const InformationItem = styled.div`
    display: flex;
    flex-direction: column;
`
const Information = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
`
const InformationGreen = styled.div`
    font-family: Helvetica;
    margin-left: 10px;
    color: #80c700;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    text-transform: capitalize;
`
const ImageBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 18px;
    flex-shrink: 0;
    flex: 1;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
`
// export type Props = {
//   cheapestBox: CheapestBox,
//   changeLocation: PlacebLocation => any,
//   onClose: void => any,
//   className: string,
//   selectedCategoryType: SizeTypeObject,
// };
class CheaperModal extends React.Component /*<Props>*/ {
    onConfirm = () => {
        const {
            cheapestBox: { cheapestLocation },
            changeLocation,
            onClose,
        } = this.props
        changeLocation(cheapestLocation)
        onClose()
    }
    renderDurationString = () => {
        const { cheapestBox } = this.props
        const { category, selectedDuration } = cheapestBox
        const { duration, unit } = category.durations[selectedDuration]
        const translationPrefix =
            duration > 1 ? 'durationsMultiple' : 'durations'
        return ` ${duration} ${t(`${translationPrefix}.${unit}`)}`
    }

    render() {
        const { cheapestBox, className, selectedCategoryType, onClose } =
            this.props

        const { category, cheapestLocation, selectedDuration } = cheapestBox
        const boxSize = category.subType.toLowerCase()
        const sizeType = `${t(`sizeTypes.${boxSize}`)}`

        const duration = category.durations[selectedDuration]

        const cheaperPrice =
            selectedDuration === 0
                ? category.durations[selectedDuration].price / 4
                : category.durations[selectedDuration].price
        const price = (Math.round(cheaperPrice * 100) / 100).toFixed(2)

        const pricingTexts = getPricingTexts(
            price,
            duration.unit,
            duration.duration,
            category,
            cheapestLocation.preOpened,
            0
        )

        return (
            <Overlay className={className}>
                <Fixed>
                    <Header>
                        <AddressBlock>
                            <RoomWrapper />
                            <LocationBlock>
                                {cheapestLocation.street}
                                <HeaderThin>{cheapestLocation.city}</HeaderThin>
                            </LocationBlock>
                        </AddressBlock>
                        <ClearTop onClick={() => onClose()} />
                    </Header>
                    <Content>
                        <ImageBlock>
                            <ImageWrapper>
                                <SizeTypeImage
                                    src={selectedCategoryType.image}
                                />
                                {category && (
                                    <DimensionsWrapper category={category} />
                                )}
                            </ImageWrapper>
                        </ImageBlock>
                        <InformationBlock>
                            <InformationItem>
                                <Information>{t('size')}</Information>
                                <Information>{t('duration')}</Information>
                            </InformationItem>
                            <InformationItem>
                                <InformationGreen>
                                    {sizeType}, {category.volume}
                                    m³
                                </InformationGreen>
                                <InformationGreen>
                                    {this.renderDurationString()}
                                </InformationGreen>
                            </InformationItem>
                        </InformationBlock>
                        <PriceFooter
                            buttonTitle={t('selectBox')}
                            onClick={this.onConfirm}
                            pricingTexts={pricingTexts}
                            relative
                        />
                    </Content>
                </Fixed>
            </Overlay>
        )
    }
}

export default CheaperModal
