import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { routes } from '../../../../constants'
import actions from '../actions'
import { GtmEvent, HitType, PageType } from '../constants'
import { getBoxProduct } from '../utils'

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.boxSelected.toString()),
        mergeMap(() => [
            actions.pushEvent({
                dataLayerObject: {
                    event: GtmEvent.ECFAD,
                    hitType: HitType.event,
                    page: `/${routes.finder}`,
                    pageType: PageType.boxFinder,
                    componentCategory: PageType.boxFinder,
                    componentSubcategory: 'cart',
                    componentAttribute1: 'add',
                    ecommerce: {
                        add: { products: [getBoxProduct(state$.value)] },
                    },
                },
            }),
        ])
    )
