import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, SubmissionError, submit } from 'redux-form'
import { scrollToFirstError } from '../../../core/utils'
import {
    getPreopenedFlag,
    getSelectedCategoryObject,
    getSelectedDurationObject,
    getSelectedLocationObject,
} from '../../../redux/modules/productDuck'
import parseYupErrors from '../../../services/parseYupErrors'
// import type { Action } from '../../../types'
import { openUserDetails, paymentConfirmed } from '../checkoutDuck/actions'
import {
    getDiscount,
    getIsReservationPending,
    totalPricePerMonth,
} from '../checkoutDuck/selectors'
import { getProfileFormValues } from '../UserDetails'
import duck from './duck'
import moduleName from './moduleName'
import Payment from './Payment'
import validationSchema from './validationSchema'

const mapStateToProps = state => ({
    location: getSelectedLocationObject(state),
    userInformation: getProfileFormValues(state) || {},
    price: totalPricePerMonth(state),
    category: getSelectedCategoryObject(state),
    preOpened: getPreopenedFlag(state),
    duration: getSelectedDurationObject(state),
    discount: getDiscount(state) || '',
    pendingTasks: getIsReservationPending(state),
    openUserDetails,
    state,
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            confirmPayment: (card, password) =>
                paymentConfirmed({ card, password }),
            openTnC: () => window.open('', '_blank'),
            onConfirm: R.always(submit(moduleName)),
            onSubmitSuccess: duck.actions.formConfirmed,
        },
        dispatch
    )
const onSubmit = async (values, _, { category, location, duration }) => {
    try {
        return await validationSchema.validate(
            { ...values, category, location, duration },
            { abortEarly: false }
        )
    } catch (_errors) {
        const errors = R.compose(
            // R.map(t),
            parseYupErrors
        )(_errors)
        if (!R.isEmpty(errors)) {
            throw new SubmissionError(errors)
        }
    }
}

const formCreator = reduxForm({
    form: moduleName,
    onSubmit,
    onSubmitFail: scrollToFirstError,
    pure: false,
    destroyOnUnmount: false,
    initialValues: { invoice: true, couponCode: '' },
})

export default R.compose(
    connect(mapStateToProps, mapDispatchToProps),
    formCreator
)(Payment)
