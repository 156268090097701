export default {
    successLabel:
        'Danke! Du hast deine Box erfolgreich gebucht. Hier sind die nächsten Schritte.',
    nextSteps: 'Nächster Schritt',
    installMobileApp: 'Installiere die placeB App auf deinem Telefon',
    verifyEmail: 'E-Mail verifizieren',
    verifyPhone: 'Telefonnummer verifizieren',
    personalInformation: 'Informationen',
    bookedBoxes: 'Gebuchte Boxen',
    install: 'Installiere jetzt die placeB app',
    installSubheader:
        'Hol dir jetzt deinen Schlüssel zu mehr Platz und profitiere von allen Vorteilen',
    myBoxes: 'Meine Boxen',
}
