import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import LoginForm from './LoginForm'

const styles = { paper: { width: '100%' } }
const emptyCredentials = {
    email: '',
    password: '',
}
class LoginModal extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
    }

    state = {
        credentials: emptyCredentials,
    }

    onChange = value =>
        this.setState(prevState => {
            const { credentials } = prevState
            return { credentials: { ...credentials, ...value } }
        })

    onConfirm = () => {
        const { onConfirm } = this.props
        const { credentials } = this.state
        if (this.validate()) {
            onConfirm(credentials)
            this.onClose()
        }
    }

    resetFields = () => this.setState(() => ({ credentials: emptyCredentials }))

    onClose = () => {
        this.resetFields()
        this.props.onClose()
    }

    setValidationFunc = f => (this.validate = f)

    render() {
        const { show, classes } = this.props
        const { credentials } = this.state
        const tPrefix = 'bookingPage.step2.loginModal'

        return (
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                classes={classes}
                onClose={this.onClose}
                open={show}
            >
                <DialogTitle id="alert-dialog-title">
                    {I18n.t(`${tPrefix}.login`)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <LoginForm
                            credentials={credentials}
                            onChange={this.onChange}
                            setValidationFunc={this.setValidationFunc}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.onClose}>
                        {I18n.t(`${tPrefix}.cancel`)}
                    </Button>
                    <Button color="primary" onClick={this.onConfirm}>
                        {I18n.t(`${tPrefix}.login`)}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export default withStyles(styles)(LoginModal)
