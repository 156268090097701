import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PlaceBTheme from '../../../../styles/PlacebTheme'
const mainColor = PlaceBTheme.palette.primary.main

const Root = styled.div`
    display: flex;
    align-items: center;
    width: 75px;
    justify-content: space-between;
`

const UnActiveCircle = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1.5px solid #c0c0c0;
`

const Active = styled(UnActiveCircle)`
    border: none;
    background: ${mainColor};
`

const Line = styled.div`
    width: 56.8px;
    border-top: 1.5px solid #ffffff;
`

class Stepper extends React.Component {
    render() {
        const { stepNumber, value } = this.props
        const steps = Array(stepNumber)
            .fill(0)
            .map((item, index, array) => {
                const current = +index
                return (
                    <React.Fragment key={index}>
                        {current === value ? <Active /> : <UnActiveCircle />}
                        {current !== array.length - 1 && <Line />}
                    </React.Fragment>
                )
            })
        return <Root className={this.props.className}>{steps}</Root>
    }
}

Stepper.propTypes = {
    stepNumber: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}
export default Stepper
