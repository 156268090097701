import React from 'react'
import { GoogleMap, withGoogleMap } from 'react-google-maps'

const WithMap0 = withGoogleMap(({ children, mapLoaded, ...other }) => {
    return (
        <GoogleMap
            options={{
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                    position: global.google.maps.ControlPosition.TOP_RIGHT,
                },
                scrollwheel: true,
                streetViewControl: false,
                navigationControl: true,
            }}
            {...other}
            ref={mapLoaded}
        >
            {children}
        </GoogleMap>
    )
})

const WithMap = React.forwardRef((props, ref) => (
    <WithMap0 {...props} ref={ref} />
))

const createContainer = () => <div style={{ display: 'flex', flex: 1 }} />

const MarkerClustererExample = React.forwardRef(
    ({ children, ...other }, ref) => (
        <WithMap
            containerElement={createContainer()}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAnMfptlhR2i5eVO2JjpdHPN5qzdz2AAZM&v=3.exp&libraries=geometry,drawing,places"
            mapElement={createContainer()}
            {...other}
            ref={ref}
            mapLoaded={r => (ref.current = r)}
        >
            {children}
        </WithMap>
    )
)

export default MarkerClustererExample
