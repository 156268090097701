import React from 'react'
import styled from 'styled-components'
import { createTranslator } from '../../../services'

const t = createTranslator('boxFinder')
const Root = styled.div`
    margin: 18px;
`
const PreOpenedMessage = () => <Root>{t('preOpenedMessage')}</Root>
export default PreOpenedMessage
