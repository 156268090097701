import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import RANGES from '../../../constants/Ranges'
import {
    getSelectedRadius,
    getShowRadiusSelectorFlag,
    showRadiusSelector,
} from './radiusSelectorDuck'
import RadiusSelectorPortal from './RadiusSelectorPortal'

const mapStateToProps = state => ({
    options: RANGES,
    selectedRadius: getSelectedRadius(state),
    visible: getShowRadiusSelectorFlag(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators({ showSelector: () => showRadiusSelector() }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RadiusSelectorPortal)
