import compact from 'lodash/compact'
import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import FormAbstract from '../FormAbstract'
import Input from '../Input'

const tPrefix = 'checkoutProcess.userDetails.loginModal'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const Root = styled.div`
    display: flex;
    flex-direction: column;
    @supports (-ms-ime-align: auto) {
        display: block;
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    @-moz-document url-prefix() {
        display: block;
    }
`
const InputWrapper = styled(Input)`
  &&{
    margin-top: 26px;
  @supports (-ms-ime-align: auto) {
  }
    width: 100% !important;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    width: 100% !important;
  }
  @-moz-document url-prefix() {
    width: 100% !important;
  }
  }
`

const filters = {}
const validators = {
    // eslint-disable-next-line
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(.{2,})$/,
}
const requiredProperties = ['email', 'password']
const fieldErrorMessage = {
    email: 'emailError',
    password: 'passwordError',
}
class LoginForm extends FormAbstract {
    onChange = (name, value) => {
        const mask = filters[name]
        if (mask && !mask.test(value)) {
            return
        }
        this.props.onChange({ [name]: value })
    }

    validate = () => {
        const { credentials } = this.props
        // eslint-disable-next-line
        const errors = requiredProperties.map(name => {
            const validator = validators[name]
            const value = credentials[name]
            if (!value || (validator && !validator.test(value))) {
                this.setFieldError(name, true)
                return true
            }
        })
        return !compact(errors).length
    }

    renderInput = (name, title) => {
        const emptyFieldError = t('requiredField')
        const { credentials } = this.props
        const { validationFlags } = this.state
        const value = credentials[name]
        const onClick = () => this.resetError(name)
        const onChange = _value => this.onChange(name, _value)
        const errorText = value ? t(fieldErrorMessage[name]) : emptyFieldError

        return (
            <InputWrapper
                error={validationFlags[name]}
                errorText={errorText}
                onChange={onChange}
                onClick={onClick}
                title={title}
                type={name}
                value={value}
            />
        )
    }
    render() {
        return (
            <Root>
                {this.renderInput('email', t('email'))}
                {this.renderInput('password', t('password'))}
            </Root>
        )
    }
}

LoginForm.propTypes = {
    credentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    setValidationFunc: PropTypes.func.isRequired,
}
export default LoginForm
