import React from 'react'
import { androidAppLink } from '../../../config'

const imgPath =
    ' https://www.placeb.ch/static/img/googleplay-app-store.b41157545a1f.png'

const styles = {
    width: '150px',
    maxWidth: '100%',
    maxHeight: '50px',
}
const href = `${androidAppLink}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`
class GooglePlayBadge extends React.Component {
    render() {
        return (
            <a
                className="google-play-badge"
                href={href}
                rel="noopener noreferrer"
                target="_blank"
            >
                <img alt="Get it on Google Play" src={imgPath} style={styles} />
            </a>
        )
    }
}
export default GooglePlayBadge
