import React from 'react'
import styled from 'styled-components'
import {desktopWidth} from '../../../config'
import {createTranslator} from '../../../services'
import {getPricingTexts, getRealPrice} from '../../../services/utils'
import {PlacebDuration} from '../../../types'
import {ButtonMain} from '../../components/ButtonMain'

const t = createTranslator('boxFinder')

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85px;
    padding: 15px;
    box-shadow: inset 0 1px 0 0 #dddddd;
    background-color: white;
    box-sizing: border-box;
    @media (min-width: ${desktopWidth}px) {
        width: 50%;
    }
`

const PriceContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    height: 100%;
`

const Bold = styled.div`
    font-weight: bold;
    margin-bottom: 5px;

    @media (min-width: ${desktopWidth}px) {
        font-size: 18px;
    }

    @media (max-width: 360px) {
        font-size: 14px;
    }
`

const Regular = styled.div`
    margin-bottom: 5px;

    @media (min-width: ${desktopWidth}px) {
        font-size: 18px;
    }

    @media (max-width: 360px) {
        font-size: 14px;
    }
`

const SmallSpan = styled.span`
    font-size: 12px;
`


const FooterButton = styled(ButtonMain)`
    padding-left: 0;
    padding-right: 0;
    flex: 1;
`

interface Props {
    className?: string
    duration: PlacebDuration
    category: any
    onClick: () => void
    preOpened: boolean
}

const BoxFinderFooter: React.FC<Props> = props => {

    const price = props.preOpened
        ? getRealPrice(props.duration) / 2
        : getRealPrice(props.duration)

    const pricingTexts = getPricingTexts(price, props.duration.unit, props.duration.duration, props.category, props.preOpened)

    return (
        <Footer className={props.className}>
            <PriceContainer>
                <Regular>{pricingTexts.unitDurationLabel} <SmallSpan>({t('inclVat')})</SmallSpan>:</Regular>
                <Bold>{pricingTexts.priceLabel}</Bold>
                {pricingTexts.priceLabelAfter && <Regular>{pricingTexts.priceLabelAfter}</Regular>}
            </PriceContainer>
            <FooterButton onClick={props.onClick}>
                {t('selectBox')}
            </FooterButton>
        </Footer>
    )
}

export default BoxFinderFooter
