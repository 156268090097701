import * as R from 'ramda'
import { handleActions } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { delay, mergeMap, mergeMapTo, take } from 'rxjs/operators'
import {
    changeLocation,
    getSelectedLocationObject,
} from '../../../redux/modules/productDuck'
import {
    geolocationLoaded,
    getGeolocation,
} from '../../../redux/modules/userGeolocation'
import {
    actionCreator,
    coordsToShort,
    globalizeSelectors,
} from '../../../services'

const moduleName = 'locationsMap'
const zurichCoords = { lat: 47.37, lng: 8.54 }
const defaultValue = { center: zurichCoords }

const createAction = actionCreator(moduleName)

export const changeMapCenter = createAction('CHANGE_MAP_CENTER')
export const mapMounted = createAction('MAP_MOUNTED')
export const mapPinClicked = createAction('MAP_PIN_CLICKED', location => ({
    location,
}))
export const goToUserLocation = createAction('GO_TO_USER_LOCATION', () => null)
export const fitBounds = createAction('FIT_BOUNDS')

const globalize = globalizeSelectors(R.prop('locationsMap'))
export default handleActions(
    {
        [changeMapCenter]: (state, { payload: { center } }) => ({
            ...state,
            center,
        }),
        [fitBounds.toString()]: (state, { payload: { bounds } }) => ({
            ...state,
            bounds,
        }),
    },
    defaultValue
)

const newGeocordinatesEpic = action$ =>
    action$.pipe(
        ofType(geolocationLoaded.toString()),
        mergeMap(({ payload: { geolocation } }) => [
            changeMapCenter({ center: coordsToShort(geolocation) }),
        ])
    )
const goToUserLocationEpic = (action$, state$) =>
    action$.pipe(
        ofType(goToUserLocation.toString()),
        mergeMap(() => {
            const geolocation = getGeolocation(state$.value)
            const selectedLocation = getSelectedLocationObject(state$.value)
            if (!geolocation) {
                return []
            }
            const bounds = new global.google.maps.LatLngBounds()
            ;[geolocation, selectedLocation]
                .filter(_ => _)
                .forEach(i => bounds.extend(coordsToShort(i)))
            return [fitBounds({ bounds })]
        })
    )
const mapMountedEpic = action$ =>
    action$.pipe(
        ofType(mapMounted.toString()),
        take(1),
        mergeMapTo([goToUserLocation()])
    )
const mapPinClickedEpic = (action$, state$) =>
    action$.pipe(
        ofType(mapPinClicked.toString()),
        delay(10),
        mergeMap(({ payload: { location } }) => {
            const selectedLocation = getSelectedLocationObject(state$.value)
            return selectedLocation === location
                ? []
                : [changeLocation({ location, deep: true, setPosition: true })]
        })
    )

export const _getMapCenter = state => state.center
export const _getBounds = state => state.bounds

export const getBounds = globalize(_getBounds)
export const getMapCenter = globalize(_getMapCenter)
export const epic = combineEpics(
    newGeocordinatesEpic,
    mapMountedEpic,
    goToUserLocationEpic,
    mapPinClickedEpic
)
