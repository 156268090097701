import React from 'react'
import styled from 'styled-components'
import CalculatorButton from './CalculatorButton'
import SizeTypeCalculatorContainer from './SizeTypeCalculatorContainer'

const Root = styled.div`
    position: relative;
    min-width: 0;
`

// type Props = {
//   visible: boolean,
//   showSelector: void => void,
//   className: string,
// };
class SizeTypeCalculatorPortal extends React.Component /*<Props>*/ {
    render() {
        const { className, visible } = this.props
        return (
            <Root className={className}>
                <CalculatorButton onClick={this.props.showSelector} />
                {visible && <SizeTypeCalculatorContainer />}
            </Root>
        )
    }
}
export default SizeTypeCalculatorPortal
