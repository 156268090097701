import React from 'react'
import styled from 'styled-components'
import RangeSelectorFace from './components/RadiusSelectorFace'
import RadiusSelector from './RadiusSelectorContainer'

const Root = styled.div`
    position: relative;
    min-width: 0;
`
// type Props = {
//   visible: boolean,
//   selectedRadius: string | number,
//   className: string,
//   showSelector: void => void,
// };
const RadiusSelectorPortal = (
    { selectedRadius, className, visible, showSelector } /*: Props*/
) => (
    <Root className={className}>
        <RangeSelectorFace onClick={showSelector} value={selectedRadius} />
        {visible && <RadiusSelector />}
    </Root>
)

export default RadiusSelectorPortal
