import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import PlaceBTheme from '../../../../styles/PlacebTheme'
import ButtonContainer from './ButtonContainer'
import BoxIcon from './offer-icn.svg'

const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const mainColor = PlaceBTheme.palette.primary.main
const Root = styled(ButtonContainer)``
const Icon = styled.img.attrs({ src: BoxIcon })`
    height: 24px;
    width: 24px;
    margin-right: 10px;
    margin-left: 15px;
`
const TitleWrapper = styled.div`
    color: ${mainColor};
    font-weight: bold;
    margin-right: 15px;
`
// type Props = {
//   className: string,
//   onClick: void => void,
// };
const ConfirmButton = ({ className, onClick } /*: Props*/) => (
    <Root className={className} onClick={onClick}>
        <Icon />
        <TitleWrapper>{t('showOffer')}</TitleWrapper>
    </Root>
)
export default ConfirmButton
