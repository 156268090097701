import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled, { css } from 'styled-components'
import { desktopWidth } from '../../../../config'
import { InputIcon, NoWrapText } from '../../components/commonComponents'
import SelectorHeaderCommon from '../../components/SelectorHeaderCommon'
import RadiusIcon from './radius-icn.svg'

const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const SearchPlace = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 45px;
    flex: 2;
    border: 1px solid #dddddd;
    margin-right: 10px;
    padding-right: 10px;
    ${props =>
        props.active &&
        css`
            border: none;
        `};
`
const Title = styled(NoWrapText)`
    margin-top: 5px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
`
const Icon = styled(InputIcon).attrs({ src: RadiusIcon })``
// type Props = {
//   onCrossButtonClick: void => void,
//   className: string,
// };
const Root = styled(SelectorHeaderCommon)`
    @media (min-width: ${desktopWidth}px) {
        display: none;
    }
`
const Header = ({ onCrossButtonClick, className } /*: Props*/) => (
    <Root className={className} onCrossButtonClick={onCrossButtonClick}>
        <SearchPlace active>
            <Icon />
            <Title>{t('radius')}</Title>
        </SearchPlace>
    </Root>
)

export default Header
