import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import { desktopWidth } from '../../../config'
// import type { PlacebCategory, PlacebLocation } from '../../../types';
const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)

const Root = styled.div`
    z-index: 12;
    display: flex;
    box-sizing: border-box;
    @media (min-width: ${desktopWidth}px) {
        width: 50%;
    }
    background: ${props => (props.animate ? ' #141977' : '#4a90e2')};
    transition: all 1s ease;
    position: fixed;
    padding: 15px;
    flex-direction: column;
    align-items: stretch;
    bottom: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    height: 180px;
    justify-content: center;
`

const Text = styled.div`
    color: white;
    font-weight: 100;
    margin-bottom: 15px;
    font-size: 18px;
    text-align: center;
`

const NoLocationBlock = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (max-width: 500px) {
        justify-content: space-between;
    }
`

const TransparentButton = styled.button`
    margin: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 6px 0;
    color: white;
    font-size: 14px;
    width: 50%;
    text-align: center;
    &:hover {
        background: #4281cc;
    }
    &:first-child {
        border-right: 1px solid white;
    }
    &:disabled {
        background: #8cb8ed;
        color: grey;
        cursor: auto;
    }
`

const TransparentButtonBlock = styled.div``
const ButtonBlock = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid white;
    border-radius: 2px;
`
const Label = styled.div`
    padding: 8px 12px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 16px;
`

// type Props = {
//   setAnimateFunction: ((void) => void) => void,
//   className: string,
//   categoryObject: PlacebCategory,
//   selectedRadius: ?number,
//   nearestLocation: PlacebLocation,
//   changeLocation: PlacebLocation => void,
//   cheapestLocation: PlacebLocation,
//   smallerAvailableCategory: PlacebCategory,
//   largerAvailableCategory: PlacebCategory,
//   changeCategory: PlacebCategory => void,
// };
class MainElement extends React.PureComponent /*<Props>*/ {
    state = { animate: false }

    animateFooter = null
    componentDidMount() {
        const { setAnimateFunction } = this.props
        setAnimateFunction && setAnimateFunction(this.animate)
    }
    componentWillUnmount() {
        const { setAnimateFunction } = this.props
        setAnimateFunction && setAnimateFunction(null)
    }
    animate = () => {
        this.setState(() => ({ animate: true }))
        setTimeout(() => this.setState(() => ({ animate: false })), 1000)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.animate && this.animate()
        const { categoryObject, selectedRadius } = nextProps
        if (!categoryObject && selectedRadius !== this.props.selectedRadius) {
            this.animateFooter && this.animateFooter()
        }
    }
    // getSnapshotBeforeUpdate(prevProps) {
    //   this.animate && this.animate();
    //   const { categoryObject, selectedRadius } = this.props;
    //   if (!categoryObject && selectedRadius !== prevProps.selectedRadius) {
    //     this.animate && this.animate();
    //   }
    //   return null;
    // }
    changeToNearestLocation = () => {
        const { changeLocation, nearestLocation } = this.props
        nearestLocation && changeLocation(nearestLocation)
    }
    changeToCheapestLocation = () => {
        const { changeLocation, cheapestLocation } = this.props
        cheapestLocation && changeLocation(cheapestLocation)
    }
    increaseCategory = () => {
        const { changeCategory, largerAvailableCategory } = this.props
        largerAvailableCategory && changeCategory(largerAvailableCategory)
    }
    decreaseCategory = () => {
        const { changeCategory, smallerAvailableCategory } = this.props
        smallerAvailableCategory && changeCategory(smallerAvailableCategory)
    }
    render() {
        const {
            className,
            nearestLocation,
            cheapestLocation,
            smallerAvailableCategory,
            largerAvailableCategory,
        } = this.props
        return (
            <Root animate={this.state.animate} className={className}>
                <Text>{t('noBoxes')}</Text>
                <NoLocationBlock>
                    <TransparentButtonBlock>
                        <Label>{t('changeSize')}</Label>
                        <ButtonBlock>
                            <TransparentButton
                                disabled={!smallerAvailableCategory}
                                onClick={this.decreaseCategory}
                            >
                                -
                            </TransparentButton>
                            <TransparentButton
                                disabled={!largerAvailableCategory}
                                onClick={this.increaseCategory}
                            >
                                +
                            </TransparentButton>
                        </ButtonBlock>
                    </TransparentButtonBlock>
                    <TransparentButtonBlock>
                        <Label>{t('changeLocation')}</Label>
                        <ButtonBlock>
                            <TransparentButton
                                disabled={!nearestLocation}
                                onClick={this.changeToNearestLocation}
                            >
                                {t('nearest')}
                            </TransparentButton>
                            <TransparentButton
                                disabled={!cheapestLocation}
                                onClick={this.changeToCheapestLocation}
                            >
                                {t('cheapest')}
                            </TransparentButton>
                        </ButtonBlock>
                    </TransparentButtonBlock>
                </NoLocationBlock>
            </Root>
        )
    }
}
const NoBoxesFooter = ({
    onClick,
    className,
    setAnimateFunction,
    ...props
}) => {
    const Demon = styled(MainElement)`
        position: relative;
        visibility: hidden;
    `
    return [
        <MainElement
            key={1}
            className={className}
            onClick={onClick}
            setAnimateFunction={setAnimateFunction}
            {...props}
        />,
        <Demon
            key={2}
            className={className}
            onClick={onClick}
            setAnimateFunction={setAnimateFunction}
            {...props}
        />,
    ]
}

export default NoBoxesFooter
