import * as recalculateCheaperBoxDuck from './recalculateCheaperBox'

const { moduleName, default: _reducer } = recalculateCheaperBoxDuck
export { default } from './CheaperModalContainer'
export const reducer = { [moduleName]: _reducer }
export {
    showCheaperBoxDetails,
    getCheapestBox,
    recalculateCheaperBox,
} from './recalculateCheaperBox'
