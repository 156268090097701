import React from 'react'
import { pure } from 'recompose'
import styled, { css } from 'styled-components'
import { desktopWidth } from '../../../../config'
import PlaceBTheme from '../../../../styles/PlacebTheme'
import { InputIcon } from '../../components/commonComponents'
import SelectorHeaderCommon from '../../components/SelectorHeaderCommon'
import Input from './Input'
import SearchIcon from './search-icn.svg'

const Icon = styled(InputIcon).attrs({ src: SearchIcon })``

const SearchPlace = styled.div`
    display: flex;
    min-width: 0;
    align-items: center;
    box-sizing: border-box;
    height: 45px;
    flex: 2;
    padding-right: 10px;
    @media (max-width: ${desktopWidth - 1}px) {
        ${props =>
            props.active &&
            css`
                border: 1px solid ${PlaceBTheme.palette.primary.main};
            `};
    }
`
// type Props = {
//   inputValue: string,
//   onChangeInputValue: string => void,
//   onCrossButtonClick: void => void,
//   onFocus: void => void,
// };
const Header = (
    { inputValue, onChangeInputValue, onCrossButtonClick, onFocus } /*: Props*/
) => (
    <SelectorHeaderCommon onCrossButtonClick={onCrossButtonClick}>
        <SearchPlace active>
            <Icon />
            <Input
                onChange={onChangeInputValue}
                onFocus={onFocus}
                value={inputValue}
            />
        </SearchPlace>
    </SelectorHeaderCommon>
)

export default pure(Header)
