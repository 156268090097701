// allow overwriting the config with local conf (used in CMS)

export const clientId =
    // @ts-ignore
    window.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID
export const clientSecret =
    // @ts-ignore
    window.REACT_APP_CLIENT_SECRET || process.env.REACT_APP_CLIENT_SECRET
export const endpoint =
    // @ts-ignore
    window.REACT_APP_ENDPOINT || process.env.REACT_APP_ENDPOINT
export const stripeKey =
    // @ts-ignore
    window.REACT_APP_STRIPE_KEY || process.env.REACT_APP_STRIPE_KEY

export const gtmKey = process.env.REACT_APP_GTM_CONTAINER
export const googleGeolocationKey = process.env.REACT_APP_GOOGLE_API_KEY
export const iosAppLink = process.env.REACT_APP_IOS_APP_LINK
export const androidAppLink = process.env.REACT_APP_ANDROID_APP_LINK
export const desktopWidth = process.env.REACT_APP_DESKTOP_WIDTH

export default {
    clientId,
    clientSecret,
    endpoint,
    stripeKey,
    gtmKey,
    googleGeolocationKey,
    iosAppLink,
    androidAppLink,
    desktopWidth,
}
