import {
    dimensions,
    durations,
    durationsMultiple,
    sizeTypes,
} from '../../../translations/frCommon'

export default {
    radius: 'Umkreis',
    allSwitzerland: 'Ganze Schweiz',
    'showOffer:': 'Angebot anzeigen',
    currentPosition: 'Aktueller Standort',
    calculatorQuestion: 'Was passt in diese Box?',
    chooseThisSize: 'Diese Grösse wählen',
    dimensions,
    sizeTypes,
    size: 'Grösse',
    selectBox: 'Diese Box auswählen',
    inclVat: 'TVA comp.',
    per: 'pro',
    sizeCalculator: 'Grössenberater',
    duration: 'Dauer',
    location: 'Standort',
    noBoxes: 'Es gibt leider keine Boxen dieser Grösse mehr an diesem Standort',
    increaseRange: 'Standort ändern?',
    preOpenedMessage:
        '50% Discount! Preopening Standort. Zugang nur auf Anfrage.',
    noCategories: 'Keine Kategorie für diese Grösse',
    durations,
    durationsMultiple,
    durationLabel: ['1 Woche', '1 Monat', '3 Monate', '6 Monate', '1 Jahr'],
    price: 'Preis',
    minimumRentalPeriod: 'Mindestdauer',
    notificationText:
        'Es gibt eine günstigere %{category} Box an einem anderen Standort.',
    cancelButton: 'Nein Danke',
    agreeButton: 'Angebote sehen',
    changeSize: 'Grösse anpassen',
    changeLocation: 'Standort wechseln',
    nearest: 'Nächster',
    cheapest: 'Günstigster',
    priceDiscountPrefix: 'Erste',
    includeDiscount: 'Abzüglich Gutscheincode Rabatt',
    priceRegularPrefix: 'Danach',
    sizeTypesStuff: {
        xs: ['15-20 Umzugskartons', 'Fahrrad'],
        s: [
            '15-20 Umzugskartons',
            '1 Regal',
            '1 Fahrrad',
            '4 Stühle',
            '1 Esstisch',
            '1 Bett mit Matratze',
            '1 Schreibtisch',
            '1 Fernseher',
        ],
        m: [
            '15-20 Umzugskartons',
            '1 Regal (demontiert)',
            '1 Kommode',
            '1 Fahrrad',
            '6 Stühle',
            '1-3 Bett mit Matratze (demontiert)',
            '1-3-türiger Wandschrank (demontiert)',
            '1 Esstisch',
            '1 Schreibtisch',
            '1 Fernseher',
            '1 Sideboard',
            '1 Serviertisch',
            '1 Sofa',
            '1 Lounge für 4 Personen',
        ],
        l: [
            '20-25 Umzugskartons',
            '1 Regal',
            '2 Kommode',
            '1 Fahrrad',
            '8 Stühle',
            '2-4 Bett mit Matratze (demontiert)',
            '2-4-türiger Wandschrank  (demontiert)',
            '1 Esstisch',
            '1 Schreibtisch',
            '1 Fernseher',
            '1 Sideboard',
            '1 Serviertisch',
            '1 grosses Sofa',
            '1 Lounge für 4 Personen',
            '1 Waschmaschine',
            '1 Trockner',
        ],
        xl: [
            '35-45 Umzugskartons',
            '2-4 shelves',
            '3-4 Kommode',
            '1 Fahrrad',
            '10 Stühle',
            '3-5 Bett mit Matratze (demontiert)',
            '3-5-türiger Wandschrank (demontiert)',
            '1 Esstisch',
            '1 kitchen table',
            '3 Schreibtischs',
            '1 Fernseher',
            '2 Sideboards',
            '1 Serviertisch',
            '1 grosses Sofa',
            '1 Lounge für 8 Personen',
            '1 Waschmaschine',
            '1 Trockner ',
        ],
    },
}
