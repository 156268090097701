import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import type { Dispatch } from 'redux';
import {
    getAvailableCategories,
    getCategories,
    getSelectedCategory,
    userChangedSize,
} from '../../../redux/modules/productDuck'
import SizePicker from './SizePicker'

// import type { Action } from '../../../types';

function mapStateToProps(state) {
    const availableCategories = getAvailableCategories(state)
    const selectedCategory = getSelectedCategory(state)
    const categoryOptions = getCategories(state)
    const isCategoriesExisting = !!categoryOptions
    return {
        availableCategories,
        options: categoryOptions,
        value: selectedCategory,
        isCategoriesExisting,
    }
}

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        { onChange: size => userChangedSize({ size }) },
        dispatch
    )

const SizePickerContainer = props =>
    props.isCategoriesExisting ? <SizePicker {...props} /> : null
export default connect(mapStateToProps, mapDispatchToProps)(SizePickerContainer)
