export default {
    header: 'Étape 2 : Tes détails',
    fillDetails: 'Compléter les détails',
    loginButton: 'Se connecter avec un compte existant',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    company: 'Société (optionnel)',
    country: 'Pays',
    email: 'E-Mail',
    address: 'Adresse',
    address2: `Complément d'adresse (optionnel)`,
    zip: 'Code postal',
    city: 'Ville',
    phone: 'Téléphone portable',
    phoneHelperText: `Merci d'indiquer un numéro de téléphone portable`,
    nextStep: 'Prochaine étape',
    confirm: 'Prochaine étape',
    required: 'Ce champ est obligatoire',
    invalidCountry: 'Pays non valide',
    incorrectEmail: 'E-mail incorrect',
    passwordError: 'Le mot de passe est trop court',
    incorrectPhone: 'Numéro de téléphone mobile invalide',
    incorrectZip: 'code postal non valide',
    loginModal: {
        logIn: 'Se connecter',
        email: 'E-Mail',
        cancel: 'Annuler',
        password: 'Mot de passe',
        emailError: 'E-mail erroné',
        passwordError: 'Le mot de passe est trop court',
        requiredField: 'Ce champ est obligatoire',
    },
}
