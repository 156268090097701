import * as R from 'ramda'
import { connect } from 'react-redux'
import { reduxForm, SubmissionError } from 'redux-form'
import { scrollToFirstError } from '../../../core/utils'
import {
    getSelectedCategoryObject,
    getSelectedDurationObject,
    getSelectedLocationObject,
    getPreopenedFlag,
    openBoxFinder,
} from '../../../redux/modules/productDuck'
import { totalPricePerMonth } from '../checkoutDuck'
import { openUserDetails } from '../checkoutDuck/actions'
import moduleName from '../moduleName'
import Booking from './booking'

const mapStateToProps = state => ({
    location: getSelectedLocationObject(state),
    duration: getSelectedDurationObject(state),
    price: totalPricePerMonth(state),
    category: getSelectedCategoryObject(state),
    preOpened: getPreopenedFlag(state),
    openBoxFinder,
})

const reduxConnector = connect(mapStateToProps)
const onSubmit = ({ insuranceValue: value }) => {
    if (value !== 'no' && (!value || isNaN(+value))) {
        throw new SubmissionError({ insuranceValue: 'error' })
    }
}
const formCreator = reduxForm({
    form: moduleName,
    onSubmit,
    onSubmitFail: scrollToFirstError,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    pure: false,
    initialValues: { insuranceValue: '', startDate: new Date() },
    onSubmitSuccess: openUserDetails,
})
export default R.compose(reduxConnector, formCreator)(Booking)
