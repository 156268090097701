import * as R from 'ramda'
import { prop } from 'ramda'
import { createAction as createReduxAction } from 'redux-actions'
import handleActions from 'redux-actions/es/handleActions'
import { combineEpics, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import BoxSizeTypes, {
    getSizeTypeByCategory,
} from '../../../constants/BoxSizeTypes'
import {
    getSelectedCategory,
    getSelectedLocationObject,
} from '../../../redux/modules/productDuck'
import { globalizeSelectors } from '../../../services'

const moduleName = 'sizeTypeCalculator'
const createAction = (action, ...args) =>
    createReduxAction(`${moduleName}/${action}`, ...args)
export const sizeChangedByCalculator = createAction(
    'CATEGORY_CHANGED_BY_CALCULATOR'
)
export const setShowCalculator = createAction('SET_SHOW_CALCULATOR')
export const showCalculatorSelector = createAction('SHOW_SELECTOR', () => null)
export const hideSelector = createAction('HIDE_SELECTOR', () => null)
export const changeCategory = createAction('CHANGE_CATEGORY')
export const changeSizeType = createAction('CHANGE_SIZE_TYPE')
export const confirmChoose = createAction('CONFIRM_CHOOSE', () => null)

const globalize = globalizeSelectors(prop(moduleName))

const defaultState = {
    showCalculator: false,
    category: null,
    sizeType: 'XS',
}
const mergeState = object => state => R.merge(state, object)

export default handleActions(
    {
        [setShowCalculator]: (state, { payload: { flag: showCalculator } }) =>
            R.merge(state, { showCalculator }),
        [showCalculatorSelector]: mergeState({ showCalculator: true }),
        [hideSelector]: mergeState({ showCalculator: false }),
        [changeCategory]: (state, { payload: { category } }) =>
            R.merge(state, {
                category: category,
                sizeType: getSizeTypeByCategory(category) || 'xs',
            }),
        [changeSizeType]: (state, { payload: { sizeType } }) => ({
            ...state,
            sizeType: sizeType,
            category: BoxSizeTypes[sizeType.toLowerCase()].options[0],
        }),
    },
    defaultState
)

const _getShowCalculatorFlag = prop('showCalculator')
const _getCategory = prop('category')
const _getSizeType = prop('sizeType')
const _getSizeTypeObject = state =>
    BoxSizeTypes[_getSizeType(state).toLowerCase()]
export const getSizeType = globalize(_getSizeType)
export const getShowCalculatorFlag = globalize(_getShowCalculatorFlag)
export const getCategory = globalize(_getCategory)
export const getSizeTypeObject = globalize(_getSizeTypeObject)
export const getCategoryObject = state => {
    const category = getCategory(state)
    const selectedLocation = getSelectedLocationObject(state)
    return (
        (selectedLocation &&
            selectedLocation.categories.find(
                item => item.title === category
            )) ||
        null
    )
}

const showSelectorEpic = (action$, state$) =>
    action$.pipe(
        ofType(showCalculatorSelector.toString()),
        map(() => {
            const category = getSelectedCategory(state$.value)
            return category
                ? changeCategory({ category })
                : changeSizeType({ sizeType: 'xs' })
        })
    )
const confirmChooseEpic = (action$, state$) =>
    action$.pipe(
        ofType(confirmChoose.toString()),
        mergeMap(() => {
            const size = getCategory(state$.value)
            return [sizeChangedByCalculator({ size }), hideSelector()]
        })
    )

export const epic = combineEpics(showSelectorEpic, confirmChooseEpic)
