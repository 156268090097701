import * as locationsMapDuck from './locationsMapDuck'

export { default } from './LocationMapContainer'

export const reducer = {
    locationsMap: locationsMapDuck.default,
}

export const epic = locationsMapDuck.epic

export {
    goToUserLocation,
    mapPinClicked,
    changeMapCenter,
} from './locationsMapDuck'
