import booking from '../Booking/translations/fr'
import bookingSuccess from '../BookingSuccess/translations/fr'
import payment from '../Payment/translations/fr'
import userDetails from '../UserDetails/translations/fr'
import account from '../Account/translations/fr'

export default {
    booking,
    bookingSuccess,
    userDetails,
    payment,
    account,
}
