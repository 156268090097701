import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Clear from '@material-ui/icons/Clear'

import { desktopWidth } from '../../../../config'

const Fixed = styled.div`
    touch-action: none;
    position: fixed;
    overflow-x: hidden;
    z-index: 999;
    top: 0;
    bottom: 0;
    background: white;
    flex: 1;
    flex-direction: column;
    display: flex;
    right: 0;
    left: 0;
    @media (min-width: ${desktopWidth}px) {
        top: 10%;
        right: 10%;
        bottom: 10%;
        left: 10%;
        border: 1px solid #dddddd;
    }
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
    position: relative;
    font-family: Helvetica;
    right: 0;
    left: 0;
    line-height: 22px;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    top: 0;
    padding: 22px 20px 21px 30px;
    font-size: 18px;
    font-weight: bold;
    z-index: 1000;
`

const ClearTop = styled(Clear)`
    float: right;
    cursor: pointer;
`

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 20px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
    overflow: auto;
`

class BookingModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    closeModal = () => {
        this.props.onClose()
    }

    render() {
        const { show, modalTitle, modalText, className } = this.props
        return (
            <Fixed className={className} open={show}>
                <Header>
                    {modalTitle}
                    <ClearTop onClick={this.closeModal} />
                </Header>
                <Content>{modalText}</Content>
            </Fixed>
        )
    }
}

BookingModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    modalText: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
}
export default BookingModal
