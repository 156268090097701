import { CardElement } from '@stripe/react-stripe-js'
import rest from '../../../../services/rest'

export const createAndConfirmSetupIntent = async (
    token,
    userId,
    stripe,
    elements
) => {
    const { client_secret: clientSecret } = await rest.createSetupIntent(
        token,
        userId
    )

    if (!stripe || !elements || !clientSecret) {
        throw new Error('Setup intent creation failed')
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
        throw new Error('Missing card element')
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardElement },
    })

    if (result.error) {
        throw result.error
    }

    if (!result.setupIntent) {
        throw new Error('Card confirmation failed')
    }

    const card = await rest.updateDefaultCard(
        {
            paymentMethod: result.setupIntent.payment_method,
        },
        userId
    )

    result.setupIntent.card = card

    return result.setupIntent
}
