import React from 'react'
import { pure } from 'recompose'
import StaticInput from '../../components/StaticInput'
import SearchIcon from './search-icn.svg'

// type Props = {
//   value: string,
//   onClick: void => void,
//   className?: string,
// };
const LocationSelectorFace = ({ value, onClick, className } /*: Props*/) => (
    <StaticInput
        className={className}
        icon={SearchIcon}
        onClick={onClick}
        value={value}
    />
)

export default pure(LocationSelectorFace)
