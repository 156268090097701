import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLocations } from '../../../redux/modules/locationsDuck'
import { getGeolocation } from '../../../redux/modules/userGeolocation'
import {
    getPlaceSuggestions,
    getSelectedPosition,
    getShowLocationSelectorFlag,
    hideLocationSelector,
    loadSuggestions,
    showLocationSelector,
    userChangedPosition,
} from './locationSelectorDuck'
import LocationSelectorPortal from './LocationSelectorPortal'
// import type { Action } from '../../../types';
const mapStateToProps = state => ({
    locations: getLocations(state),
    value: getSelectedPosition(state),
    userGeolocation: getGeolocation(state),
    suggestions: getPlaceSuggestions(state),
    showSelector: getShowLocationSelectorFlag(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            onChange: userChangedPosition,
            onInputChange: loadSuggestions,
            hideLocationSelector,
            showLocationSelector,
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationSelectorPortal)
