export default {
    successLabel:
        'Merci ! Tu as réservé ta box avec succès. Voici les prochaines étapes.',
    nextSteps: 'Prochaine étape',
    installMobileApp: `Installer l'application placeB sur son téléphone mobile`,
    verifyEmail: `Vérification de l'e-mail`,
    verifyPhone: 'Vérification du numéro de téléphone',
    personalInformation: 'Informations',
    bookedBoxes: 'Boxes réservées',
    install: `Installer l'app placeB maintenant`,
    installSubheader: `Obtiens maintenant la clé pour plus d'espace et profites de tous les avantages`,
    myBoxes: 'Mes boxes',
}
