import React from 'react'
import { findDOMNode } from 'react-dom'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import { getPricingTexts } from '../../../services/utils'
import FlexContainer from '../../components/FlexContainer'
import PriceFooter from '../../components/PriceFooter'
import FullScreen from '../components/FullScreen'
import Login from '../components/Login/Login'
import ProfileForm from './ProfileForm'
import UserDetailsHeader from './UserDetailsHeader'

const tPrefix = 'checkoutProcess.userDetails'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const iphoneDevice = !!navigator.platform && /iPhone/.test(navigator.platform)

const Root = styled(FullScreen)`
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: ${iphoneDevice ? 'touch' : 'no'};
`

const MaxWidthWrapper = styled.div`
    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const UserDetailWrapper = styled(FlexContainer)`
    align-items: stretch;
`

const UserDetailsContainer = styled.div`
    padding: 0 19px;
    font-family: Helvetica, sans-serif;
    margin-bottom: 25px;
`

const Title = styled.h3`
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: #000000;
    margin-top: 27px;

    @media (max-width: 800px) {
        margin-bottom: -7px;
    }
`

class UserDetails extends React.Component /*<Props>*/ {
    userDetailBlock = React.createRef()
    componentWillUnmount() {
        // eslint-disable-next-line react/no-find-dom-node
        const topBlock = findDOMNode(this.userDetailBlock.current)
        topBlock.scrollIntoView()
    }

    render() {
        const {
            className,
            price,
            openBooking,
            login,
            duration,
            category,
            preOpened,
            openPayment,
            onSubmit,
            user,
        } = this.props

        const pricingTexts = getPricingTexts(
            price.price,
            duration.unit,
            duration.duration,
            category,
            preOpened,
            price.insurancePerMonth
        )

        return (
            <Root ref={this.userDetailBlock} className={className}>
                <UserDetailWrapper>
                    <UserDetailsHeader onButtonClick={openBooking} />
                    <MaxWidthWrapper>
                        {!user && <Login login={login} />}
                        <UserDetailsContainer>
                            <Title>{t('fillDetails')}</Title>
                            <ProfileForm onSubmitSuccess={openPayment} />
                        </UserDetailsContainer>
                    </MaxWidthWrapper>
                </UserDetailWrapper>
                <PriceFooter
                    buttonTitle={t('nextStep')}
                    onClick={onSubmit}
                    pricingTexts={pricingTexts}
                    relative={iphoneDevice}
                />
            </Root>
        )
    }
}

export default UserDetails
