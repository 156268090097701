// import type {
//   GeoPoint,
//   PlacebLocation,
//   PriceInLocation,
//   Suggestion,
// } from '../../../types';
import * as R from 'ramda'
import * as React from 'react'
import { Circle } from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import styled from 'styled-components'
import RANGES from '../../../constants/Ranges'
import { coordsToShort } from '../../../services/utils'
import PlaceBTheme from '../../../styles/PlacebTheme'
import GoogleMap from './components/GoogleMap'
import MapPinSelected from './components/map-pin-selected.svg'
import MapPinUnactive from './components/map-pin-unselected.svg'
import PositionDot from './components/PositionDot'
import UserLocationMarker from './components/UserLocationMarker'

const Root = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

const MarkerLabel = styled.div`
    background-color: ${PlaceBTheme.palette.primary.main};
    font-size: 16px;
    padding: 3px;
    color: white;
    border: 1px solid white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    z-index: ${({ zIndex }) => zIndex};
`

const CircleWrapper = styled(Circle).attrs({
    options: {
        strokeColor: '#80C700',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#80C700',
        fillOpacity: 0.3,
        visible: true,
    },
})``

const getZoom = range =>
    range === 5000
        ? 12
        : range === 10000
        ? 11
        : range === 20000
        ? 10
        : range === 40000
        ? 9
        : 7

// export type Props = {
//   locations: Array<PlacebLocation>,
//   value: PlacebLocation,
//   range: ?number,
//   userPosition: Coordinates,
//   selectedPosition: Coordinates | Suggestion,
//   onLocationClick: PlacebLocation => any,
//   className: string,
//   center: GeoPoint,
//   changeMapCenter: ({ center: GeoPoint }) => any,
//   hideLocationSelector: void => any,
//   prices: Array<PriceInLocation>,
//   bounds: any,
// };
class LocationMap extends React.Component /*<Props>*/ {
    UNSAFE_componentWillReceiveProps(nextProps /*: Props*/) {
        if (
            nextProps.bounds !== this.props.bounds &&
            !R.isEmpty(this.mapRef.current.context)
        ) {
            this.mapRef.current.fitBounds(nextProps.bounds)
        }
    }

    mapRef /*: { current: IGoogleMap }*/ = React.createRef()

    onCenterChanged = () => {
        const getCenter = this.mapRef.current.getCenter()
        const center = {
            lat: getCenter.lat(),
            lng: getCenter.lng(),
        }
        this.props.changeMapCenter({ center })
    }

    render() {
        const {
            locations,
            value,
            className,
            onLocationClick,
            hideLocationSelector,
            center,
            selectedPosition,
            range,
            userPosition,
            prices,
        } = this.props
        return (
            <Root className={className} onClick={hideLocationSelector}>
                <GoogleMap
                    ref={this.mapRef}
                    center={center}
                    onCenterChanged={this.onCenterChanged}
                    zoom={getZoom(range)}
                >
                    {range !== RANGES[RANGES.length - 1] &&
                        selectedPosition && (
                            <CircleWrapper
                                center={coordsToShort(selectedPosition)}
                                radius={range}
                            />
                        )}
                    {userPosition && (
                        <UserLocationMarker
                            position={coordsToShort(userPosition)}
                        />
                    )}
                    {selectedPosition && selectedPosition.isSuggestion && (
                        <PositionDot
                            position={coordsToShort(selectedPosition)}
                        />
                    )}
                    {locations &&
                        locations.map((item, index) => {
                            const active = item === value
                            const position = coordsToShort(item)

                            return (
                                <MarkerWithLabel
                                    key={index}
                                    active={active}
                                    icon={{
                                        scaledSize: {
                                            height: 48,
                                            width: 35,
                                        },
                                        url: active
                                            ? MapPinSelected
                                            : MapPinUnactive,
                                    }}
                                    labelAnchor={position}
                                    onClick={() => onLocationClick(item)}
                                    position={position}
                                >
                                    {prices[item.id] ? (
                                        <MarkerLabel
                                            zIndex={
                                                item === value
                                                    ? 9999
                                                    : item.longitude
                                            }
                                        >
                                            {Math.ceil(+prices[item.id])}
                                            .-
                                        </MarkerLabel>
                                    ) : (
                                        <div />
                                    )}
                                </MarkerWithLabel>
                            )
                        })}
                </GoogleMap>
            </Root>
        )
    }
}

export default LocationMap
