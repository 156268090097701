import Cookie from 'cookie'
import { loadTranslations, setLocale } from 'react-redux-i18n-lite'
import { combineEpics, ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import translations from '../../../translations'
import { authorizeAsGuest } from '../auth'
import { loadGeolocation } from '../userGeolocation'
import { initApp, setLanguage } from './actions'

const setLanguageEpic = action$ =>
    action$.pipe(
        ofType(setLanguage.toString()),
        mergeMap(() => {
            const cookies = Cookie.parse(document.cookie)
            const browserLanguage = cookies['language-code']
            const locale = translations[browserLanguage]
                ? browserLanguage
                : 'en'
            return [setLocale(locale)]
        })
    )
const initAppEpic = action$ =>
    action$.pipe(
        ofType(initApp.toString()),
        mergeMap(() => {
            if (!window.dataLayer) {
                window.dataLayer = []
            }
            return [
                authorizeAsGuest(),
                loadTranslations(translations),
                setLanguage(),
                loadGeolocation(),
            ]
        })
    )
// const showMessageEpic = action$ =>
//   action$.pipe(
//     ofType(showMessage.toString()),
//     tap(({ payload }) => window.alert(payload)),
//     ignoreElements()
//   );
export default combineEpics(
    setLanguageEpic,
    initAppEpic
    //showMessageEpic,
)
