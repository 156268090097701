export default {
    required: 'Champ obligatoire',
    invalidCard: 'Carte non valable ou couverture insuffisante',
    save: 'Enregistrer',
    currentCard: 'Carte de crédit actuelle',
    noCard: 'Pas de',
    title: 'Compte',
    updateCard: 'Ajouter ou mettre à jour une carte de crédit',
    updated: 'Compte actualisé',
}
