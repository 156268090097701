import { connect } from 'react-redux'
import { compose } from 'redux'
import history from '../../core/history'
import { getPreopenedFlag } from '../../redux/modules/productDuck'
import BoxFinder from './BoxFinder'

const mapStateToProps = state => ({
    isLocationPreopened: getPreopenedFlag(state),
    openMap: () => history.push('/map'),
})

export default compose(connect(mapStateToProps))(BoxFinder)
