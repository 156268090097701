import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import * as R from 'ramda'
import React from 'react'
import { connect, Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from '../redux/configureStore'
import { getLanguage } from '../redux/modules/coreDuck'
import PlacebTheme from '../styles/PlacebTheme'
import ApplicationContext from './ApplicationContext'
import Loader from './loader'

const { store, persistor } = configureStore()

const withProvider = Component => props =>
    (
        <Provider store={store}>
            <Component {...props} />
        </Provider>
    )

const withPersistGate = Component => props =>
    (
        <PersistGate loading={<Loader />} persistor={persistor}>
            <Component {...props} />
        </PersistGate>
    )

const withContext = Component => {
    const mapStateToProps = state => ({
        contextValue: {
            locale: getLanguage(state),
        },
    })

    const Result = props => {
        return (
            <ApplicationContext.Provider value={props.contextValue}>
                <Component {...props} />
            </ApplicationContext.Provider>
        )
    }

    return connect(mapStateToProps)(Result)
}

// const withStripe = Component => props => {
//     const [stripe, setStripe] = useState(null)

//     useEffect(() => {
//         if (window.Stripe) {
//             setStripe(window.Stripe(stripeKey))
//         } else {
//             document
//                 .querySelector('#stripe-js')
//                 .addEventListener('load', () => {
//                     setStripe(window.Stripe(stripeKey))
//                 })
//         }
//     }, [])

//     return <Component {...props} />
// }

const withTheme = Component => props =>
    (
        <MuiThemeProvider theme={PlacebTheme}>
            <Component {...props} />
        </MuiThemeProvider>
    )

export default R.compose(
    withPersistGate,
    withProvider,
    withContext,
    // withStripe,
    withTheme
)
