export default {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    country: '',
    city: '',
    zip: '',
    street: '',
    additionalStreet: '',
}
