import NearMeIcon from '@material-ui/icons/NearMe'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import { ListItem } from '../../components/commonComponents'
// import type { Position } from '../../../../types';

const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const iconStyle = {
    margin: '0 9px 0 10px',
}
const UserPositionNode = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    flex: 1;
    height: 100%;
`
const Thin = styled.div`
    font-weight: 100;
    font-size: 14px;
`
// type Props = {
//   item: Position,
//   onClick: void => void,
// };

const UserPositionItem = ({ item, onClick } /*: Props*/) => (
    <ListItem onClick={onClick}>
        <NearMeIcon style={iconStyle} />
        <UserPositionNode>
            <div>{t('currentPosition')}</div>
            {item.name && (
                <Thin>
                    <div>{item.name}</div>
                </Thin>
            )}
        </UserPositionNode>
    </ListItem>
)

export default UserPositionItem
