import geolib from 'geolib'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLocations } from '../../../redux/modules/locationsDuck'
import {
    getSelectedLocationObject,
    userSelectedLocationFromSlider,
} from '../../../redux/modules/productDuck'
import { getSelectedPosition } from '../LocationSelector'
import LocationsSlider from './LocationsSlider'

const closestLocationsList = (point, locations) => {
    const sorter = (prev, next) => {
        const prevDistance = geolib.getDistanceSimple(point, prev)
        const nextDistance = geolib.getDistanceSimple(point, next)
        return prevDistance - nextDistance
    }
    return [...locations].sort(sorter)
}

function mapStateToProps(state) {
    const selectedLocation = getSelectedLocationObject(state)
    const selectedPosition = getSelectedPosition(state)
    const locations = getLocations(state)
    const locationsSorted = closestLocationsList(
        selectedPosition ? selectedPosition : selectedLocation,
        locations
    )
    return {
        options: locationsSorted,
        value: selectedLocation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onChange: location => userSelectedLocationFromSlider({ location }),
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsSlider)
