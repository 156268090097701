import TextField from '@material-ui/core/TextField'
import React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Field } from 'redux-form'
import styled from 'styled-components'
import { createTranslator } from '../../../../services'
import FlexContainer from '../../../components/FlexContainer'
import RowForTwo from '../RowForTwo'
import RowForTwoSmallUp from '../RowForTwoSmallUp'

const t = createTranslator('checkoutProcess.userDetails')
const Root = styled(FlexContainer)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 15px;
    @supports (-ms-ime-align: auto) {
        display: block;
    }
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    @-moz-document url-prefix() {
        display: block;
    }
`
const InputWrapper = styled(TextField)`
    width: 100%;
    && {
        margin-top: 34px;
        @supports (-ms-ime-align: auto) {
            width: 100% !important;
        }
        @media screen and (-ms-high-contrast: active),
            screen and (-ms-high-contrast: none) {
            width: 100% !important;
        }
        @-moz-document url-prefix() {
            width: 100% !important;
        }

        @media (max-width: 600px) {
            margin-top: 27px;
        }
    }
`
const PhoneBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    align-content: flex-end;
    border-bottom: 2px solid #858585;
    margin-top: 29px;

    @media (max-width: 600px) {
        margin-top: 22px;
    }
`

const HelperText = styled.div`
    color: rgba(0, 0, 0, 0.54);
    margin-top: 8px;
    font-size: 0.75rem;
    position: absolute;
    top: 36px;
`

const ErrorText = styled(HelperText)`
    color: #f44336;
`

const ReactPhoneInputStyled = styled(ReactPhoneInput).attrs({
    containerStyle: {
        width: '100%',
        bottom: '1px',
    },
    inputStyle: {
        width: '100%',
        border: 0,
    },
})``

const renderField = ({ input, type, meta, placeholder, autocomplete }) => {
    const error = meta.touched && meta.error && t(meta.error)
    return (
        <InputWrapper
            {...input}
            autoComplete={autocomplete}
            error={error ? true : false}
            helperText={error}
            placeholder={placeholder}
            type={type}
        />
    )
}
const renderPhoneField = ({ input, meta }) => {
    return (
        <PhoneBlock>
            <ReactPhoneInputStyled
                {...input}
                autocompleteSearch
                country="ch"
                error={meta.touched && meta.error ? true : false}
                preferredCountries={['ch', 'de', 'gb', 'fr']}
                searchPlaceholder="Search"
                enableSearchField
            />
            {meta.touched && meta.error ? (
                <ErrorText>{t(meta.error)}</ErrorText>
            ) : (
                <HelperText>{t('phoneHelperText')}</HelperText>
            )}
        </PhoneBlock>
    )
}
const removeSpaces = value => value.replace(/ /g, '')

const FieldWrapper = props => (
    <Field
        component={renderField}
        placeholder={t(props.name)}
        type={'text'}
        {...props}
    />
)
const PhoneField = props => <Field component={renderPhoneField} {...props} />

const ProfileForm = () => (
    <Root>
        <RowForTwo>
            <FieldWrapper autocomplete="given-name" name="firstName" />
            <FieldWrapper autocomplete="family-name" name="lastName" />
        </RowForTwo>
        <FieldWrapper autocomplete="organization" name="company" />
        <RowForTwoSmallUp>
            <FieldWrapper autocomplete="email" name="email" />
            <PhoneField
                autocomplete="tel"
                name="phone"
                normalize={removeSpaces}
            />
        </RowForTwoSmallUp>
        <FieldWrapper
            autocomplete="address-line1"
            name="street"
            placeholder={t('address')}
        />
        <FieldWrapper name="address-line2" placeholder={t('address2')} />
        <RowForTwo>
            <FieldWrapper autocomplete="postal-code" name="zip" />
            <FieldWrapper autocomplete="address-level2" name="city" />
        </RowForTwo>
        <FieldWrapper autocomplete="country" name="country" />
    </Root>
)

export default ProfileForm
