import { createAction as createReduxAction, handleActions } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { getAddressByCoords } from '../../services/Geocoding'
import { getUserPosition } from '../../services/utils'

const moduleName = 'userGeolocation'
const createAction = action => createReduxAction(`${moduleName}/${action}`)

const defaultValue = {
    geolocation: null,
    isGeolocationLoading: false,
}

export const geolocationLoaded = createAction('GEOLOCATION_LOAD_SUCCESS')

const geolocationError = createAction('GEOLOCATION_LOAD_ERROR')

export const loadGeolocation = createAction('GEOLOCATION_LOAD_START')

const _reducer = handleActions(
    {
        [loadGeolocation]: state => ({
            ...state,
            isGeolocationLoading: true,
        }),
        [geolocationLoaded]: (state, action) => ({
            ...state,
            geolocation: action.payload.geolocation,
            isGeolocationLoading: false,
        }),
        [geolocationError]: state => ({
            ...state,
            isGeolocationLoading: false,
        }),
    },
    defaultValue
)

export const fetchUserPositionEpic = action$ =>
    action$.pipe(
        ofType(loadGeolocation.toString()),
        mergeMap(() =>
            Observable.create(async observer => {
                try {
                    // const coords = await getUserPosition()
                    // const position = {
                    //     latitude: coords.latitude,
                    //     longitude: coords.longitude,
                    // }
                    // const address = await getAddressByCoords(position)
                    // const name = `${address.city}, ${address.countryCode}`
                    // const userPosition = { ...position, address, name }
                    // observer.next(
                    //     geolocationLoaded({ geolocation: userPosition })
                    // )
                } catch (error) {
                    observer.next(geolocationError({ error }))
                }
            })
        )
    )

export const epic = combineEpics(fetchUserPositionEpic)

export const getGeolocation = state => state.userGeolocation.geolocation

export const reducer = { userGeolocation: _reducer }
