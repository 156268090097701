import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import BoxFinder from '../components/BoxFinder/BoxFinderContainer'
import FullMapContainer from '../components/BoxFinder/FullMapScreen'
import {
    Booking,
    BookingSuccess,
    Payment,
    UserDetails,
} from '../components/CheckoutProcess'
import Account from '../components/CheckoutProcess/Account/Account'
import routes from '../constants/routes'
import { logoutUser } from '../redux/modules/auth'
import { routeChanged } from '../redux/modules/coreDuck'
import {
    getSelectedCategoryObject,
    getSelectedDurationObject,
    getSelectedLocationObject,
} from '../redux/modules/productDuck'
import history from './history'

// import type {
//   Action,
//   PlacebCategory,
//   PlacebDuration,
//   PlacebLocation,
// } from '../types';

// type Props = {
//   logoutUser: void => void,
//   routeChanged: ({ route: string }) => void,
//   categoryObject: ?PlacebCategory,
//   durationObject: ?PlacebDuration,
//   locationObject: ?PlacebLocation,
// };

class RouterContainer extends React.PureComponent /*<Props>*/ {
    routeChanged = ({ pathname }) => {
        if (this.lastPathName === pathname) {
            return
        }
        this.lastPathName = pathname
        this.props.routeChanged({ route: pathname.replace('/', '') })
    }

    componentDidMount() {
        this.routeChanged(history.location)
        this.unlisten = history.listen(this.routeChanged)
    }

    componentWillUnmount() {
        this.unlisten()
    }

    renderBooking = () => {
        const { categoryObject, durationObject, locationObject } = this.props
        return categoryObject && locationObject && durationObject ? (
            <Route component={Booking} path={`/${routes.booking}`} />
        ) : (
            <Redirect to={{ pathname: `/${routes.finder}` }} />
        )
    }

    handleLogout = () => {
        this.props.logoutUser()
        return <Redirect to={{ pathname: '/' }} />
    }

    noRouteFound = () => this.props.routeChanged({ route: null })

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route
                        path={`/${routes.logout}`}
                        render={this.handleLogout}
                    />
                    <Route component={Account} path={`/${routes.account}`} />
                    <Route component={BoxFinder} path={`/${routes.finder}`} />
                    <Route
                        component={FullMapContainer}
                        path={`/${routes.map}`}
                    />
                    <Route
                        path={`/${routes.booking}`}
                        render={this.renderBooking}
                    />
                    <Route
                        component={UserDetails}
                        path={`/${routes.userDetails}`}
                    />
                    <Route component={Payment} path={`/${routes.payment}`} />
                    <Route
                        component={BookingSuccess}
                        path={`/${routes.bookingSuccess}`}
                    />
                    {/* # Hack to fix iPhone 6 webview issue. The 'account' URL with a ä is not working in iPhone 6 webview. */}
                    <Redirect to={{ pathname: `/${routes.account}` }} exact />
                    <Route path="*" render={this.noRouteFound} />
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => ({
    categoryObject: getSelectedCategoryObject(state),
    durationObject: getSelectedDurationObject(state),
    locationObject: getSelectedLocationObject(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators({ logoutUser, routeChanged: routeChanged }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer)
