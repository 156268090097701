import { ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { map, mergeMap, pluck } from 'rxjs/operators'
import { getLoggedUserProfile } from '../../../../redux/modules/auth'
import * as Payment from '../../Payment'
import actions from '../actions'

export default (action$, state$) =>
    action$.pipe(
        ofType(Payment.actions.formConfirmed.toString()),

        mergeMap(() =>
            getLoggedUserProfile(state$.value)
                ? Rx.of(actions.startReservation())
                : Rx.merge(
                      Rx.of(Payment.actions.showPasswordModal()),
                      action$.pipe(
                          ofType(Payment.actions.passwordModalConfirmed),
                          pluck('payload'),
                          map(actions.startReservation)
                      )
                  )
        )
    )
