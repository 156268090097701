import geolib from 'geolib'
import BoxSizeTypes from '../../../constants/BoxSizeTypes'
import RANGES from '../../../constants/Ranges'

export const ifCategoryAvailable = (location, category) => {
    const categoryObject = location.categories.find(
        item => item.title === category
    )

    return !!categoryObject
}

export function findAvailableCategoryAndSizeType(location) {
    let sizeType = null
    let category = null

    const categoryObject =
        location.categories.find(item => {
            if (item.title.length > 4) {
                return false
            }
            const categoryType = item.subType.toLowerCase()
            if (BoxSizeTypes[categoryType].options.includes(item.title)) {
                sizeType = categoryType
                return true
            }
            return false
        }) || null

    if (categoryObject) {
        category = categoryObject.title
    }

    return { category, sizeType }
}

export const findAvailableCategory = location =>
    location.categories.find(item => {
        if (item.title.length > 4) {
            return false
        }

        return item.subType
    })

export const chooseCategoryBySizeType = (location, sizeType, preferred) => {
    const { usedCategories, categories } = location

    const showedCategories = BoxSizeTypes[sizeType].options.filter(item =>
        usedCategories.includes(item)
    )

    const availableCategories = showedCategories.filter(item =>
        categories.find(cat => cat.title === item)
    )

    let selectedCategory

    if (preferred) {
        selectedCategory = availableCategories.find(item => item === preferred)
    }

    if (!selectedCategory) {
        selectedCategory = availableCategories[0] || showedCategories[0] || null
    }

    return selectedCategory
}

export const ifSizeTypeAvailable = (location, sizeType) => {
    const category = chooseCategoryBySizeType(location, sizeType)

    if (!category) {
        return false
    }

    return ifCategoryAvailable(location, category)
}

export function findClosestLocation(point, locations) {
    const sorter = (prev, next) => {
        const prevDistance = geolib.getDistanceSimple(point, prev)
        const nextDistance = geolib.getDistanceSimple(point, next)

        return prevDistance - nextDistance
    }

    return [...locations].sort(sorter)[0]
}

export const getMinRadius = (position, location) => {
    return (
        RANGES.find(
            item => item > geolib.getDistanceSimple(position, location)
        ) || RANGES.ALL_SWITZERLAND
    )
}

export const sortArrayBox = array => {
    const sortedArray = array.sort((a, b) => {
        if (a.title < b.title) {
            return -1
        }

        if (a.title > b.title) {
            return 1
        }

        return 0
    })

    const xs = []
    const s = []
    const m = []
    const l = []
    const xl = []

    sortedArray.forEach(item => {
        if (item.title.charAt(0) === 'S') {
            s.push(item)
        } else if (item.title.charAt(0) === 'M') {
            m.push(item)
        } else if (item.title.charAt(0) === 'L') {
            l.push(item)
        } else if (item.title.indexOf('XS') !== -1) {
            xs.push(item)
        } else {
            xl.push(item)
        }
    })

    return Array.prototype.concat.apply([], [xs, s, m, l, xl])
}

export const sortSimpleArrayBox = array => {
    const sortedArray = array.sort()
    const xs = []
    const s = []
    const m = []
    const l = []
    const xl = []

    sortedArray.forEach(item => {
        if (item.charAt(0) === 'S') {
            s.push(item)
        } else if (item.charAt(0) === 'M') {
            m.push(item)
        } else if (item.charAt(0) === 'L') {
            l.push(item)
        } else if (item.indexOf('XS') !== -1) {
            xs.push(item)
        } else {
            xl.push(item)
        }
    })

    return Array.prototype.concat.apply([], [xs, s, m, l, xl])
}
