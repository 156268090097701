import { syncTranslationWithStore } from 'react-redux-i18n-lite'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { reducer as formReducer } from 'redux-form'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import thunkMiddleware from 'redux-thunk'
import * as screens from '../components'
import * as Payment from '../components/CheckoutProcess/Payment'
import * as modules from './modules'
import { presetValues } from './modules/productDuck'
import syncQueryWithStore from './syncQueryWithStore'

const epic = combineEpics(screens.epic, modules.epic)
const epicMiddleware = createEpicMiddleware()

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [
        'checkout',
        'product',
        'i18n',
        'form',
        'cheaperBoxNotification',
    ],
}

const formPersistConfig = {
    key: 'form',
    storage: storageSession,
    whiteList: [],
    blacklist: [Payment.moduleName],
}

export function configureStore(initialState) {
    const reducer = combineReducers({
        ...modules.default,
        form: persistReducer(formPersistConfig, formReducer),
    })

    let store = null

    const syncRunMiddleWare = () => next => action => {
        if (action.type === presetValues.toString()) {
            syncQueryWithStore(store)
        }
        return next(action)
    }

    const persistedReducer = persistReducer(rootPersistConfig, reducer)

    store = createStore(
        persistedReducer,
        initialState,
        composeWithDevTools(
            applyMiddleware(thunkMiddleware, epicMiddleware, syncRunMiddleWare)
        )
    )

    // if (window.module.hot) {
    //   window.module.hot.accept('./modules', () => {
    //     const nextRootReducer = require('./modules');
    //     store.replaceReducer(nextRootReducer);
    //   });
    // }

    syncTranslationWithStore(store)
    const persistor = persistStore(store)
    epicMiddleware.run(epic)

    return { store, persistor }
}
