import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
    getPreopenedFlag,
    getSelectedDurationObject,
    getSelectedCategoryObject,
} from '../../../redux/modules/productDuck'
import { Action } from '../../../types'
import { confirmButtonClicked } from '../boxFinderDuck'
import BoxFinderFooter from './BoxFinderFooter'

const mapStateToProps = (state: any) => ({
    duration: getSelectedDurationObject(state),
    category: getSelectedCategoryObject(state),
    preopened: getPreopenedFlag(state),
})
const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
    bindActionCreators({ onClick: confirmButtonClicked }, dispatch)
const BoxFinderFooterContainer = (props: any)=>
    props.duration ? <BoxFinderFooter {...props} /> : null

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BoxFinderFooterContainer)
