export default {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    country: '',
    zip: '',
    city: '',
}
