import React from 'react'
import styled from 'styled-components'

const InputStyled = styled.input`
    border: none;
    outline: none;
    font-size: 16px;
    color: #39393a;
    position: relative;
    width: 98%;
    height: calc(100% - 2px);
`

const Root = styled.div`
    flex: 1;
    position: relative;
    padding-right: 10px;
`
// type Props = {
//   value: string,
//   onChange: string => void,
//   onFocus: void => void,
//   onBlur?: void => void,
// };
const Input = ({ onChange, onFocus, onBlur, value = '' } /*: Props*/) => (
    <Root>
        <InputStyled
            innerRef={input => {
                input && input.focus()
            }}
            //autofocus={true}
            onBlur={onBlur}
            onChange={({ target }) => onChange(target.value)}
            onFocus={e => {
                const temp_value = e.target.value
                e.target.value = ''
                e.target.value = temp_value
                onFocus && onFocus()
            }}
            value={value}
        />
    </Root>
)
export default Input
