import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import {
    getDiscount,
    getInsuranceValue,
    getOrderedFlag,
    getProfileFormValues,
    isPayByCard,
} from '../../../../components/CheckoutProcess'
import { getLastReservation } from '../../../../components/CheckoutProcess/checkoutDuck'
import { routes } from '../../../../constants'
import { getLoggedUserProfile } from '../../auth'
import actions from '../actions'
import { GtmEvent, HitType, PageType } from '../constants'
import {
    getBoxProduct,
    getInsuranceProduct,
    getProductState,
    readableDuration,
    with2Digits,
} from '../utils'

const PaymentMethod = {
    invoice: 'invoice',
    invoice3rdParty: 'invoice3rdParty',
    creditCard: 'credit_card',
}

export default (action$, state$) =>
    action$.pipe(
        ofType(actions.orderCompleted.toString()),
        mergeMap(() => {
            const { duration, quantity } = getProductState(state$.value)
            const ordered = getOrderedFlag(state$.value)
            const { zip, email, id } =
                getProfileFormValues(state$.value) ||
                getLoggedUserProfile(state$.value)
            let dataLayerObject = {
                event: GtmEvent.ECORD,
                hitType: HitType.pageView,
                page: `/${routes.bookingSuccess}`,
                pageType: PageType.bookingSuccess,
                userEmail: email,
                userZip: zip,
                userId: id ? id + '' : null,
            }
            if (ordered) {
                const variant = readableDuration(duration)
                const insuranceValue = getInsuranceValue(state$.value)
                const card = isPayByCard(state$.value)
                const affiliation = card
                    ? PaymentMethod.creditCard
                    : PaymentMethod.invoice
                const shipping = with2Digits(
                    affiliation === PaymentMethod.invoice ? 5 : 0
                )
                const { priceInfo } = getLastReservation(state$.value)
                const coupon = getDiscount(state$.value) || ''
                const products = [getBoxProduct(state$.value)]
                const boxProduct = products[0]
                const insuranceProduct = getInsuranceProduct({
                    quantity,
                    variant,
                    value: insuranceValue,
                })
                insuranceValue && products.push(insuranceProduct)
                const insurancePrice = insuranceValue
                    ? +insuranceProduct.priceTotal
                    : 0
                const revenue = with2Digits(+priceInfo.price + insurancePrice)

                typeof window.gtag === 'function' &&
                    window.gtag('event', 'purchase', {
                        transaction_id: priceInfo.id + '',
                        currency: 'CHF',
                        value: revenue,
                        coupon: coupon,
                        items: [
                            {
                                item_id: boxProduct.dimension13,
                                item_name: boxProduct.name,
                                index: 0,
                                location_id: boxProduct.category,
                                price: boxProduct.price,
                                quantity: boxProduct.quantity,
                            },
                        ],
                    })

                typeof window.uetq === 'object' &&
                    typeof window.uetq.push === 'function' &&
                    window.uetq.push('event', 'purchase', {
                        revenue_value: revenue,
                        currency: 'CHF',
                    })

                dataLayerObject = {
                    ...dataLayerObject,
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: priceInfo.id + '',
                                affiliation,
                                shipping,
                                revenue, //non-discounted /
                                coupon,
                                metric1: with2Digits(priceInfo.discountAmount), //discount value,
                            },
                            products,
                        },
                    },
                }
            }
            return [actions.pushEvent({ dataLayerObject })]
        })
    )
//{id: 2109, price: 61.24, discountAmount: 0, priceDiscounted: 61.24}
