import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import FlexContainer from '../../components/FlexContainer'
import FullScreen from '../components/FullScreen'
import BoxDuration from '../components/InformationItems/BoxDuration'
import BoxLocation from '../components/InformationItems/BoxLocation'
import BoxSize from '../components/InformationItems/BoxSize'
import BoxStartDate from '../components/InformationItems/BoxStartDate'
import PersonalInformation from '../components/PersonalInformation'
import AppleStoreBadge from './AppleStoreBadge'
import GooglePlayBadge from './GooglePlayBadge'
import { ButtonMain } from '../../components/ButtonMain'
import { withLocale } from '../../../core/ApplicationContext'

const tPrefix = 'checkoutProcess.bookingSuccess'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const iphoneDevice = !!navigator.platform && /iPhone/.test(navigator.platform)

const Root = styled(FullScreen)`
    overflow-y: scroll;
    -webkit-overflow-scrolling: ${iphoneDevice ? 'touch' : 'no'};
    flex-shrink: 0;
`

const MaxWidthWrapper = styled.div`
    box-sizing: border-box;

    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const Notification = styled(FlexContainer)`
    align-items: flex-start;
    flex-shrink: 0;
    background-color: #80c700;
    color: #ffffff;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
`

const NextStepsBlock = styled.div`
    align-items: flex-start;
    flex-shrink: 0;
    background-color: #fafafa;
    box-shadow: inset 0 1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
    font-family: Helvetica, sans-serif;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-around;
`

const Title = styled.h3`
    flex: 1;
    color: #000000;
    margin-top: 0;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    margin-bottom: 18px;
    font-weight: bold;
    line-height: 22px;
`

const Text = styled.div`
    color: #000000;
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
`

const TitleWrapperInformation = styled(Title)`
    margin-left: 20px;
`

const Personal = styled.div`
    flex-shrink: 0;
    box-shadow: inset 0 1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
`
const PersonalInformationBlock = styled.div`
    flex-shrink: 0;
    background: #fafafa;
    box-shadow: inset 0 1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
`

const TitleApp = styled.div`
    color: #000000;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
`

const StepLi = styled.div`
    display: flex;
    flex-direction: row;
    margin: 17px 0;
`

const StepIcon = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #80c700;
    background-color: ${props => (props.active ? '#80c700' : 'white')};
`

const StepText = styled.div`
    margin-left: 10px;
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
`

const BoxDurationWrapper = styled(BoxDuration)`
    margin-bottom: 20px;
`

const ApplicationBlock = styled.div`
    display: flex;
    justify-content: space-between;
`

const Footer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 1px 0 0 #dddddd;
    background-color: white;
    padding: 16px;
`

const StepItem = ({ active, title }) => (
    <StepLi>
        <StepIcon active={active} />
        <StepText>{title}</StepText>
    </StepLi>
)

class BookingSuccess extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('checkoutCompleted')
        }
    }

    goToBoxes() {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('goToBoxes')
        }
    }

    render() {
        const { userInformation, location, category, duration, startDate } =
            this.props

        const regular = !window.ReactNativeWebView

        const stepList = (
            <React.Fragment>
                {/* <StepItem
                    active={userInformation.emailVerified}
                    title={t('verifyEmail')}
                /> */}
                <StepItem
                    active={userInformation.phoneVerified}
                    title={t('verifyPhone')}
                />
                {regular && (
                    <>
                        <StepItem
                            active={userInformation.hasAppInstalled}
                            title={t('installMobileApp')}
                        />
                    </>
                )}
            </React.Fragment>
        )

        const pixelUrl = `https://secure.adnxs.com/px?id=1870265&order_id=${userInformation.id}&value=${duration.price}&t=2`

        return (
            <Root>
                <Notification>
                    <MaxWidthWrapper style={{ padding: '19px 20px' }}>
                        {t('successLabel')}
                    </MaxWidthWrapper>
                </Notification>
                <NextStepsBlock>
                    <MaxWidthWrapper style={{ padding: '25px 19px' }}>
                        <Title>{t('nextSteps')}</Title>
                        {stepList}
                    </MaxWidthWrapper>
                </NextStepsBlock>
                {category && (
                    <>
                        <Personal>
                            <MaxWidthWrapper
                                style={{ padding: '34px 0 19px 20px' }}
                            >
                                <TitleWrapper>
                                    <Title>{t('personalInformation')}</Title>
                                </TitleWrapper>
                                <PersonalInformation
                                    userInformation={userInformation}
                                />
                            </MaxWidthWrapper>
                        </Personal>
                        <PersonalInformationBlock>
                            <MaxWidthWrapper style={{ padding: '32px 0' }}>
                                <TitleWrapperInformation>
                                    {t('bookedBoxes')}
                                </TitleWrapperInformation>
                                <BoxSize sizeParameters={category} />
                                <BoxLocation location={location} />
                                <BoxDurationWrapper duration={duration} />
                                <BoxStartDate startDate={startDate} />
                            </MaxWidthWrapper>
                        </PersonalInformationBlock>
                    </>
                )}
                <MaxWidthWrapper style={{ padding: '30px 20px' }}>
                    {regular ? (
                        <>
                            <TitleApp>{t('install')}</TitleApp>
                            <Text>{t('installSubheader')}</Text>
                            <ApplicationBlock>
                                <AppleStoreBadge />
                                <GooglePlayBadge />
                            </ApplicationBlock>
                        </>
                    ) : (
                        <Footer>
                            <ButtonMain onClick={this.goToBoxes}>
                                {t('myBoxes')}
                            </ButtonMain>
                        </Footer>
                    )}
                </MaxWidthWrapper>

                <img src={pixelUrl} width="1" height="1" />
            </Root>
        )
    }
}

export default BookingSuccess
