import invariant from 'invariant'
import React from 'react'
import ReactDOM from 'react-dom'
import { OverlayView } from 'react-google-maps'
import { OVERLAY_VIEW } from 'react-google-maps/lib/constants'

export default class CustomOverlayView extends OverlayView {
    draw() {
        const { mapPaneName } = this.props
        invariant(
            !!mapPaneName,
            'OverlayView requires either props.mapPaneName or props.defaultMapPaneName but got %s',
            mapPaneName
        )
        const mapPanes = this.state[OVERLAY_VIEW].getPanes()
        if (mapPanes && this.containerElement) {
            mapPanes[mapPaneName].appendChild(this.containerElement)
            ReactDOM.unstable_renderSubtreeIntoContainer(
                this,
                React.Children.only(this.props.children),
                this.containerElement,
                this.onPositionElement
            )
        }
    }
}
