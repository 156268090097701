import PropTypes from 'prop-types'
import React from 'react'

class FormAbstract extends React.Component {
    constructor() {
        super()
        this.state = {
            validationFlags: {},
        }
    }

    componentDidMount() {
        this.props.setValidationFunc(this.validate)
    }

    componentWillUnmount() {
        this.props.setValidationFunc(null)
    }

    setFieldError(name, value) {
        this.setState(prevState => {
            const { validationFlags } = prevState
            validationFlags[name] = value
            return {
                ...prevState,
                validationFlags,
            }
        })
    }
    resetError(name) {
        this.setFieldError(name, false)
    }
}

FormAbstract.propTypes = {
    userInformation: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    setValidationFunc: PropTypes.func.isRequired,
}
export default FormAbstract
