export const dimensions = {
    w: 'B',
    h: 'H',
    d: 'T',
}
export const sizeTypes = {
    xs: 'Extra Small',
    s: 'Small',
    m: 'Medium',
    l: 'Large',
    xl: 'Extra Large',
}
export const durations = {
    day: 'Tag',
    week: 'Woche',
    month: 'Monat',
    year: 'Jahr',
    inSentence: {
        day: 'Tag',
        week: 'Woche',
        month: 'Monat',
        year: 'Jahr',
    },
}

export const durationsMultiple = {
    day: 'Tage',
    week: 'Wochen',
    month: 'Monate',
    year: 'Jahre',
    inSentence: {
        day: 'Tage',
        week: 'Wochen',
        month: 'Monate',
        year: 'Jahre',
    },
}
