import clsx from 'clsx'
import React from 'react'
import styled from 'styled-components'

const Message = styled.h1`
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    padding: 0 30px;
    font-weight: 300;
    font-size: 20px;
    line-height: 2;
    color: #39393a;
    text-align: center;
`

interface Props {
    children?: React.ReactNode
    showBackdrop?: boolean
}

const Loader: React.FC<Props> = props => (
    <div className={clsx('loader', props.showBackdrop && 'loader--backdrop')}>
        {props.children && <Message>{props.children}</Message>}
        <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
        </div>
    </div>
)

export default Loader
