import { indexBy, prop } from 'ramda'
import { createAction as createReduxAction, handleActions } from 'redux-actions'
import { combineEpics, ofType } from 'redux-observable'
import { createSelector } from 'reselect'
import { mergeMap } from 'rxjs/operators'
import { globalizeSelectors } from '../../services'
import getPlacebLocations from '../../services/placebLogic/getPlacebLocations'
// import type { PlacebLocation } from '../../types';
const moduleName = 'locations'
const createAction = action => createReduxAction(`${moduleName}/${action}`)

export const locationsLoaded = createAction('SET_LOCATIONS')
export const loadLocations = createAction('LOAD_LOCATIONS')
export const locationsLoadingError = createAction('LOCATIONS_LOADING_ERROR')

const _getLocations = createSelector([prop('locations')], Object.values)

const globalize = globalizeSelectors(prop(moduleName))
const _getLocationById = (state, id) => state.locations[id]

const loadLocationsEpic = action$ =>
    action$.pipe(
        ofType(loadLocations.toString()),
        mergeMap(async () => {
            try {
                const locations /*: Array<
                    PlacebLocation
                >*/ = await getPlacebLocations()
                return locationsLoaded({ locations })
            } catch (error) {
                return locationsLoadingError({ error })
            }
        })
    )

const defaultValue = {
    locations: [],
    isLocationsLoading: false,
}

const _reducer = handleActions(
    {
        [loadLocations]: state => ({
            ...state,
            isLocationsLoading: true,
        }),
        [locationsLoaded]: (state, { payload: { locations } }) => ({
            ...state,
            locations: indexBy(prop('id'), locations),
            isLocationsLoading: false,
        }),
        [locationsLoadingError]: state => ({
            ...state,
            isLocationsLoading: false,
        }),
    },
    defaultValue
)

export const epic = combineEpics(loadLocationsEpic)
export const reducer = { [moduleName]: _reducer }

const getLocations = globalize(_getLocations)
const getLocationById = globalize(_getLocationById)

export { getLocations, getLocationById }
