import {
    dimensions,
    durations,
    durationsMultiple,
    sizeTypes,
} from '../../../translations/enCommon'

export default {
    radius: 'Radius',
    allSwitzerland: 'All of Switzerland',
    showOffer: 'Show offer',
    currentPosition: 'Current position',
    calculatorQuestion: 'What fits in this box?',
    chooseThisSize: 'Choose this size',
    dimensions,
    size: 'Size',
    selectBox: 'Select this box',
    inclVat: 'incl. VAT',
    per: 'per',
    sizeCalculator: 'Size calculator',
    location: 'Location',
    duration: 'Duration',
    noBoxes: 'There are no more available boxes in this size at this location',
    increaseRange: 'Change location?',
    preOpenedMessage:
        '50% Discount! Preopening Location. Access on request only.',
    noCategories: 'No categories for this size',
    durations,
    durationsMultiple,
    sizeTypes,
    durationLabel: ['1 Week', '1 Month', '3 Months', '6 Months', '1 Year'],
    price: 'Price',
    minimumRentalPeriod: 'Minimal duration',
    notificationText:
        'There is a cheaper %{category} in a different location. ',
    cancelButton: 'No, thank you',
    agreeButton: 'Show cheaper box',
    changeSize: 'Change Size',
    changeLocation: 'Change Location',
    nearest: 'Nearest',
    cheapest: 'Cheapest',
    priceDiscountPrefix: 'First',
    priceRegularPrefix: 'Thereafter',
    includeDiscount: 'Less coupon code discount',
    sizeTypesStuff: {
        xs: ['15-20 boxes', 'bike'],
        s: [
            '15-20 boxes',
            '1 shelf',
            '1 bike',
            '4 chairs',
            '1 dining table',
            '1 bed incl. mattress',
            '1 desk',
            '1 TV',
        ],
        m: [
            '15-20 boxes',
            '1 shelves (demounted)',
            '1 chest of drawers',
            '1 bike',
            '6 chairs',
            '1-3 bed incl. mattress (demounted)',
            '1-3 door closet (demounted)',
            '1 dining table',
            '1 desk',
            '1 TV',
            '1 sideboard',
            '1 side table',
            '1 couch',
            '1 lounge for 4 people',
        ],
        l: [
            '20-25 boxes',
            '1 shelves',
            '2 chest of drawers',
            '1 bike',
            '8 chairs',
            '2-4 bed incl. mattress (demounted)',
            '2-4 4 door closet (demounted)',
            '1 dining table',
            '1 desk',
            '1 TV',
            '1 sideboard',
            '1 side table',
            '1 big couch',
            '1 lounge for 4 people',
            '1 washer',
            '1 dryer',
        ],
        xl: [
            '35-45 boxes',
            '2-4 shelves',
            '3-4 chest of drawers',
            '1 bike',
            '10 chairs',
            '3-5 bed incl. mattress (demounted)',
            '3-5 door closet (demounted)',
            '1 dining table',
            '1 kitchen table',
            '3 desks',
            '1 TV',
            '2 sideboards',
            '1 side table',
            '1 big couch',
            '1 lounge for 8 people',
            '1 washer',
            '1 dryer',
        ],
    },
}
