import { BoxWithAlignment } from '../../components/commonComponents'
import styled from 'styled-components'

export default styled(BoxWithAlignment)`
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #ffffff;
    height: 44px;
    cursor: pointer;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
`
