import LeftArrow from '@material-ui/icons/KeyboardArrowLeft'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import deLocale from 'date-fns/locale/de'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import DatePicker from 'material-ui-pickers/DatePicker'
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils'
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { withLocale } from '../../../../core/ApplicationContext'
import { createTranslator } from '../../../../services'
import FlexContainer from '../../../components/FlexContainer'
import EditButton from '../../components/EditButton'
import BoxStartDate from '../../components/InformationItems/BoxStartDate'

const t = createTranslator('bookingPage.step1')

const localeMap = {
    en: enLocale,
    fr: frLocale,
    de: deLocale,
}

const InformationItemStartDateContainer = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
`
const DatePickerWrapper = styled(DatePicker)`
    width: 0;
    height: 0;
    visibility: hidden;
`
// type Props = {
//   value: Date,
//   locale: string,
// };

const dateDisable = date => {
    const today = new Date()
    const highBorder = new Date(today)
    highBorder.setDate(highBorder.getDate(today) + 60)
    return highBorder - date < 0
}

function StartDatePicker(props /*: Props*/) {
    const pickerRef = useRef(null)

    const openPicker = useCallback(e => {
        if (pickerRef.current) {
            pickerRef.current.open(e)
        }
    }, [])

    const languageLocale = localeMap[props.locale]

    return (
        <InformationItemStartDateContainer>
            <BoxStartDate startDate={props.value} />
            <MuiPickersUtilsProvider
                locale={languageLocale}
                utils={DateFnsUtils}
            >
                <DatePickerWrapper
                    ref={pickerRef}
                    cancelLabel={t('cancel')}
                    id={'datePickerInputText'}
                    leftArrowIcon={<LeftArrow />}
                    okLabel={t('ok')}
                    onChange={props.onChange}
                    rightArrowIcon={<RightArrow />}
                    shouldDisableDate={dateDisable}
                    value={props.value}
                    autoOk
                    disablePast
                />
            </MuiPickersUtilsProvider>

            <EditButton onClick={openPicker} />
        </InformationItemStartDateContainer>
    )
}
export default withLocale(StartDatePicker)
