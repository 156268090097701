import styled from 'styled-components'

export const ButtonMain = styled.button`
    color: #ffffff;
    background-color: #80c700;
    font-size: 18px;
    font-weight: bold;
    padding-left: 17px;
    padding-right: 17px;
    text-align: center;
    width: 100%;
    height: 52px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`
