import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { createTranslator } from '../../../../services'

const t = createTranslator('checkoutProcess.userDetails')
const Root = styled.div`
    padding: 20px 19px 0 19px;
    outline: none;
`
const Title = styled.button`
    width: 100%;
    background-color: #ffffff;
    padding: 12px 35px;
    color: #80c700;
    border: 1px solid #80c700;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
`
const LoginButton = ({ onClick }) => (
    <Root onClick={onClick}>
        <Title>{t('loginButton')}</Title>
    </Root>
)

LoginButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}
export default LoginButton
