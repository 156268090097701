import styled, { css } from 'styled-components'
import placeBlogo from './placeb-logo.svg'
import { I18n } from 'react-redux-i18n-lite'
import favicon from './favicon.png'
import { desktopWidth } from '../../../config'
import React from 'react'

export const NoWrapText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`
export const ListItem = styled.li`
    box-sizing: border-box;
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    height: 60px;
    padding: 10px;
`
export const SelectorList = styled.ul`
    padding-left: 0;
    display: flex;
    margin: 0;
    flex-direction: column;
    border-top: 1px solid #dddddd;
    @media (min-width: ${desktopWidth}px) {
        // ${show => css`
            display: ${show ? 'flex' : 'none'};
        `}
        height: 450px;
        overflow-y: auto;
    }
`
export const DropdownMixin = css`
    position: absolute;
    width: calc(100% - 10px);
    height: 500px;
    border: 1px solid #dddddd;
`

export const FixedContainer = styled.div`
    outline: none;
    box-sizing: border-box;
    position: fixed;
    //overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    top: 0;
    bottom: 0;
    background: white;
    flex: 1;
    flex-direction: column;
    display: flex;
    right: 0;
    left: 0;
    @media (min-width: ${desktopWidth}px) {
        ${DropdownMixin};
    }
`

export const InputIcon = styled.img`
    height: 21px;
    width: 21px;
    color: #39393a;
    margin: 10px;
    margin-top: 13px;
    flex-shrink: 0;
`
export const Logo = ({ className, onClick }) => {
    const Img = styled.img.attrs({ src: placeBlogo })`
        height: 40px;
        @media (max-width: ${desktopWidth - 1}px) {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }
    `
    const Wrapper = styled.div`
        position: relative;
        margin: 0 12px 0 10px;
        height: 40px;
        cursor: pointer;
        @media (max-width: ${desktopWidth - 1}px) {
            width: 40px;
            overflow: hidden;
        }
    `
    return (
        <Wrapper className={className} onClick={onClick}>
            <Img />
        </Wrapper>
    )
}

export const SimpleLogo = styled.img.attrs({ src: favicon })`
    margin: 0 12px 0 10px;
    height: 40px;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    flex-shrink: 0;
`

export const BoxWithAlignment = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`
export const rangeItemTitle = (strings, item) =>
    isNaN(-item) ? I18n.t(`boxFinder.${item}`) : `${Math.floor(item / 1000)} km`

export const addressItemTitle = (strings, item) => {
    if (!item) {
        return ''
    }
    if (item.isSuggestion || !item.id) {
        return item.name
    }
    return `${item.street}, ${item.city} ${item.zip}`
}
