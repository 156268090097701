import * as R from 'ramda'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import actions from '../actions'
import formTypeMap from './formTypeMap'

const createErrorAction = (path, label) =>
    actions.pushEvent({
        event: 'DL Event',
        eventCategory: 'Form Error',
        eventAction: path,
        eventLabel: label,
    })
const createNestedErrors = path =>
    R.compose(
        R.values,
        R.mapObjIndexed((value, name) =>
            createErrorAction(`${path}.${name}`, value)
        )
    )

export default action$ =>
    action$.pipe(
        ofType(actions.formFieldInputError.toString()),
        mergeMap(({ payload: { form, field, value } }) =>
            R.type(value) === 'Object'
                ? createNestedErrors(`${formTypeMap[form]}.${field}`)(value)
                : [createErrorAction(`${formTypeMap[form]}.${field}`, value)]
        )
    )
