import * as R from 'ramda'
import { createAction as createReduxAction } from 'redux-actions'
import routes from '../../../constants/routes'
import history from '../../../core/history'

const createAction = action => createReduxAction(`checkout/${action}`)
export const changeDiscount = createAction('CHANGE_DISCOUNT')
export const setLastReservation = createAction('SET_LAST_RESERVATION')
export const openBooking = createAction('OPEN_BOOKING')
export const reservationSuccess = createAction('RESERVATION_SUCCESS')
export const paymentConfirmed = createAction('PAYMENT_CONFIRMED')
export const disableCard = createAction('DISABLE_CARD')
const startReservation = createAction('START_RESERVATION')
export const reservationFailed = createAction('RESERVATION_FAILED')
export const openUserDetails = () => history.push(`/${routes.userDetails}`)
export const openPayment = () => history.push(`/${routes.payment}`)
export const openBookingSuccess = () =>
    history.push(`/${routes.bookingSuccess}`)
export const registerUser = createAction('REGISTER_USER')
export const registrationSuccess = createAction('REGISTRATION_SUCCESS')
export const registrationFailed = createAction('REGISTRATION_FAILED')
export const createReservation = createAction('CREATE_RESERVATION')
export const updateUser = createAction('UPDATE_USER')
export const updateUserSuccess = createAction('UPDATE_USER_SUCCESS')
export const updateUserError = createAction('UPDATE_USER_ERROR')

const paymentFormConfirmed = createAction('PAYMENT_FORM_CONFIRMED', R.F)
const creditCardError = createAction('CREDIT_CARD_ERROR')
export default {
    paymentFormConfirmed,
    startReservation,
    creditCardError,
}
