import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import RANGES from '../../../constants/Ranges'
import RadiusSelector from './RadiusSelector'
import {
    getSelectedRadius,
    hideRadiusSelector,
    radiusChangedByUser,
} from './radiusSelectorDuck'

const mapStateToProps = state => ({
    options: RANGES,
    value: getSelectedRadius(state),
})

const mapDispatchToProps = (dispatch /*: Dispatch<Action>*/) =>
    bindActionCreators(
        {
            onChange: radius => radiusChangedByUser({ radius }),
            onClose: hideRadiusSelector,
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(RadiusSelector)
