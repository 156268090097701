import * as R from 'ramda'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import {
    getSelectedCategoryObject,
    getSelectedDuration,
    getSelectedLocationObject,
} from '../../../redux/modules/productDuck'
import { globalizeSelectors } from '../../../services/index'
import { getRealPricePerMonth } from '../../../services/utils'
import moduleName from './moduleName'

const getCheckoutForm = getFormValues('checkout')
export const getInsuranceValue = R.compose(
    state =>
        state && (isNaN(+state.insuranceValue) ? 0 : +state.insuranceValue),
    getCheckoutForm
)
export const getStartDate = R.compose(
    v => v && new Date(v),
    R.prop('startDate'),
    getCheckoutForm
)
const globalize = globalizeSelectors(R.prop(moduleName))

const _getCreditCard = R.prop('payByCard')
const _getDiscount = R.prop('discount')
const _getOrderedFlag = R.prop('ordered')
const _getLastReservation = R.prop('lastReservation')
const _getIsReservationPendingFlag = R.prop('isReservationPending')

export const getIsReservationPending = globalize(_getIsReservationPendingFlag)
export const getLastReservation = globalize(_getLastReservation)
export const getDiscount = globalize(_getDiscount)
export const isPayByCard = globalize(_getCreditCard)
export const getOrderedFlag = globalize(_getOrderedFlag)
export const totalPricePerMonth = createSelector(
    [
        getSelectedLocationObject,
        getSelectedCategoryObject,
        getSelectedDuration,
        getInsuranceValue,
    ],
    (selectedLocation, selectedCategory, selectedDuration, insuranceValue) => {
        if (!selectedLocation || !selectedCategory) {
            return null
        }
        const duration = selectedCategory.durations[selectedDuration]
        const { unit } = duration
        let price = getRealPricePerMonth(duration)
        price = selectedLocation.preOpened ? price / 2 : price
        const timeCoef =
            unit === 'year'
                ? 1
                : unit === 'month'
                ? 1
                : unit === 'week'
                ? 1 / 4
                : unit === 'day'
                ? 1 / 30
                : 1
        const insurancePerMonth = insuranceValue
            ? Math.ceil(insuranceValue / 10000) * 6 * timeCoef
            : 0
        price =
            (Math.round((price + insurancePerMonth) * 100) / 100).toFixed(2) / 1
        //price = price + insurancePerMonth;
        return { price, insurancePerMonth }
    }
)
