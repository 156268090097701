import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { createTranslator } from '../../../../services'
import { errorParser } from '../../../../services/utils'
import { reservationFailed } from '../actions'

const t = createTranslator('actions')

export default action$ => {
    return action$.pipe(
        ofType(reservationFailed.toString()),
        mergeMap(({ payload: { error } }) => {
            const errorText = error.children
                ? errorParser(error.children)
                : error
            alert(`${t('reservationFailed')}`)
            return []
        })
    )
}
