import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
    display: flex;
    flex: 1;
`
const RightPadding = styled(Root)`
    padding-right: 10px;
`
const LeftPadding = styled(Root)`
    padding-left: 10px;
`
const MiddlePadding = styled(Root)`
    padding-left: 10px;
    padding-right: 10px;
`

const RowForThree = ({ children, className }) => (
    <Root className={className}>
        <RightPadding>{children[0]}</RightPadding>
        <MiddlePadding>{children[1]}</MiddlePadding>
        <LeftPadding>{children[2]}</LeftPadding>
    </Root>
)

RowForThree.propTypes = {}
export default RowForThree
