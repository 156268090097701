import { combineEpics, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { filter, mapTo, mergeMap, switchMap } from 'rxjs/operators'
import {
    changeCategory,
    changeLocation,
    getIsValueChanged,
    userChangedSize,
    userChangedSizeType,
    userSelectedLocationFromSlider,
} from '../../../../redux/modules/productDuck'
import { getCheapestBox, recalculateCheaperBox } from '../../CheaperModal'
import {
    hideCheaperNotification,
    showCheaperNotification,
} from '../../CheaperNotification'
import { mapPinClicked } from '../../LocationMap'
import { userChangedPosition } from '../../LocationSelector'
import { confirmButtonClicked } from '../actions'
// import type { Stream } from '../../../../types';

const recalculateCheaperBoxEpic = (
    action$ /*: Stream*/,
    state$ /*: StateObservable*/
) =>
    action$.pipe(
        ofType(recalculateCheaperBox.toString()),
        mergeMap(() => {
            const cheapestBox = getCheapestBox(state$.value)
            return cheapestBox
                ? [showCheaperNotification({ cheapestBox })]
                : [hideCheaperNotification()]
        })
    )
const runCheaperBoxRecalculationEpic = (
    action$ /*: Stream*/,
    state$ /*: StateObservable*/
) =>
    Rx.merge(
        action$.pipe(
            ofType(
                mapPinClicked.toString(),
                userSelectedLocationFromSlider.toString(),
                userChangedPosition.toString()
            ),
            switchMap(() => action$.pipe(ofType(changeLocation.toString())))
        ),
        action$.pipe(
            ofType(confirmButtonClicked.toString()),
            filter(() => !getIsValueChanged(state$.value))
        ),
        action$.pipe(
            ofType(userChangedSize.toString(), userChangedSizeType.toString()),
            switchMap(() => action$.pipe(ofType(changeCategory.toString())))
        )
    ).pipe(mapTo(recalculateCheaperBox()))

export default combineEpics(
    recalculateCheaperBoxEpic,
    runCheaperBoxRecalculationEpic
)
