import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Edit from '@material-ui/icons/Edit'

const EditButtonContainer = styled.div`
    height: 36px;
    width: 36px;
    border: 1px solid #80c700;
    border-radius: 2px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-right: 20px;
    cursor: pointer;
`
const EditImage = styled(Edit)`
    color: #80c700 !important;
    width: 22px !important;
    height: 22px !important;
`

class EditButton extends React.Component {
    render() {
        const { onClick } = this.props
        return (
            <EditButtonContainer onClick={onClick}>
                <EditImage />
            </EditButtonContainer>
        )
    }
}
export default EditButton
EditButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}
