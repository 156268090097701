import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
    display: flex;
    flex: 1;

    @media (max-width: 400px) {
        display: block;
    }
`
const RightPadding = styled(Root)`
    padding-left: 10px;

    @media (max-width: 400px) {
        padding: 0;
    }
`
const LeftPadding = styled(Root)`
    padding-right: 10px;

    @media (max-width: 400px) {
        padding: 0;
    }
`

const RowForTwoSmallUp = ({ children }) => (
    <Root>
        <LeftPadding>{children[0]}</LeftPadding>
        <RightPadding>{children[1]}</RightPadding>
    </Root>
)

RowForTwoSmallUp.propTypes = {}
export default RowForTwoSmallUp
