import React from 'react'
import styled from 'styled-components'
import PlaceBTheme from '../../../../styles/PlacebTheme'
import OverlayView from './OverlayView'
// import type { Position } from '../../../../types';

const mainColor = PlaceBTheme.palette.primary.main
const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
})
const Circle = styled.div`
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: 1px solid green;
    background-color: ${mainColor};
    width: 17px;
    height: 17px;
`

// type Props = { position: Position };
const PositionDot = ({ position } /*: Props*/) => {
    return (
        <OverlayView
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={position}
        >
            <Circle />
        </OverlayView>
    )
}

export default PositionDot
