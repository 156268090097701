import * as R from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { delay, mergeMap, mergeMapTo, take } from 'rxjs/operators'
import BoxSizeTypes from '../../../../constants/BoxSizeTypes'
import { guestAuthorized } from '../../../../redux/modules/auth'
import { loadLocations } from '../../../../redux/modules/locationsDuck'
import {
    changeCategory,
    changeLocation,
    changeSizeType,
    userChangedSize,
    userSelectedLocationFromSlider,
} from '../../../../redux/modules/productDuck'
import { sizeChangedByCalculator } from '../../SizeTypeCalculator'
import changeLocationEpic from './changeLocationEpic'
import changePositionEpic from './changePositionEpic'
import geolocationLoadedEpic from './geolocationLoadedEpic'
import locationsLoadedEpic from './locationsLoadedEpic'
import radiusChangedByUserEpic from './radiusChangedByUserEpic'
import recalculateCheaperBoxEpic from './recalculateCheaperBoxEpic'
import userChangedSizeTypeEpic from './userChangedSizeTypeEpic'
import userChangedSomethingEpic from './userChangedSomethingEpic'

const pickSizeTypeByCategory = category => {
    const res = R.pickBy(
        item => R.contains(category, item.options),
        BoxSizeTypes
    )
    return R.keys(res)[0]
}
const userChangedSizeEpic = action$ =>
    action$.pipe(
        ofType(userChangedSize.toString(), sizeChangedByCalculator.toString()),
        delay(10),
        mergeMap(({ payload: { size: category } }) => {
            const stream = [changeCategory({ category })]
            if (category) {
                stream.push(
                    changeSizeType({
                        sizeType: pickSizeTypeByCategory(category),
                    })
                )
            }
            return stream
        })
    )
const userSelectedLocationFromSliderEpic = action$ =>
    action$.pipe(
        ofType(userSelectedLocationFromSlider.toString()),
        delay(10),
        mergeMap(({ payload: { location } }) => [
            changeLocation({ location, deep: true, setPosition: true }),
        ])
    )
const guestAuthorizedEpic = action$ =>
    action$.pipe(
        ofType(guestAuthorized.toString()),
        take(1),
        mergeMapTo([loadLocations()])
    )
export default combineEpics(
    locationsLoadedEpic,
    userChangedSizeEpic,
    userSelectedLocationFromSliderEpic,
    userChangedSomethingEpic,
    userChangedSizeTypeEpic,
    guestAuthorizedEpic,
    radiusChangedByUserEpic,
    geolocationLoadedEpic,
    changeLocationEpic,
    changePositionEpic,
    recalculateCheaperBoxEpic
)
