import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled from 'styled-components'
import FlexContainer from '../../../components/FlexContainer'
import BookingModal from './BookingModal'

const tPrefix = 'checkoutProcess.booking'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)

const TermsContainer = styled(FlexContainer)`
    background-color: transparent;
    align-items: stretch;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        display: block;
    }
    -webkit-text-size-adjust: 100%;
`

const MaxWidthWrapper = styled.div`
    box-sizing: border-box;
    padding: 30px 21px 19px 21px;

    @media (min-width: 800px) {
        margin: 0 auto;
        width: 800px;
    }
`

const Title = styled.div`
    flex: 1;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    font-family: Helvetica, sans-serif;
`

const Text = styled.p`
    color: #000000;
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    margin: 9px 0;
    line-height: 24px;
`

const DetailsButton = styled.div`
    color: #80c700;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    font-family: Helvetica, sans-serif;
    padding: 5px 0;
    cursor: pointer;
    display: inline-block;
`

class Terms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
        }
    }
    showModal = () => {
        this.setState(() => ({ show: true }))
    }
    hideModal = () => {
        this.setState(() => ({ show: false }))
    }
    render() {
        const { title, text, className, modalTitle, modalText } = this.props
        const { show } = this.state
        const modal = show ? (
            <BookingModal
                modalText={modalText}
                modalTitle={modalTitle}
                onClose={this.hideModal}
                show={show}
            />
        ) : null
        return (
            <TermsContainer className={className}>
                <MaxWidthWrapper>
                    {title && <Title>{title}</Title>}
                    <Text>{text}</Text>
                    <DetailsButton onClick={this.showModal}>
                        {t('moreInformation')}
                    </DetailsButton>
                    {modal}
                </MaxWidthWrapper>
            </TermsContainer>
        )
    }
}

Terms.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}
export default Terms
