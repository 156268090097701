import React from 'react'
import { I18n } from 'react-redux-i18n-lite'
import styled, { css } from 'styled-components'
import { NoWrapText } from '../components/commonComponents'

const tPrefix = 'boxFinder'
const t = phrase => I18n.t(`${tPrefix}.${phrase}`)
const Root = styled.div`
    display: flex;
    flex-direction: row;
    height: 40px;
    flex-shrink: 0;
    margin-top: 10px;
`
const Item = styled(NoWrapText)`
    color: #39393a;
    text-align: center;
`
const ItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    min-width: 0;
    user-select: none;
    cursor: pointer;
    background-color: ${props => (props.active ? 'white' : '#F6F6F6')};
    border: ${props =>
        props.active ? '2px solid #80C700' : '1px solid #dddddd'};
    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
        `};
    margin-left: -1px;
    z-index: ${props => (props.active ? '2' : '1')};
`
// type Props = {
//   availableCategories: Array<string>,
//   options: Array<string>,
//   value: string,
//   value: string => void,
// };
class SizePicker extends React.Component /*<Props>*/ {
    onChange = newIndex => {
        const { onChange, options, value } = this.props
        const newValue = options[newIndex]
        if (value === newValue) {
            return
        }
        onChange(newValue)
    }
    renderItem = (item, index) => {
        const { options, value, availableCategories } = this.props
        const currentIndex = options.indexOf(value)
        const isDisabled = !availableCategories.includes(item)
        return (
            <ItemWrapper
                key={item}
                active={index === currentIndex}
                disabled={isDisabled}
                onClick={() => this.onChange(index, item)}
            >
                <Item>{item}</Item>
            </ItemWrapper>
        )
    }

    render() {
        const { options, className } = this.props
        return (
            <Root className={className}>
                {!options.length
                    ? t('noCategories')
                    : options.map((item, index) =>
                          this.renderItem(item, index)
                      )}
            </Root>
        )
    }
}

export default SizePicker
