import { ofType } from 'redux-observable'
import { filter, map, pluck } from 'rxjs/operators'
import actions from '../actions'
import formTypeMap from './formTypeMap'

export default action$ =>
    action$.pipe(
        ofType(actions.formFieldInputChange.toString()),
        pluck('payload'),
        filter(({ field }) => !field.includes('creditCard')),
        map(({ form, field, value }) =>
            actions.pushEvent({
                event: 'DL Event',
                eventCategory: 'Form Event',
                eventAction: `${formTypeMap[form]}.${field}`,
                eventLabel: value,
            })
        )
    )
