export const actions = {
    REQUEST_START: 'REQUEST_START',
    REQUEST_END: 'REQUEST_END',
}

export const durationUnit = {
    YEAR: 'year',
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
}

export const STATUS = {
    NO_RESERVATION: 'NO_RESERVATION',
    NETWORK_ERROR: 'NETWORK_ERROR',
    UNAUTHORIZED: 'UNAUTHORIZED',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    INCORRECT_CURRENT_PASSWORD: 'INCORRECT_CURRENT_PASSWORD',
}

export { default as routes } from './routes'
