export default {
    logout: 'logout',
    finder: 'finder',
    map: 'map',
    booking: 'booking',
    payment: 'payment',
    userDetails: 'user-details',
    bookingSuccess: 'booking-success',
    account: 'account',
}
