export default {
    required: 'Pflichtfeld',
    invalidCard: 'Ungültige Karte oder unzureichende Deckung',
    save: 'Speichern',
    currentCard: 'Aktuelle Kreditkarte',
    noCard: 'Keine',
    title: 'Konto',
    updateCard: 'Kreditkarte hinzufügen oder aktualisieren',
    updated: 'Konto aktualisiert',
}
